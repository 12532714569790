<template>
    <span>{{ prefix }}{{ amount }}</span>
</template>

<script>
    import { currencies as CURRENCIES } from '@grininc/grin-ui';
    import { commas } from '../../../helpers';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'MoneyDisplay',

        /**
         * The component properties
         *
         * @type {Object}
         */
        props: {
            /**
             * Amount
             */
            value: {
                default: 0
            },

            /**
             * Currency code
             */
            currency: {
                type: String,
                default: 'usd'
            }
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The amount
             *
             * @returns {String|Number}
             */
            amount() {
                return commas(parseFloat(Math.abs(this.value)).toFixed(2));
            },

            /**
             * The currency symbol
             *
             * @returns {String}
             */
            prefix() {
                let prefix = '';

                if (this.value < 0) {
                    prefix = '–';
                }

                return prefix + CURRENCIES[this.currency].symbol;
            }
        }
    };
</script>
