/**
 * Setup Bugsnag for error tracking
 *
 * @param {object} Vue
 */
import bugsnag from '@bugsnag/js';

import BugsnagVue from '@bugsnag/plugin-vue';

export default function (Vue) {
    if (__BUGSNAG__) {
        const client = bugsnag({
            apiKey: window.grinEnv.BUGSNAG_API_KEY,
            appVersion: '2.1.0',
            autoBreadcrumbs: true,
            consoleBreadcrumbsEnabled: false,
            autoCaptureSessions: true,
            autoNotify: true,
            notifyHandler: 'xhr',
            collectUserIp: true,
            releaseStage: __BUGSNAG_ENV__,
            notifyReleaseStages: ['staging', 'production'],
            beforeSend(report) {
                if (report?.errorClass === 'UnhandledRejection') {
                    report.ignore();
                    return;
                }

                if (window.FS && window.FS.getCurrentSessionURL) {
                    report.updateMetaData('fullstory', {
                        urlAtTime: window.FS.getCurrentSessionURL(true)
                    });
                }
            }
        });

        client.use(BugsnagVue, Vue);

        // if we have a authenticated user then we can setup the
        // bugsnag meta data so we get more details on the errors
        if (
            Object.prototype.hasOwnProperty.call(self, 'SERVER_DATA') &&
            Object.prototype.hasOwnProperty.call(self.SERVER_DATA, 'account') &&
            Object.prototype.hasOwnProperty.call(self.SERVER_DATA.account, 'id')
        ) {
            const account = self.SERVER_DATA.account;
            client.metaData = {
                account: {
                    id: account.id,
                    name: account.company_name
                }
            };

            if (self.SERVER_DATA.contact) {
                const contact = self.SERVER_DATA.contact;
                client.user = {
                    id: contact.id,
                    name: `${contact.first_name} ${contact.last_name}`,
                    email: contact.email
                };
            }
        }

        // catch unhandled rejections and send them to Bugsnag formatted
        window.addEventListener('unhandledrejection', (event) => {
            event.preventDefault();

            let reason = event?.reason?.status ? event?.reason?.status + ' ' : '';
            reason += event?.reason?.statusText ? event?.reason?.statusText + ' ' : '';
            let name = event?.reason?.body?.error_name || event?.reason?.body?.message || null;
            if (!name) {
                name = typeof event?.reason === 'string' ? event?.reason : 'unknown';
            }

            reason += name;

            client.notify(reason, {
                metaData: {
                    info: event?.reason
                }
            });
        });
    }
}
