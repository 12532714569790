<template>
    <div class="text-center has-text-grey-light" :class="`m-${margin}`">
        <i class="far fa-lg fa-spinner fa-spin"></i>
    </div>
</template>
<script>
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'Loader',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The css margin
             */
            margin: {
                type: [Number, String],
                required: false,
                default: 30
            }
        }
    }
</script>