/**
 * NOTE: This mixin is deprecated. Please use the `useEventTracking`
 *       composable going forward.
 *
 * @deprecated
 */

import _ from 'lodash';
import {
    EVENT_NAME_PROPOSAL_STARTED,
    EVENT_NAME_PROPOSAL_CONTINUE,
    EVENT_NAME_PROPOSAL_SUBMITTED,
    EVENT_NAME_PRODUCT_ADDED,
    EVENT_NAME_OPTIONAL_TASK_ADDED,
    EVENT_NAME_ACCOUNT_VERIFICATION_STARTED,
    EVENT_NAME_NETWORK_CONNECTED,
    NETWORK_INITIAL_CONNECT_EVENTS,
} from '../constants/analytics-events';

export default {
    methods: {
        trackEventProposalStarted(campaignId) {
            this.trackLiveUrlEvent(EVENT_NAME_PROPOSAL_STARTED, {
                campaign_id: campaignId
            });
        },

        trackEventProposalContinue(campaignId) {
            this.trackLiveUrlEvent(EVENT_NAME_PROPOSAL_CONTINUE, {
                campaign_id: campaignId
            });
        },

        trackEventProposalSubmitted(campaignId, submitSuccess) {
            this.trackLiveUrlEvent(EVENT_NAME_PROPOSAL_SUBMITTED, {
                campaign_id: campaignId,
                was_successful: submitSuccess
            });
        },

        trackEventProductAdded(campaignId) {
            this.trackLiveUrlEvent(EVENT_NAME_PRODUCT_ADDED, {
                campaign_id: campaignId
            });
        },

        trackEventOptionalTaskAdded(campaignId) {
            this.trackLiveUrlEvent(EVENT_NAME_OPTIONAL_TASK_ADDED, {
                campaign_id: campaignId
            });
        },

        trackEventAccountVerificationStarted(verificationMethod) {
            this.trackLiveUrlEvent(EVENT_NAME_ACCOUNT_VERIFICATION_STARTED, {
                verification_method: verificationMethod
            });
        },

        trackEventNetworkConnected(networkSlug, state, reasonFailed = 'N/A') {
            this.trackLiveUrlEvent(EVENT_NAME_NETWORK_CONNECTED, {
                network: networkSlug,
                was_successful: state,
                reason_failed: reasonFailed,
                account_id: this.$root.account.id
            });
        },

        trackEventInitialNetworkConnect(networkSlug) {
            this.trackLiveUrlEvent(NETWORK_INITIAL_CONNECT_EVENTS[networkSlug], {});
        },

        trackLiveUrlEvent(eventName, eventParams) {
            this.$root.trackEvent(
                eventName,
                _.assign(
                    {
                        platform: 'liveurl',
                        contact_id: this.$root.contact_id
                    },
                    eventParams
                )
            );
        }
    }
};
