<template>
    <el-popover :placement="placement" :width="width" trigger="hover">
        <p class="text-14 has-text-grey">
            <slot />
        </p>
        <i slot="reference" class="far fa-question-circle"></i>
    </el-popover>
</template>
<script>
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'InfoPopup',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The popup width
             *
             * @type {object}
             */
            width: {
                type: Number,
                required: false,
                default: 250
            },

            /**
             * The popup placement
             *
             * @type {object}
             */
            placement: {
                type: String,
                required: false,
                default: 'right'
            }
        }

    }
</script>