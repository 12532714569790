import useCurrentInstance from '../helpers/useCurrentInstance';
import { isEmail } from '../helpers';
import { INSTAGRAM_ID, FACEBOOK_ID, TIKTOK_ID, PINTEREST_ID, NETWORK_CONNECTION_TYPE_OAUTH, NETWORK_CONNECTION_TYPE_SOFT, NETWORK_CONNECTION_TYPE_FAILED, NETWORK_CONNECTION_PERMISSIONS_FULL, NETWORK_ACCOUNT_TYPE_CREATOR, META_UPLOADED_CONTENT_DELIVERY_NETWORKS, META_UPLOADED_CONTENT_DELIVERY_NETWORK_SLUGS } from '../../app-v2/constants/networks';
import { CAMPAIGN_TASK_MEDIA_TYPE_STORY } from '../../app-v2/constants/campaigns';
import { useCreatorStore, useFeatureFlagsStore } from '../../stores';
const useNetworks = () => {
    const flags = useFeatureFlagsStore().data;
    const isSoftAuthed = (contactNetwork) => {
        return (contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_connection_type) === NETWORK_CONNECTION_TYPE_SOFT;
    };
    const isOAuthed = (contactNetwork) => {
        return ((contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_connection_type) === NETWORK_CONNECTION_TYPE_OAUTH &&
            (contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_connection_permissions) === NETWORK_CONNECTION_PERMISSIONS_FULL);
    };
    const isFailed = (contactNetwork) => {
        return (contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_connection_type) === NETWORK_CONNECTION_TYPE_FAILED;
    };
    const isCreatorAccount = (contactNetwork) => {
        return (contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_account_type) === NETWORK_ACCOUNT_TYPE_CREATOR;
    };
    const isMetaById = (id) => {
        return [INSTAGRAM_ID, FACEBOOK_ID].includes(id);
    };
    const isTiktokById = (id) => {
        return TIKTOK_ID === id;
    };
    const isPinterestById = (id) => {
        return PINTEREST_ID === id;
    };
    const isInstagramById = (id) => {
        return INSTAGRAM_ID === id;
    };
    const isFacebookById = (id) => {
        return FACEBOOK_ID === id;
    };
    // Activations
    const isMetaUploadContentDeliveryNetworkByType = (type) => {
        if (!type) {
            return false;
        }
        return META_UPLOADED_CONTENT_DELIVERY_NETWORKS.includes(type);
    };
    // Campaigns + Partnerships
    const isMetaUploadContentDeliveryNetworkBySlugAndMediaType = (slug, mediaType) => {
        if (!slug) {
            return false;
        }
        return (META_UPLOADED_CONTENT_DELIVERY_NETWORK_SLUGS.includes(slug) && mediaType !== CAMPAIGN_TASK_MEDIA_TYPE_STORY);
    };
    /**
     * Deprecated as part of Spacejam
     * @deprecated
     */
    const isBrandNetworkConnectedForNetworkById = (brandNetworks, id) => {
        if (!brandNetworks) {
            return false;
        }
        return !!(brandNetworks === null || brandNetworks === void 0 ? void 0 : brandNetworks.find((network) => network.network_id === id && network.has_valid_access_token));
    };
    /**
     * Deprecated as part of Spacejam
     * @deprecated
     */
    const isGraphAPIAvailable = (contactNetwork) => {
        return isMetaById(contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_id) && isOAuthed(contactNetwork);
    };
    /**
     * Deprecated as part of Spacejam
     * @deprecated
     */
    const isDiscoveryAPIAvailable = (contactNetwork, brandNetworks) => {
        return !!(isMetaById(contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_id) &&
            (contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_username) &&
            isCreatorAccount(contactNetwork) &&
            isBrandNetworkConnectedForNetworkById(brandNetworks, contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.network_id));
    };
    /**
     * Validates if the facebook input is acceptable
     *
     * Should Fail:
     * https://www.facebook.com/100068990425780
     * https://www.facebook.com/profile.php?id=100068990425780
     * 100068990425780
     * email
     *
     * Should Pass:
     * https://www.facebook.com/Cristiano/
     * Cristiano
     * https://www.facebook.com/mine.glee.3
     * https://www.facebook.com/1mine.glee.3
     * mine.glee.3
     * 12mine.glee.3
     */
    const isValidFacebookInput = (input) => {
        if (!input || /(^[0-9]+$)|profile\.php\?id=|(facebook\.com\/[0-9]+$)/.test(input) || isEmail(input)) {
            return false;
        }
        return true;
    };
    const getContactNetworkById = (id) => {
        var _a, _b, _c;
        return (_c = (_b = (_a = useCurrentInstance()) === null || _a === void 0 ? void 0 : _a.$root) === null || _b === void 0 ? void 0 : _b.networks) === null || _c === void 0 ? void 0 : _c.find((network) => { var _a; return ((_a = network.network) === null || _a === void 0 ? void 0 : _a.id) === id; });
    };
    const getContactNetworkByIdFromContactNetworks = (id, contactNetworks) => {
        if (!id || !contactNetworks) {
            return null;
        }
        return contactNetworks.find((contactNetwork) => contactNetwork.network_id === id);
    };
    const getConnectedContactNetworkByIdFromContactNetworks = (id, contactNetworks) => {
        if (!id || !contactNetworks) {
            return null;
        }
        return contactNetworks.find((contactNetwork) => {
            if (contactNetwork.network_id !== id) {
                return false;
            }
            // 3rd Party - Meta Only - Connected = Soft or OAuth with Full Permissions
            if (isMetaById(id)) {
                return isSoftAuthed(contactNetwork) || isOAuthed(contactNetwork);
            }
            // 1st Party - Or any other network - Connected = OAuth with Full Permissions
            return isOAuthed(contactNetwork);
        });
    };
    const isConnectedByIdFromContactNetworks = (id, contactNetworks) => {
        return !!getConnectedContactNetworkByIdFromContactNetworks(id, contactNetworks);
    };
    const canPullContentAutomatically = (networkId) => {
        const contactNetwork = getContactNetworkById(networkId);
        if (!contactNetwork) {
            return false;
        }
        if (isTiktokById(networkId) || isPinterestById(networkId)) {
            return contactNetwork && isOAuthed(contactNetwork);
        }
        else if (isInstagramById(networkId)) {
            return contactNetwork && !isFailed(contactNetwork);
        }
        else {
            return !!contactNetwork;
        }
    };
    const showUploadedContentDeliveryForMeta = (contactNetwork, brandNetworks, type = null, slug = null, mediaType = null) => {
        const isMetaUploadContentDelivery = type
            ? isMetaUploadContentDeliveryNetworkByType(type)
            : isMetaUploadContentDeliveryNetworkBySlugAndMediaType(slug, mediaType);
        return (isMetaUploadContentDelivery &&
            !isFacebookById(contactNetwork === null || contactNetwork === void 0 ? void 0 : contactNetwork.id) &&
            !isOAuthed(contactNetwork) &&
            !isSoftAuthed(contactNetwork));
    };
    const showUploadedContentDeliveryForTiktok = (networkId, contactNetwork) => {
        return isTiktokById(networkId) && !isOAuthed(contactNetwork);
    };
    const showUploadedContentDeliveryForPinterest = (networkId, contactNetwork) => {
        return isPinterestById(networkId) && !isOAuthed(contactNetwork);
    };
    const showUploadedContentDeliveryForNetwork = (networkId, contactNetwork, brandNetworks = null, type = null, slug = null, mediaType = null) => {
        return (showUploadedContentDeliveryForMeta(contactNetwork, brandNetworks, type, slug, mediaType) ||
            showUploadedContentDeliveryForTiktok(networkId, contactNetwork) ||
            showUploadedContentDeliveryForPinterest(networkId, contactNetwork));
    };
    const showSharedNetworkPreConnect = (socialNetwork, isLandingPage = false) => {
        return !!(socialNetwork &&
            !isLandingPage &&
            useCreatorStore().worksWithMulitpleBrands &&
            !useCreatorStore().getCurrentLiveUrlSocialNetwork(socialNetwork === null || socialNetwork === void 0 ? void 0 : socialNetwork.network_id) &&
            flags['live-site-shared-network-connections']);
    };
    const showSharedNetworkPostConnect = (socialNetwork, contactNetworks, isLandingPage = false) => {
        const liveUrlsNotConnected = useCreatorStore().getLiveUrlsMissingSocialNetwork(socialNetwork === null || socialNetwork === void 0 ? void 0 : socialNetwork.network_id, socialNetwork === null || socialNetwork === void 0 ? void 0 : socialNetwork.external_id);
        const contactNetwork = getContactNetworkByIdFromContactNetworks(socialNetwork === null || socialNetwork === void 0 ? void 0 : socialNetwork.network_id, contactNetworks);
        return !!(contactNetwork &&
            contactNetwork.network_connection_type === NETWORK_CONNECTION_TYPE_OAUTH &&
            socialNetwork &&
            !isLandingPage &&
            (liveUrlsNotConnected === null || liveUrlsNotConnected === void 0 ? void 0 : liveUrlsNotConnected.length) > 0 &&
            flags['live-site-shared-network-connections']);
    };
    /**
     * Deprecated as part of Spacejam
     * @deprecated
     */
    const isInstagramGraphRequiredForActivationBrief = (brief) => {
        var _a;
        return ((_a = (brief && brief.tasks.filter((task) => isInstagramGraphRequiredForActivationTask(task)))) === null || _a === void 0 ? void 0 : _a.length) > 0;
    };
    /**
     * Deprecated as part of Spacejam
     * @deprecated
     */
    const isInstagramGraphRequiredForActivationTasks = (tasks) => {
        var _a;
        return ((_a = (tasks && tasks.filter((task) => isInstagramGraphRequiredForActivationTask(task)))) === null || _a === void 0 ? void 0 : _a.length) > 0;
    };
    /**
     * Deprecated as part of Spacejam
     * @deprecated
     */
    const isInstagramGraphRequiredForActivationTask = (task) => {
        return false;
    };
    /**
     * Deprecated as part of Spacejam
     * @deprecated
     */
    const isInstagramGraphRequiredForCampaignTask = (task) => {
        return false;
    };
    return {
        getContactNetworkById,
        getContactNetworkByIdFromContactNetworks,
        getConnectedContactNetworkByIdFromContactNetworks,
        isConnectedByIdFromContactNetworks,
        isSoftAuthed,
        isOAuthed,
        isCreatorAccount,
        isMetaById,
        isTiktokById,
        isInstagramById,
        isPinterestById,
        isFacebookById,
        isMetaUploadContentDeliveryNetworkByType,
        isMetaUploadContentDeliveryNetworkBySlugAndMediaType,
        isValidFacebookInput,
        isBrandNetworkConnectedForNetworkById,
        isGraphAPIAvailable,
        isDiscoveryAPIAvailable,
        isInstagramGraphRequiredForActivationBrief,
        isInstagramGraphRequiredForActivationTasks,
        isInstagramGraphRequiredForActivationTask,
        isInstagramGraphRequiredForCampaignTask,
        canPullContentAutomatically,
        showUploadedContentDeliveryForMeta,
        showUploadedContentDeliveryForTiktok,
        showUploadedContentDeliveryForPinterest,
        showUploadedContentDeliveryForNetwork,
        showSharedNetworkPreConnect,
        showSharedNetworkPostConnect
    };
};
export default useNetworks;
