<template>
    <g-drawer
        :visible="visible"
        :wrap-style="overrideWrapStyle ? overrideWrapStyle : wrapStyle"
        :title="title"
        :placement="placement"
        :height="height"
        :width="width"
        :closable="closable"
        class="FullscreenDrawer"
        :get-container="container"
        @close="handleClose"
    >
        <div class="FullscreenDrawer__header-button-container">
            <slot name="header-button"></slot>
        </div>

        <div :class="{ FullscreenDrawer__content: true, 'FullscreenDrawer__content--has-title': !!title }">
            <slot />
        </div>
    </g-drawer>
</template>

<script>
    import { reactive } from 'vue-demi';

    export default {
        name: 'FullscreenDrawer',

        props: {
            visible: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            height: {
                type: String,
                default: '100%'
            },
            width: {
                type: String,
                default: '100%'
            },
            placement: {
                type: String,
                default: 'right'
            },
            closable: {
                type: Boolean,
                default: true
            },
            overrideWrapStyle: {
                type: Object,
                default: () => ({})
            },
            container: {
                type: String,
                default: '#app'
            }
        },

        setup(props, context) {
            const wrapStyle = reactive({ position: 'absolute' });

            const handleClose = () => {
                context.emit('close');
            };

            return {
                wrapStyle,
                handleClose
            };
        }
    };
</script>

<style lang="scss" scoped>
    .FullscreenDrawer {
        padding-top: 0;
        max-width: 100% !important;
        left: 0 !important;
        .FullscreenDrawer__content {
            max-width: 650px;
            margin: 0 auto;
            .FullscreenDrawer__content--has-title {
                margin-top: 55px;
            }
        }
        .FullscreenDrawer__content.FullscreenDrawer__content--has-title {
            margin-top: 55px;
        }
        .FullscreenDrawer__header-button-container {
            position: absolute;
            top: 18px;
            left: 24px;
            z-index: 2;
            cursor: pointer;
        }
    }

    ::v-deep .ant-drawer-header {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        z-index: 1;
        height: 55px;
    }
    ::v-deep .ant-drawer-title {
        font-weight: 600 !important;
        font-size: 1.125rem !important;
    }
</style>
