<template>
    <div>
        <template>
            <!-- Compensation -->
            <div class="form-section-container">
                <div class="campaign-form-title">
                    {{ customizationText('compensation_step_title', 'Compensation') }}
                </div>
                <div v-if="hasCompensation('product')" class="mt-10">
                    <products-compensation
                        :campaign="campaign"
                        @change="products => proposal_model.products = products"
                    />
                </div>
                <div v-if="campaign.affiliate_link_id || campaign.discount_code_group_id" class="mt-10">
                    <affiliate-compensation :campaign="campaign">
                        <span v-if="hasCompensation('affiliate')">
                            {{ customizationText('compensation_commission_overview', '') }}
                        </span>
                    </affiliate-compensation>
                </div>
                <div v-if="hasCompensation('money')" class="mt-10">
                    <money-compensation :campaign="campaign" />
                </div>
                <payout-account-notification :campaign="campaign" />
            </div>

            <!-- Requirements (Tasks) -->
            <div class="form-section-container">
                <div class="campaign-form-title">
                    {{ customizationText('tasks_step_title', 'Media Requirements') }}
                </div>
                <campaign-tasks :campaign="campaign" :proposal="proposal_model" />
                <!-- Tracking Notification -->
                <tracking-notification :campaign="campaign" />
            </div>

            <!-- Campaign Dates -->
            <campaign-dates :campaign="campaign" />

            <!-- Campaign Assets -->
            <div v-if="campaign.is_active && campaign.assets.length" class="form-section-container">
                <div class="campaign-form-title">
                    {{ customizationText('assets_step_title', 'Assets') }}
                </div>
                <p>{{ customizationText('assets_step_overview', ' Please take a look at the following assets:') }}</p>
                <div class="mt-15 mb-15">
                    <div v-for="asset in campaign.assets">
                        <a :href="asset.url" target="_blank">{{ asset.name }}</a>
                    </div>
                </div>
            </div>

            <div class="form-section-container">
                <div class="mb-15 text-bold">
                    {{ customizationText('other_info_step_title', 'Please make sure your information is up to date') }}
                </div>
                <!-- Shipping Address -->
                <address-info
                    ref="address"
                    :in-settings="false" />
            </div>
            <!-- Extra Fields -->
            <div v-if="campaign_fields && campaign_fields.length" class="form-section-container">
                <campaign-additional-fields
                    ref="fields"
                    :in-settings="false"
                    :page-fields="campaign.fields" />
            </div>

            <!-- Price, Terms and Submit -->
            <div class="form-section-container">
                <!-- Proposal Notes -->
                <proposal-notes
                    :campaign="campaign"
                    :proposal="proposal_model" />
            </div>

            <div class="form-section-container is-font-small">
                <!-- Content Rights -->
                <div v-if="content_rights" class="is-font-small mb-10">
                    <content-rights-preview :content-rights="content_rights" title="Content License" />
                </div>
                <proposal-price-and-terms :campaign="campaign" :proposal="proposal_model">
                    <template slot="terms">
                        <data-accept ref="terms">
                            I accept the terms of the foregoing proposal and agree to the
                        </data-accept>
                    </template>

                    <button
                        class="button is-medium"
                        :disabled="!can_submit"
                        :class="{'is-loading': sending}"
                        @click="sendProposal">
                        Submit
                    </button>

                    <div class="mt-25 text-italic text-12 text-light">
                        The provisions agreed to above apply in the event of any conflict with the <a
                            href="https://grin.co/terms-of-use-influencers/"
                            target="_blank">Terms</a>.
                    </div>
                </proposal-price-and-terms>
            </div>
        </template>
    </div>
</template>

<style>
    .is-font-small {
        font-size: 0.9rem
    }
</style>

<script>
    import {currencies} from '../../../../helpers/constants';
    import CampaignProposalMixin from './CampaignProposalMixin';
    import CampaignCustomizeMixin from '../../../../mixins/CampaignCustomizeMixin';
    import AffiliateCompensation from './compensation/AffiliateCompensation.vue';
    import MoneyCompensation from './compensation/MoneyCompensation.vue';
    import SelectedProducts from './compensation/SelectedProducts.vue';
    import ProposalNotes from './requirements/ProposalNotes.vue';
    import ProposalPriceAndTerms from './requirements/ProposalPriceAndTerms.vue';
    import CampaignTasks from './requirements/CampaignTasks.vue';
    import DataAccept from '../../../interface/elements/DataAccept.vue';
    import ProductsCompensation from './compensation/ProductsCompensation.vue';
    import TrackingNotification from '../partials/TrackingNotification.vue';
    import CampaignAdditionalFields from './requirements/CampaignAdditionalFields.vue';
    import AddressInfo from '../../settings/profile/AddressInfo.vue';
    import PayoutAccountNotification from '../partials/PayoutAccountNotification.vue';
    import ContentRightsPreview from './ContentRightsPreview';
    import _ from 'lodash';
    import CampaignDates from '../CampaignDates';
    import HttpErrorMixin from '../../../../../app-v2/components/providers/mixins/HttpErrorMixin';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'ProposalForm',

        /**
         * The component mixins
         *
         * @type {Array}
         */
        mixins: [
            CampaignCustomizeMixin,
            CampaignProposalMixin,
            HttpErrorMixin,
        ],

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            CampaignDates,
            PayoutAccountNotification,
            AddressInfo,
            CampaignAdditionalFields,
            TrackingNotification,
            DataAccept,
            AffiliateCompensation,
            MoneyCompensation,
            SelectedProducts,
            CampaignTasks,
            ProposalNotes,
            ProposalPriceAndTerms,
            ProductsCompensation,
            ContentRightsPreview,
        },

        /**
         * The component data
         *
         * @returns {Object}
         */
        data() {
            return {
                /**
                 * The proposal form
                 *
                 * @type {Object}
                 */
                proposal_model: {
                    tasks:    [],
                    price:    0,
                    notes:    null,
                    products: [],
                    agreed:   false,
                },

                /**
                 * The sending proposal loading boolean
                 *
                 * @type {Boolean}
                 */
                sending: false,

                /**
                 * Available currencies
                 *
                 * @type {Object}
                 */
                currencies,
            };
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The campaign id
             *
             * @returns {String}
             */
            campaign_id() {
                return this.$route.params.campaign_id;
            },

            /**
             * The additional campaign fields
             *
             * @returns {array}
             */
            campaign_fields() {
                return Array.isArray(this.campaign.fields)
                    ? this.campaign.fields.filter(field => field !== '')
                    : [];
            },

            /**
             * Whether or not to show the address form
             *
             * @returns {array}
             */
            show_address() {
                return this.campaign.is_active && this.$parent.needs_shipping;
            },

            /**
             * The campaign content end date
             *
             * @returns {string}
             */
            content_end() {
                return this.campaign.dates.content.end;
            },

            /**
             * The campaign proposals end date
             *
             * @returns {string}
             */
            proposals_end() {
                return this.campaign.dates.proposals.end;
            },

            /**
             * Whether the campaign has content and proposal dates
             *
             * @returns {boolean}
             */
            has_dates() {
                return this.content_end !== 'N/A' && this.proposals_end !== 'N/A';
            },

            /**
             * Retrieve the content rights
             *
             * @returns {object}
             */
            content_rights() {
                return _.get(this.campaign, 'content_rights.content_rights');
            },

            /**
             * Whether the proposal can be submitted or not
             *
             * @returns {boolean}
             */
            can_submit() {
                for (let i in this.campaign.tasks) {
                    if (this.campaign.tasks[i].required) {
                        if (this.proposal_model.tasks.indexOf(this.campaign.tasks[i].id) === -1) {
                            return false;
                        }
                    }
                }

                return true;
            },

            /**
             * Whether or not the user needs to provide their address info
             *
             * @returns {boolean}
             */
            needs_shipping() {
                return this.hasCompensation('product') && !this.$root.has_shipping;
            },
        },

        /**
         * The component methods
         *
         * @type {Object}
         */
        methods: {
            /**
             * Reset the proposal form
             */
            resetProposal() {
                this.proposal_model = {
                    tasks:    [],
                    price:    0,
                    notes:    null,
                    products: [],
                    agreed:   false,
                };
            },

            /**
             * Send the proposal
             */
            sendProposal() {
                this.sending = true;

                if (!this.proposalIsValid()) {
                    this.sending = false;
                    return;
                }

                const requests = this.collectRequests();

                // Promise.all(requests).then(() => this.submitProposal());

                requests[0]
                    .then(() => {
                        requests[1]
                            .then(() => this.submitProposal())
                            .catch(() => {
                                this.$emit('notify-error', 'There was an error submitting your proposal. Please make sure all form fields are filled out.');
                                this.sending = false;
                            });
                    })
                    .catch(() => {
                        this.$emit('notify-error', 'Please enter a valid shipping address');
                        this.sending = false;
                    });
            },

            /**
             * Submit the proposal
             */
            submitProposal() {
                this.$emit('clear-error');

                if (!this.proposalIsValid()) {
                    this.sending = false;
                    return;
                }

                // send proposal
                this.proposal_model.campaign_id = this.campaign.id;
                this.proposal_model.contact_id  = this.$root.contact_id;
                this.proposal_model.agreed      = this.$refs.terms.hasAccepted();

                this.$http.post('/campaigns/proposal/submit', this.proposal_model)
                    .then((res) => {
                        return this.addSteps(this.proposal_model.contact_id, this.proposal_model.campaign_id, res.data.id);
                    })
                    .then(() => this.finish())
                    .catch(res => {
                        if (res.status === 423) {
                            this.handleLockedException(res.data);
                        } else {
                            this.$emit('notify-error', res.data);
                        }
                    })
                    .then(() => this.sending = false)
                ;
            },

            /**
             * Add completed steps
             */
            addSteps(contactId, campaignId, proposalId) {
                const url = `/api/v1/contacts/${contactId}/campaigns/${campaignId}/proposals/${proposalId}/steps`;
                return this.$http.post(url);
            },

            /**
             * Collect the requests
             */
            collectRequests() {
                let requests = [];

                // update shipping address
                if (this.$refs.address) {
                    requests.push(this.$refs.address.updateProfile());
                } else {
                    requests.push(Promise.resolve());
                }

                // update fields
                if (this.$refs.fields) {
                    requests.push(this.$refs.fields.updateContact());
                } else {
                    requests.push(Promise.resolve());
                }

                return requests;
            },

            /**
             * Check the proposal
             *
             * @returns {Boolean}
             */
            proposalIsValid() {
                if (!this.$refs.address.$refs.address.isValid) {
                    this.$emit('notify-error', 'We need your shipping address, please fill it out!');
                    return false;
                }

                if (!this.phoneNumberValid()) {
                    this.$emit('notify-error', 'We need your phone number, please fill it out!');
                    return false;
                }

                if (!this.hasTasks()) {
                    this.$emit('notify-error', 'You must add at least 1 task to send a proposal');
                    return false;
                }

                if (!this.hasRequiredTasks()) {
                    this.$emit('notify-error', 'You must include all required tasks');
                    return false;
                }

                if (!this.acceptedTerms()) {
                    this.$emit('notify-error', 'You must agree to the proposal terms');
                    return false;
                }

                if (!this.hasProducts()) {
                    this.$emit('notify-error', 'Please select which product(s) you would like to receive');
                    return false;
                }

                return true;
            },

            /**
             * Check the phone number
             *
             * @returns {boolean}
             */
            phoneNumberValid() {
                if (this.campaign_fields.indexOf('phone_number') >= 0) {
                    const phoneRef = _.get(this, '$refs.fields.$refs.phone');

                    if (phoneRef) {
                        const phoneObject = phoneRef.getPhoneNumber();
                        return !!(phoneObject && phoneObject.phone);
                    } else {
                        return false;
                    }
                }
                return true;
            },

            /**
             * Check that proposal has at least one task
             *
             * @returns {Boolean}
             */
            hasTasks() {
                return !!this.proposal_model.tasks.length;
            },

            /**
             * Check required tasks
             *
             * @returns {Boolean}
             */
            hasRequiredTasks() {
                for (let i in this.campaign.tasks) {
                    if (this.campaign.tasks[i].required) {
                        if (this.proposal_model.tasks.indexOf(this.campaign.tasks[i].id) === -1) {
                            return false;
                        }
                    }
                }

                return true;
            },

            /**
             * Check for policy and terms
             *
             * @returns {Boolean}
             */
            acceptedTerms() {
                return (this.$refs.terms && this.$refs.terms.hasAccepted());
            },

            /**
             * Check for product selection
             *
             * @returns {Boolean}
             */
            hasProducts() {
                return (
                    !this.hasCompensation('product')
                    ||
                    !this.campaign.select_products
                    ||
                    this.proposal_model.products.length
                );
            },

            /**
             * Finish the proposal submission
             */
            finish() {
                this.resetProposal();
                this.$emit('proposal-complete', this.campaign);
            },
        },

        /**
         * When the component is created
         */
        created() {
            this.resetProposal();
        },
    };
</script>

<style scoped>
    .form-section-container {
        padding: 25px;
        border-bottom: 1px solid #f2f2f2;
        margin: 0 -1.5rem;
    }

    .form-section-container:last-child {
        border-bottom: none;
    }
</style>
