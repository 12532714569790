export const FAQ_PAYOUT_SUPPORT = 'https://help.grin.co/docs/managing-payouts';
export const FAQ_INSTAGRAM_CONNECTION = 'https://help.grin.co/docs/connecting-your-instagram-account';
export const FAQ_CONNECT_INSTAGRAM_WITHIN_FACEBOOK_PAGE = 'https://help.grin.co/docs/connecting-your-instagram-account';
export const FAQ_MANAGING_INSIGHTS = 'https://help.grin.co/docs/managing-insights';
export const FAQ_DELIVERING_STORY_INSIGHTS_MANUALLY = 'https://help.grin.co/docs/how-to-upload-content-manually-on-the-live-site#delivering-instagram-stories';
export const FAQ_INSTAGRAM_BUSINESS = 'https://help.instagram.com/502981923235522/?cms_platform=iphone-app&helpref=platform_switcher';
export const FAQ_INSTAGRAM_CREATOR = 'https://help.instagram.com/2358103564437429/?cms_platform=iphone-app&helpref=platform_switcher';
export const FAQ_FACEBOOK_CREATE_A_PAGE = 'https://www.facebook.com/business/help/473994396650734?id=939256796236247';
export const FAQ_CONNECTING_NETWORKS_PERMISSIONS = 'https://help.grin.co/docs/connecting-social-networks-faq#what-permissions-are-required-when-syncing-my-accounts';
export const FAQ_CONNECTING_INSTAGRAM = 'https://help.grin.co/docs/connecting-your-instagram-account';
export const FAQ_CONNECTING_TIKTOK = 'https://help.grin.co/docs/connecting-your-tiktok';
export const FAQ_CONNECTING_PINTEREST = 'https://help.grin.co/docs/connecting-your-pinterest';
export const FAQ_UPLOADED_CONTENT_DELIVERY = 'https://help.grin.co/v1/docs/how-to-upload-content-manually-on-the-live-site';
export const FAQ_CONTENT_APPROVAL = 'https://help.grin.co/v1/docs/submitting-content-for-approval';
export const FAQ_DATA_PRIVACY = 'https://help.grin.co/docs/en/creator-data-privacy';
