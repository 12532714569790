<template>
    <div
        class="column"
        :class="{
            'is-6': !isSelected,
            'is-12': isSelected || readOnly,
            'p-0': !isSelected && readOnly,
            'mb-5': !isSelected && readOnly
        }"
    >
        <!-- Product Added -->
        <article class="media selected-product" v-if="readOnly">
            <a class="media-left">
                <div class="image is-64x64 product-image" :style="`background-image: url(${image});`"></div>
            </a>

            <div class="media-content">
                <div class="content">
                    <div>
                        <div class="text-bold">{{ product.name }}</div>

                        <div v-if="!product.show_product" class="tag is-small is-warning is-light">Out of Stock</div>

                        <div v-else-if="product.select_options && !isDeal">
                            <div
                                v-for="(option, index) in product.options"
                                :key="option.id"
                                class="select is-small mr-5 mb-5"
                            >
                                <select v-model="selected_options[index].value_id" @change="handleOptionSelect(index)">
                                    <option :value="null">Choose {{ option.name }}</option>
                                    <option v-for="value in availableValues(option)" :key="value.id" :value="value.id">
                                        {{ value.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <span class="text-14 has-text-weight-normal" v-else>
                            <span v-if="selected_options && selected_options.length">
                                <span v-if="options_selected">
                                    (<span v-for="(selected_option, index) in selected_options">
                                        {{ optionValueLabel(selected_option) }}
                                        <span v-if="index < selected_options.length - 1"> / </span> </span
                                    >)
                                </span>

                                <span v-else>(No Options Selected)</span>
                            </span>
                        </span>

                        <span v-if="forcePrices">
                            <money-display :value="product.price.toFixed(2)" :currency="currency" />
                        </span>
                    </div>
                </div>
            </div>

            <div v-if="allowDelete" class="media-right">
                <a class="delete" @click="handleDelete"></a>
            </div>
        </article>

        <!-- Product List / Details -->
        <div class="card" v-else>
            <div
                class="card-image"
                @click="
                    () => {
                        if (!isSelected) {
                            toggleSelect();
                        }
                    }
                "
            >
                <figure
                    :style="`background-image: url(${image});`"
                    class="image is-square bg-image"
                    v-if="!isSelected"
                ></figure>
                <div v-else>
                    <carousel
                        :perPage="1"
                        :autoplay="true"
                        :paginationEnabled="false"
                        :navigationEnabled="false"
                        v-if="product.images && product.images.length"
                    >
                        <slide v-for="img in product.images" :key="img.src">
                            <div class="bg-image image-slide" :style="`background-image: url(${img.src})`"></div>
                        </slide>
                    </carousel>
                    <div class="bg-image image-slide" :style="`background-image: url(${image})`" v-else></div>
                </div>
            </div>
            <div
                class="card-content"
                :class="{ 'p-5': !isSelected, 'p-15': isSelected, 'has-border-top': isSelected && !readOnly }"
            >
                <div class="content text-center" :class="{ 'center-text-v': !isSelected }">
                    <div
                        class="product-title"
                        :class="{ 'product-title-height': !isSelected, 'product-title-details': isSelected }"
                    >
                        {{ product.name }}
                    </div>

                    <div v-if="isSelected">
                        <span v-if="!product.show_product" class="tag is-small is-warning is-light">
                            Out of Stock
                        </span>

                        <span v-if="showPrices" class="ml-5">
                            <money-display :value="product.price.toFixed(2)" :currency="currency" />
                        </span>

                        <div class="text-12 mt-10" v-if="show_description">
                            <div class="product-description" v-html="$sanitize(product.requirements_br)"></div>
                        </div>
                    </div>

                    <div class="mt-15" v-if="isSelected">
                        <div v-if="allowSelect && product.show_product">
                            <div v-if="product.show_product && product.select_options">
                                <div
                                    v-for="(option, index) in product.options"
                                    :key="option.id"
                                    class="select is-small mr-5 mb-5"
                                >
                                    <select
                                        v-model="selected_options[index].value_id"
                                        @change="handleOptionSelect(index)"
                                    >
                                        <option :value="null">Choose {{ option.name }}</option>
                                        <option
                                            v-for="value in availableValues(option)"
                                            :key="value.id"
                                            :value="value.id"
                                        >
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <button class="button is-small add-button" :disabled="add_disabled" @click="handleAdd">
                                <span>Add Product</span>
                                <span v-if="showPrices && options_selected && selected_variant_price" class="ml-5">
                                    (<money-display :value="selected_variant_price.toFixed(2)" :currency="currency" />)
                                </span>
                            </button>
                        </div>

                        <div class="mt-15">
                            <button class="button is-light is-cancel is-small" @click="toggleSelect">
                                <span class="icon mr-5"><i class="far fa-chevron-left"></i></span>
                                Back
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="allowDelete" class="text-center">
                    <button class="button is-small" @click="handleDelete">Remove</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .image-slide {
        width: 100%;
        height: 425px;
    }
    .bg-image {
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
    .product-title-height {
        height: 45px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    .product-description {
        word-break: break-word;
        @media only screen and (max-width: 800px) {
            line-height: 18px;
        }
    }
    .product-title-details {
        font-size: 16px !important;
        font-weight: bold !important;
        margin-bottom: 10px !important;
        line-height: 22px !important;
    }
</style>
<script>
    import _ from 'lodash';
    import { Carousel, Slide } from 'vue-carousel';
    import MoneyDisplay from '../../../../interface/elements/MoneyDisplay.vue';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'ProductsCompensationListItem',

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            Carousel,
            Slide,
            MoneyDisplay
        },

        /**
         * The component properties
         *
         * @type {Object}
         */
        props: {
            /**
             * The product
             */
            product: {
                type: Object,
                required: true
            },

            /**
             * Whether or not this is for a deal
             */
            isDeal: {
                type: Boolean,
                default: false
            },

            /**
             * Whether or not to show product prices
             */
            showPrices: {
                type: Boolean,
                default: false
            },

            /**
             * Whether or not to force showing prices in read-only mode
             */
            forcePrices: {
                type: Boolean,
                default: false
            },

            /**
             * Currency code
             */
            currency: {
                type: String,
                default: 'usd'
            },

            /**
             * Whether or not to allow to select this product
             */
            allowSelect: {
                type: Boolean,
                default: false
            },

            /**
             * Whether or not this product is selected
             */
            isSelected: {
                type: Boolean,
                default: false
            },

            /**
             * Whether or not this product is in the read-only mode
             */
            readOnly: {
                type: Boolean,
                default: false
            },

            /**
             * Whether or not to allow to delete this product
             */
            allowDelete: {
                type: Boolean,
                default: false
            }
        },

        /**
         * The component data
         *
         * @returns {Object}
         */
        data() {
            return {
                /**
                 * Whether or not we are viewing an image modal
                 *
                 * @type {Boolean}
                 */
                viewing_image: false,

                /**
                 * The selected product options
                 *
                 * @type {Array}
                 */
                selected_options: []
            };
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The image modal width
             *
             * @returns {String}
             */
            modal_width() {
                if (window.innerWidth < 500) {
                    return '90%';
                }

                return '50%';
            },

            /**
             * The product image
             *
             * @returns {String}
             */
            image() {
                return this.product.image || 'https://d1igyfcg6nq1cr.cloudfront.net/placeholder/placeholder-square.jpg';
            },

            /**
             * Whether or not to show the description
             *
             * @returns {boolean}
             */
            show_description() {
                return (
                    this.isSelected &&
                    !this.readOnly &&
                    this.product.requirements_br &&
                    this.product.requirements_br !== 'null'
                );
            },

            /**
             * The max variant price
             */
            max_price() {
                if (this.product.hasOwnProperty('variants') && this.product.variants.length) {
                    let variants = this.product.variants;

                    if (this.product.track_inventory && this.product.track_variant_inventory) {
                        variants = this.product.variants.filter((variant) => variant.qty > 0);
                    }

                    return _.maxBy(variants, 'price').price;
                }

                return 0;
            },

            /**
             * Whether the add product button is disabled
             *
             * @returns {boolean}
             */
            add_disabled() {
                return !!((this.product.has_options && !this.options_selected) || this.st);
            },

            /**
             * Whether or not a user selected all the product's options
             *
             * @returns {Boolean}
             */
            options_selected() {
                for (let i in this.selected_options) {
                    if (this.selected_options[i].value_id === null) {
                        return false;
                    }
                }

                return true;
            },

            /**
             * Selected variant's price
             *
             * @returns {Number}
             */
            selected_variant_price() {
                let price = null;

                for (let i in this.product.variants) {
                    const variant = this.product.variants[i];

                    price = variant.price;

                    for (let j in variant.option_values) {
                        const value_id = variant.option_values[j].pivot.option_value_id;

                        if (_.findIndex(this.selected_options, { value_id }) === -1) {
                            price = null;
                            break;
                        }
                    }

                    if (price !== null) {
                        break;
                    }
                }

                return price;
            },

            /**
             * Selected product's price
             *
             * @returns {Number}
             */
            price() {
                if (this.product.has_options && !this.product.select_options && this.product.variant_id) {
                    const variant = _.find(this.product.variants, { id: this.product.variant_id });

                    return variant.price;
                }

                if (this.selected_options.length) {
                    return this.selected_variant_price;
                }

                return this.product.price;
            }
        },

        /**
         * The component watchers
         *
         * @type {Object}
         */
        watch: {
            isSelected: function () {
                this.rebuildOptions();
            }
        },

        /**
         * The component methods
         *
         * @type {Object}
         */
        methods: {
            /**
             * Show the larger image modal
             */
            showImage() {
                this.viewing_image = true;
            },

            /**
             * Rebuild the selected options array
             */
            rebuildOptions() {
                if (this.readOnly) {
                    this.selected_options = this.product.selected_options;

                    return;
                }

                let options = [];

                if (this.isSelected && this.product.has_options && this.product.select_options) {
                    this.product.options.forEach((option) => {
                        options.push({
                            id: option.id,
                            value_id: this.getSelectedOptionValueId(option.id)
                        });
                    });
                }

                this.selected_options = options;
            },

            /**
             * Try to find a value ID for a given option
             *
             * @returns {Number|null}
             */
            getSelectedOptionValueId(optionId) {
                if (!this.product.selected_options) {
                    return null;
                }

                const option = _.find(this.product.selected_options, { id: optionId });

                if (option) {
                    return option.value_id || null;
                }

                return null;
            },

            /**
             * Return a label for a selected option's value
             *
             * @param {Object} selected_option
             * @returns {String}
             */
            optionValueLabel(selected_option) {
                const option = _.find(this.product.options, { id: selected_option.id });

                return _.find(option.values, { id: selected_option.value_id }).name;
            },

            /**
             * Return in-stock values for a given option
             *
             * @param {Object} option
             * @returns {Array}
             */
            availableValues(option) {
                if (this.product.track_inventory && this.product.track_variant_inventory) {
                    return option.values.filter((value) => {
                        const variants = this.product.variants.filter((variant) => {
                            const values = variant.option_values.filter((option_value) => {
                                return option_value.pivot.option_value_id === value.id;
                            });

                            return values.length && variant.qty > 0;
                        });

                        return variants.length;
                    });
                }

                return option.values;
            },

            /**
             * Handle the select product event
             */
            toggleSelect() {
                if (this.allowSelect) {
                    this.$emit('select', !this.isSelected);
                }
            },

            /**
             * Handle the option select event
             *
             * @param {Number} index
             */
            handleOptionSelect(index) {
                const option = this.selected_options[index];

                this.$emit('option-select', option.id, option.value_id);
            },

            /**
             * Handle the add product event
             */
            handleAdd() {
                this.$emit('add', this.selected_options);
            },

            /**
             * Handle the delete product event
             */
            handleDelete() {
                this.$emit('delete');
            }
        },

        /**
         * When the component first created
         */
        created() {
            this.rebuildOptions();
        }
    };
</script>
