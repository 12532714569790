<template>
    <div>
        <label class="label">Checkout URL</label>

        <div class="field has-addons">
            <p class="control is-expanded">
                <input class="input is-medium" :placeholder="placeholder" v-model="url" />
            </p>

            <p class="control">
                <button
                    class="button is-medium"
                    :class="{ 'is-loading': loading }"
                    :disabled="!url"
                    @click="loadProducts"
                >
                    OK
                </button>
            </p>
        </div>

        <div v-if="invalidUrl" class="has-text-danger">
            <div>Oops, wrong URL. Let’s try this again.</div>

            <img v-if="!selectionMethodWebsiteShopifySharedCart" src="https://d38xvr37kwwhcm.cloudfront.net/image/checkout-example-unbranded.gif" />
        </div>

        <products-compensation-list-item
            v-for="(product, index) in selectedProducts"
            :key="`${index}-${product.id}`"
            :product="product"
            :force-prices="!!settings.show_prices"
            :currency="campaign.brand.currency"
            :allow-delete="true"
            :read-only="true"
            @delete="removeProduct(index)"
        />

        <div v-if="maxProductsExceeded" class="has-text-danger">
            Oops, please limit your cart to
            <strong class="has-text-danger">{{ settings.max_products }}</strong> item(s)
        </div>

        <div v-if="maxPriceExceeded" class="has-text-danger">
            Oops, please limit your cart to
            <strong class="has-text-danger">
                <money-display :value="settings.max_product_price" :currency="campaign.brand.currency" />
            </strong>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import MoneyDisplay from '../../../../interface/elements/MoneyDisplay.vue';
    import ProductsCompensationListItem from './ProductsCompensationListItem.vue';
    import { useFeatureFlagsStore } from '../../../../../../stores';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'ProductsCompensationCheckout',

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            MoneyDisplay,
            ProductsCompensationListItem
        },

        /**
         * The component props
         *
         * @type {Object}
         */
        props: {
            /**
             * The campaign
             */
            campaign: {
                type:     Object,
                required: true
            },

            /**
             * A deal's proposal
             */
            proposal: Object,

            /**
             * Selected products
             */
            products: {
                type:    Array,
                default: () => []
            }
        },

        /**
         * The component data
         *
         * @returns {Object}
         */
        data() {
            return {
                /**
                 * Checkout URL
                 *
                 * @type {String}
                 */
                url: null,

                /**
                 * Whether or not the URL is invalid
                 *
                 * @type {Boolean}
                 */
                invalidUrl: false,

                /**
                 * Whether or not products are being loaded
                 *
                 * @type {Boolean}
                 */
                loading: false,

                /**
                 * Current products
                 *
                 * @type {Array}
                 */
                selectedProducts: []
            };
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The compensation settings
             *
             * @returns {Object}
             */
            settings() {
                return (this.proposal ? this.proposal.compensation_settings : this.campaign);
            },

            /**
             * Input's placeholder
             *
             * @returns {String}
             */
            placeholder() {
                //return `${this.campaign.brand.website_url}/25478/checkouts/bc2b897c297fd1600bce01ec960be71d`;
                return 'Paste your checkout url here'
            },

            /**
             * Total product price
             *
             * @returns {Number}
             */
            totalPrice() {
                return this.selectedProducts.reduce((total, product) => (total + product.price), 0);
            },

            /**
             * Whether or not the maximum products limit is exceeded
             *
             * @returns {Boolean}
             */
            maxProductsExceeded() {
                return (
                    this.settings.max_products
                    && this.selectedProducts.length > this.settings.max_products
                );
            },

            /**
             * Whether or not the maximum price limit is exceeded
             *
             * @returns {Boolean}
             */
            maxPriceExceeded() {
                return (
                    this.settings.show_prices
                    && this.settings.max_product_price
                    && this.totalPrice > this.settings.max_product_price
                );
            },

            selectionMethodWebsiteShopifySharedCart() {
                return useFeatureFlagsStore().data['product-select-from-website-q2-2024'] || false;
            }
        },

        /**
         * The component methods
         *
         * @type {Object}
         */
        methods: {
            /**
             * Set current products
             */
            setSelectedProducts(products) {
                this.selectedProducts = products.map(product => ({
                    ...product,
                    selected_options: this.getSelectedOptions(product)
                }));
            },

            /**
             * Return selected options for a product
             *
             * @param   {Object} product
             * @returns {Array}
             */
            getSelectedOptions(product) {
                if (!product.has_options || !product.variant_id) {
                    return [];
                }

                const variant = _.find(product.variants, { id: product.variant_id });

                return variant.option_values.map(option_value => ({
                    id:       option_value.option_id,
                    value_id: option_value.id
                }));
            },

            /**
             * Load products using a checkout URL
             */
            loadProducts() {
                this.loading    = true;
                this.invalidUrl = false;

                this.$http.post(`/campaigns/${this.campaign.id}/products/checkout`, { url: this.url })
                    .then(response => {
                        this.setSelectedProducts(response.data);

                        this.handleChange();
                    })
                    .catch(() => this.invalidUrl = true)
                    .then(() => this.loading = false)
                ;
            },

            /**
             * Remove a product
             *
             * @param {Number} index
             */
            removeProduct(index) {
                this.selectedProducts.splice(index, 1);

                this.handleChange();
            },

            /**
             * Handle the "change" event
             */
            handleChange() {
                this.$emit('change', this.selectedProducts.map(product => ({
                    id:      product.id,
                    options: product.selected_options
                })));
            }
        },

        /**
         * After the component is created
         */
        created() {
            this.setSelectedProducts(this.products);
        }
    }
</script>