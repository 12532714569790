<template>
    <div class="data-accept mb-10">
        <el-checkbox v-model="terms" />
        <span class="ml-5">
            <slot />
            <a href="https://www.grin.co/privacy-policy" target="_blank">Privacy Policy</a>
            and
            <a href="https://www.grin.co/terms-of-use-influencers" target="_blank">Terms</a>
        </span>
    </div>
</template>

<style lang="scss">
    .data-accept {
        font-size: 0.9em;
        #terms {
            vertical-align: middle;
        }
        a {
            color: #3a424b;
            text-decoration: underline;
        }
    }
</style>

<script>
    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'DataAccept',

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {
                /**
                 * The checkbox terms model
                 *
                 * @type {boolean}
                 */
                terms: false
            };
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {
            /**
             * Whether or not the user has accepted the terms
             *
             * @returns {boolean}
             */
            hasAccepted() {
                return this.terms;
            }
        }
    };
</script>
