<template>
    <div class="connect-wrapper">
        <g-loader v-if="fullscreenLoader" type="fixed" />
        <div v-if="context === 'profile'" class="margin-bottom-normal">
            <g-button @click="handleBack">Back</g-button>
        </div>

        <div v-if="failed || failurePage">
            <connect-network-failed :network="failed || failurePage" @go-back="handleTryAgain" />
        </div>
        <div v-else>
            <img class="logo-style" :src="getNetworkConnectionInfo().logo" alt="Logo" />
            <div class="h1">
                <g-typography tag="h1" font-weight="semibold" text-align="center">
                    <span>{{ getNetworkConnectionInfo().title }}</span>
                </g-typography>
            </div>
            <div class="text-wrapper">
                <div v-if="isInstagramById(networkId) && (isDiscoverable || discoverUnavailable)">
                    <g-alert
                        v-if="isDiscoverable"
                        message="Looks like you have the right type of account! Click connect to continue"
                        type="success"
                        show-icon
                    />
                    <g-alert
                        v-else
                        message="Just a few more steps! Click connect to continue"
                        type="success"
                        show-icon
                    />

                    <div class="margin-top-2">
                        <g-button
                            class="connect-button"
                            data-testid="connect"
                            size="xlarge"
                            shape="round"
                            theme="live"
                            type="primary"
                            @click="onConnect"
                        >
                            Connect
                        </g-button>
                    </div>

                    <div class="margin-top-2 margin-bottom-3">
                        <g-link
                            v-if="!fullConnectionRequired"
                            data-testid="dont-link-creator"
                            @click="handleDontLinkCreator"
                        >
                            <g-typography color="grey-6" size="h5" value="I don't want to connect my creator account" />
                        </g-link>
                    </div>
                </div>
                <div v-else-if="isMetaById(networkId) && thirdParty.error">
                    <g-alert :message="getNetworkConnectionInfo().error" type="error" show-icon />

                    <div class="margin-top-2">
                        <g-button
                            size="xlarge"
                            shape="round"
                            theme="live"
                            type="primary"
                            @click="handleRetryThirdPartyConnect"
                        >
                            Retry
                        </g-button>
                    </div>
                </div>
                <div v-else>
                    <g-input
                        v-model="payload.username"
                        addon-before="@"
                        size="large"
                        :placeholder="getNetworkConnectionInfo().placeholder"
                    />

                    <g-typography class="g-mt-5" tag="div" text-align="left" color="grey-6">
                        {{ networkFullUrl }}
                    </g-typography>

                    <div class="margin-top-2">
                        <g-button
                            class="connect-button"
                            data-testid="continue"
                            size="xlarge"
                            shape="round"
                            theme="live"
                            type="primary"
                            :loading="loading"
                            :disabled="!usernameIsValid"
                            @click="handleContinue"
                        >
                            {{ getNetworkConnectionInfo().cta }}
                        </g-button>
                    </div>

                    <div v-if="isInstagramById(networkId) && !useThirdParty" class="margin-top-2 margin-bottom-3">
                        <g-link v-if="fullConnectionRequired" @click="handleInstagramFaq">
                            <g-typography color="grey-6" size="h5" value="Why do I need a professional account?" />
                        </g-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { toRefs, computed } from 'vue-demi';
    import { message } from '@grininc/grin-ui';
    import { getMeta, popup } from '../../../../../src/helpers';
    import {
        INSTAGRAM_ID,
        INSTAGRAM_SLUG,
        INSTAGRAM_ACCOUNT_SLUG,
        NETWORK_INFO_BY_ID,
        NETWORK_CONNECTION_INFO_BY_ID,
        NETWORK_CONNECTION_TYPE_FAILED,
        THIRD_PARTY_NETWORK_CONNECTION_INFO_BY_ID,
        DISCOVERY_NO_BRAND_TOKEN,
        DISCOVERY_INVALID_BRAND_TOKEN,
        DISCOVERY_INVALID_BRAND_PERMISSIONS,
        CONNECTION_FAILURE_REASON_INCOMPLETE,
        CONNECTION_FAILURE_REASON_ACCOUNT_MISSING,
        CONNECTION_FAILURE_REASON_CONNECTION_PENDING,
        FACEBOOK_MISSING_USERNAME, PINTEREST_SLUG, FACEBOOK_SLUG, TIKTOK_SLUG
    } from '../../../../constants/networks';
    import { NETWORK_CONNECTION_INFO_EVENTS_BY_ID } from '../../../../constants/analytics-events';
    import ConnectNetworkFailed from './ConnectNetworkFailed.vue';
    import WindowEventMixin from '../../../../mixins/WindowEventMixin';
    import useEventTracking from '../../../../../src/composables/use-event-tracking';
    import useNetworks from '../../../../../src/composables/use-networks';
    import { useFeatureFlagsStore } from '../../../../../stores';

    export default {
        name: 'NetworkConnectionInfo',
        mixins: [WindowEventMixin],
        components: {
            ConnectNetworkFailed
        },
        props: {
            context: {
                type: String,
                default: 'proposal'
            },
            networkId: {
                type: Number,
                required: true
            },
            contactId: {
                type: String,
                required: true
            },
            instagramGraphRequired: {
                type: Boolean,
                default: false
            },
            liveUrl: {
                type: String,
                default: ''
            },
            campaignId: {
                type: String,
                default: ''
            },
            proposalId: {
                type: String,
                default: ''
            },
            failurePage: {
                type: String,
                default: ''
            }
        },
        data() {
            const flags = useFeatureFlagsStore().data;

            return {
                loading: false,
                fullscreenLoader: false,
                discoverable: null,
                failed: null,
                payload: {
                    network_id: null,
                    username: undefined,
                    soft: true
                },
                messages: {
                    failure: 'network-error',
                    success: 'network-success'
                },
                debouncedFormatUsername: debounce(() => {
                    const networkSlug = NETWORK_INFO_BY_ID[this.networkId]?.slug;
                    let { username } = this.payload;
                    if (!username || !networkSlug) {
                        return;
                    }

                    const cleanUrl = username.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\s+/g, '');

                    const extractUsername = (url, symbol) => {
                        const parts = url.split(symbol);
                        return parts[1] ? parts[1].split('?')[0] : url;
                    };

                    switch (networkSlug) {
                        case INSTAGRAM_SLUG:
                            username = extractUsername(cleanUrl, '/').replace(/[^0-9a-z._]/gi, '');
                            break;

                        case TIKTOK_SLUG:
                            username = cleanUrl.replace(/[^0-9a-z._]/gi, '');
                            break;

                        case PINTEREST_SLUG:
                            username = extractUsername(cleanUrl, '/');
                            break;

                        case FACEBOOK_SLUG:
                            if (cleanUrl.includes('profile.php')) {
                                return;
                            }
                            username = cleanUrl.includes('@')
                                ? extractUsername(cleanUrl, '@')
                                : extractUsername(cleanUrl, '/');
                            break;

                        default:
                            username = cleanUrl;
                            break;
                    }

                    this.payload.username = username;
                }, 300),
                thirdParty: {
                    error: false
                }
            };
        },
        computed: {
            /**
             * The network authentication host
             *
             * @return {String}
             */
            host() {
                return `networkauth.${getMeta('routing-domain')}`;
            },

            /**
             * Whether the username is valid
             *
             * @return {Boolean}
             */
            usernameIsValid() {
                if (!this.payload.username) {
                    return false;
                }

                if (this.isInstagramById(this.networkId)) {
                    return this.payload.username.length >= 3;
                } else if (this.isFacebookById(this.networkId)) {
                    // https://www.facebook.com/help/105399436216001?helpref=faq_content
                    return this.payload.username.length >= 5;
                } else if (this.isTiktokById(this.networkId)) {
                    return this.payload.username.length >= 4;
                } else if (this.isPinterestById(this.networkId)) {
                    return this.payload.username.length >= 3 && this.payload.username.length <= 30;
                }

                return false;
            },

            /**
             * Whether the Instagram account is discoverable (creator/business)
             *
             * @return {Boolean}
             */
            isDiscoverable() {
                return this.discoverable && this.discoverable.success;
            },

            /**
             * Whether the grin account has configured a social account to allow for discovery of creators
             *
             * @return {Boolean}
             */
            discoverUnavailable() {
                return (
                    this.discoverable &&
                    !this.discoverable.success &&
                    (this.discoverable.message === DISCOVERY_NO_BRAND_TOKEN ||
                        this.discoverable.message === DISCOVERY_INVALID_BRAND_TOKEN ||
                        this.discoverable.message === DISCOVERY_INVALID_BRAND_PERMISSIONS)
                );
            },

            /**
             * Whether a full connection is required
             */
            fullConnectionRequired() {
                return (this.$route && this.$route.query && this.$route.query.stories) || this.instagramGraphRequired;
            },

            /**
             * Returns the network url with the username
             * @returns {*}
             */
            networkFullUrl() {
                return `${this.getNetworkConnectionInfo().url}${this.payload?.username?.toLowerCase() || ''}`;
            }
        },
        methods: {
            /**
             * Instagram - Start the connection process
             */
            startInstagramConnection() {
                this.loading = true;
                this.startInstagramDiscovery();
            },

            /**
             * Instagram - Start the discovery process
             */
            startInstagramDiscovery() {
                const payload = {
                    username: this.payload.username.toLowerCase(),
                    contact_id: this.contactId
                };

                this.$http
                    .post('/api/v1/networks/instagram/discoverable', payload)
                    .then((res) => (this.discoverable = res.data))
                    .catch(() => this.handleInstagramDiscoverFailed())
                    .then(() => this.handlePostInstagramDiscover());
            },

            /**
             * Instagram - Continue connection process, after discovery
             */
            handlePostInstagramDiscover() {
                if (this.isDiscoverable || this.discoverUnavailable) {
                    this.loading = false;
                    return;
                }

                if (this.fullConnectionRequired) {
                    return this.handleFailure(INSTAGRAM_ACCOUNT_SLUG);
                }

                // When the brand is oauthed and discovery api returns "not discoverable"
                // the creator account cannot be found
                return this.handleFailure(INSTAGRAM_ACCOUNT_SLUG);
            },

            /**
             * Instagram - Handle the discovery failure
             */
            handleInstagramDiscoverFailed() {
                if (this.fullConnectionRequired) {
                    return this.handleFailure(INSTAGRAM_ACCOUNT_SLUG);
                }

                this.createSoftInstagramConnection();
            },

            /**
             * Starts the OAuth process by opening the popup
             */
            onConnect() {
                this.showPopup();
            },

            /**
             * Creates the OAuth URL for the popup
             */
            createOAuthUrl() {
                const safariWebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
                const androidWebview = /; wv/.test(navigator.userAgent);

                const slug = NETWORK_INFO_BY_ID[this.networkId].slug;

                let url = `https://${this.host}/contact/${this.$root.contact_id}/auth/${slug}/redirect?username=${this.payload.username}&start=true`;

                // Mobile opens a new tab instead of a popup
                // In this case we redirect in the new tab
                if (safariWebview || androidWebview) {
                    if (this.campaignId) {
                        url += `&origin=${window.location.origin}&live_url=${this.liveUrl}&campaign_id=${this.campaignId}&proposal_id=${this.proposalId}`;
                    } else if (this.context === 'brief') {
                        url += `&origin=${window.location.origin}&live_url=${this.liveUrl}&brief_id=${this.brief.id}`;
                    } else if (
                        this.$router?.history?.current.query.briefId &&
                        this.$router?.history?.current.query.deliverableId &&
                        this.$router?.history?.current.query.taskId &&
                        this.$route?.params?.live_url
                    ) {
                        url += `&origin=${window.location.origin}&live_url=${this.$route.params.live_url}&brief_id=${this.$route.query.briefId}&task_id=${this.$route.query.taskId}&deliverable_id=${this.$route.query.deliverableId}`;
                    } else if (this.$route) {
                        url += `&origin=${window.location.origin}&route=${this.$route.fullPath}`;
                    }
                }

                return url;
            },

            /**
             * Show the network oauth connection popup
             */
            showPopup() {
                this.loading = true;

                const pop = popup(this.createOAuthUrl(), 500, this.isPinterestById(this.networkId) ? 750 : 600);

                const interval = setInterval(() => {
                    if (pop.closed) {
                        this.loading = false;
                        clearInterval(interval);
                    }
                }, 200);

                this.listenForMessages();
            },

            /**
             * Listen for the failure message
             */
            listenForMessages() {
                this.listenForWindowMessage([this.messages.failure, this.messages.success], (event) => {
                    if (event.data.message === this.messages.failure) {
                        return this.handleFailure(event.data.network);
                    }

                    if (event.data.message === this.messages.success) {
                        this.$emit('popup-closed', this.networkId);
                        return this.$emit('go-back');
                    }
                });
            },

            /**
             * Send the given network error
             *
             * @param {string} network
             */
            async handleFailure(network) {
                if (network === INSTAGRAM_ACCOUNT_SLUG) {
                    this.payload.failure_reason = CONNECTION_FAILURE_REASON_ACCOUNT_MISSING;
                    await this.createFailedInstagramConnection();
                } else if (network === INSTAGRAM_SLUG) {
                    this.payload.failure_reason = CONNECTION_FAILURE_REASON_INCOMPLETE;
                    await this.createFailedInstagramConnection();
                }

                this.loading = false;
                if (this.context === 'profile') {
                    return (this.failed = network);
                }

                if (this.context === 'proposal') {
                    return this.$router.push({
                        name: 'v2.campaign.proposal.networks.failed',
                        params: { network }
                    });
                }

                if (this.context === 'brief') {
                    return this.$router.push({
                        name: 'v2.activation.brief.networks.failed',
                        params: { network }
                    });
                }
                this.$emit('go-back');
            },

            /**
             * Instagram - Connect softly
             */
            createSoftInstagramConnection() {
                this.loading = true;
                this.fullscreenLoader = true;

                this.payload.network_id = INSTAGRAM_ID;
                this.payload.url = this.networkFullUrl;
                this.payload.failure_reason = CONNECTION_FAILURE_REASON_INCOMPLETE;

                this.$http
                    .post(`/api/v1/contacts/${this.contactId}/networks`, this.payload)
                    .then((res) => {
                        this.$emit('load-contact');
                        this.$root.reloadContact('networks');
                        return this.$emit('go-back');
                    })
                    .catch(() => {
                        this.handleFailure(INSTAGRAM_SLUG);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.fullscreenLoader = false;
                    });
            },

            /**
             * 3rd Party Connect
             */
            createThirdPartyConnection() {
                if (!this.isMetaById(this.networkId)) {
                    return;
                }

                if (this.isFacebookById(this.networkId) && !this.isValidFacebookInput(this.payload.username)) {
                    this.handleFailure(FACEBOOK_MISSING_USERNAME);
                    return;
                }

                this.loading = true;
                this.fullscreenLoader = true;

                this.payload.network_id = this.networkId;
                this.payload.url = this.networkFullUrl;

                // TODO: Spacejam - Remove failure_reason when the auth is available for FB
                if (this.isFacebookById(this.networkId)) {
                    this.payload.failure_reason = CONNECTION_FAILURE_REASON_CONNECTION_PENDING;
                }

                this.$http
                    .post(`/api/v1/contacts/${this.contactId}/networks`, this.payload)
                    .then((res) => {
                        if (
                            res?.data?.network_connection_type === NETWORK_CONNECTION_TYPE_FAILED &&
                            res?.data?.failure_reason !== CONNECTION_FAILURE_REASON_CONNECTION_PENDING
                        ) {
                            this.thirdParty.error = true;
                        } else {
                            this.$emit('load-contact');
                            this.$root.reloadContact('networks');
                            return this.$emit('go-back');
                        }
                    })
                    .catch((e) => {
                        message.error(
                            'Something went wrong. Please try again or contact support if the problem continues.'
                        );
                    })
                    .finally(() => {
                        this.loading = false;
                        this.fullscreenLoader = false;
                    });
            },

            handleRetryThirdPartyConnect() {
                this.thirdParty.error = false;
            },

            /**
             * Instagram - Creates a failed network connection
             */
            async createFailedInstagramConnection() {
                this.loading = true;

                try {
                    this.payload.network_id = INSTAGRAM_ID;
                    this.payload.url = this.networkFullUrl;
                    await this.$http.post(`/api/v1/contacts/${this.contactId}/networks`, this.payload);

                    this.$emit('load-contact');
                    this.$root.reloadContact('networks');
                } catch (e) {
                    //
                }

                this.loading = false;
            },

            /**
             * Instagram - Show the FAQ
             */
            handleInstagramFaq() {
                this.$emit('show-instagram-faq');
            },

            /**
             * Clear the failed message
             */
            handleTryAgain() {
                this.failed = null;
                this.$emit('reset-failure-page');
            },

            handleBack() {
                this.goBackAnalyticsEvent();
                this.$emit('go-back');
            },

            handleDontLinkCreator() {
                this.dontLinkCreatorAnalyticsEvent();
                this.createSoftInstagramConnection();
            },

            handleContinue() {
                if (this.useThirdParty) {
                    this.createThirdPartyConnection();
                } else {
                    this.continueAnalyticsEvent();

                    if (!this.isInstagramById(this.networkId)) {
                        this.onConnect();
                    } else {
                        this.startInstagramConnection();
                    }
                }
            }
        },

        setup(props) {
            const { networkId } = toRefs(props);
            const { isInstagramById, isTiktokById, isPinterestById, isMetaById, isFacebookById, isValidFacebookInput } = useNetworks();

            const goBackAnalyticsEvent = () => {
                useEventTracking().trackEvent(NETWORK_CONNECTION_INFO_EVENTS_BY_ID[networkId.value]?.back);
            };

            const continueAnalyticsEvent = () => {
                useEventTracking().trackEvent(NETWORK_CONNECTION_INFO_EVENTS_BY_ID[networkId.value]?.continue);
            };

            const dontLinkCreatorAnalyticsEvent = () => {
                useEventTracking().trackEvent(NETWORK_CONNECTION_INFO_EVENTS_BY_ID[networkId.value]?.dontLink, {
                    link_text: "I don't want to connect my creator account"
                });
            };

            const useThirdParty = computed(() => {
                return isMetaById(networkId.value);
            });

            const getNetworkConnectionInfo = () => {
                return useThirdParty.value
                    ? THIRD_PARTY_NETWORK_CONNECTION_INFO_BY_ID[networkId.value]
                    : NETWORK_CONNECTION_INFO_BY_ID[networkId.value];
            };

            return {
                useThirdParty,
                isMetaById,
                isInstagramById,
                isTiktokById,
                isPinterestById,
                isFacebookById,
                isValidFacebookInput,
                goBackAnalyticsEvent,
                continueAnalyticsEvent,
                dontLinkCreatorAnalyticsEvent,
                getNetworkConnectionInfo
            };
        },

        /**
         * When the component is created
         */
        created() {
            if (this.$route && this.$route.query && this.$route.query.username) {
                this.payload.username = this.$route.query.username;
            }
        },

        watch: {
            payload: {
                deep: true,
                handler() {
                    this.debouncedFormatUsername();
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .connect-wrapper {
        text-align: center;

        .margin-bottom-normal {
            margin-bottom: $g-spacing-5;
        }

        .margin-top-2 {
            margin-top: $g-spacing-7;
        }
        .margin-bottom-3 {
            margin-bottom: $g-spacing-9;
        }
        .h1 {
            max-width: 320px;
            text-align: center;
            margin: 0 auto $g-spacing-6 auto;
        }
        .logo-style {
            width: 183px;
            margin-top: $g-spacing-7;
            margin-bottom: $g-spacing-7;
        }

        .text-wrapper {
            margin: 0 auto;
            width: 310px;
        }

        .connect-button {
            padding: 0 3rem;
        }

        ::v-deep .ant-alert {
            text-align: left;
        }
    }
</style>
