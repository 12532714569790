import { getMeta } from '../helpers';
import VueResource from 'vue-resource';
import { Message } from 'element-ui';

/**
 * Setup Vue Reource to include proper headers
 *
 * @param Vue
 */
export default function (Vue) {
    // use the Vue Resource library
    Vue.use(VueResource);

    // set csrf token and socket_id for every request
    Vue.http.headers.common['X-CSRF-TOKEN'] = getMeta('csrf-token');
    Vue.http.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    // fix lower case content type header
    Vue.http.interceptors.unshift((req, next) => {
        next((res) => {
            if (typeof res.headers['content-type'] != 'undefined') {
                res.headers['Content-Type'] = res.headers['content-type'];
            }
        });
    });

    // refresh page on auth timeout
    Vue.http.interceptors.push((req, next) => {
        next((res) => {
            if (res.status === 419) {
                // eslint-disable-next-line @babel/new-cap
                Message({
                    message: 'Your session has timed out, refreshing...',
                    duration: 2000,
                    type: 'error'
                });
                window.setTimeout(() => window.location.reload(), 2000);
            }
        });
    });
}
