export default {

    /**
     * The mixin properties
     *
     * @type {object}
     */
    props: {

        /**
         * The campaign
         */
        campaign: {
            type: Object,
            required: true
        },

        /**
         * The proposal
         */
        proposal: {
            type: Object,
            required: false,
            default() {
                return null
            }
        }
    },

    /**
     * The mixin methods
     *
     * @type {object}
     */
    methods: {

        /**
         * Whether or not the campaign has the given compensation type
         *
         * @param {string} type
         * @returns {boolean}
         */
        hasCompensation(type) {
            return this.campaign.compensation && this.campaign.compensation.indexOf(type) >= 0;
        },
    }
}
