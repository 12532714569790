<template>
    <textarea
            class="input"
            v-model="proposal_model.notes"
            style="min-height: 100px;"
            :placeholder="`Tell us anything we should know`"></textarea>
</template>
<script>
    import CampaignProposalMixin from '../CampaignProposalMixin';
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'ProposalNotes',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            CampaignProposalMixin
        ],

        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {

            /**
             * The proposal
             *
             * @returns {object}
             */
            proposal_model() {
                return this.$parent.proposal_model;
            }
        }
    }
</script>