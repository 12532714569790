import { getCurrentInstance } from 'vue-demi';

/**
 * @returns {Object} router - this.$router - can be used inside setup()
 */
const useRouter = () => {
    const internalInstance = getCurrentInstance();
    const proto = internalInstance?.proxy ? internalInstance?.proxy : internalInstance;

    const get = () => {
        return proto?.$router;
    };

    return {
        get
    };
};

export default useRouter;
