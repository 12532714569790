<template>
    <field :label="field.display_label" :pop_text="field.description" :key="field.id" :required="required" v-if="field">
        <component :is="`${field.component}Input`"
                   :field="field"
                   ref="field" />
    </field>
</template>
<script>

    import CrmFieldInputMixin from './mixins/CrmFieldInputsMixin';
    import PhoneInput from "../../interface/form/PhoneInput.vue";
    import AddressInput from "../../interface/form/AddressInput.vue";

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'CustomField',

        /**
         * Components
         */

        components: {
            PhoneInput,
            AddressInput,
        },

        /**
         * Mixins
         *
         */
        mixins: [
            CrmFieldInputMixin
        ],

        /**
         * The component properties
         *
         * @type {Object}
         */
        props: {
            field: {
                type: Object
            },

            required: {
                type: Boolean,
                default: false
            },
        },
    }
</script>
