<template>
    <div class="blank-state" :class="classes">
        <i v-if="icon" class="far" :class="`fa-${icon}`" />
        <div class="blank-state-title">
            <slot name="title" />
        </div>
        <div class="blank-state-content">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'BlankState',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {
            /**
             * The icon class
             */
            icon: {
                type: String,
                default: null
            },

            /**
             * The size of the blank state
             */
            size: {
                type: String,
                default: 'large'
            },

            /**
             * Whether or not the blank state is inside a parent element
             */
            inner: {
                type: Boolean,
                default: false
            }
        },

        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {
            /**
             * The blank state classes
             *
             * @returns {Record<string, boolean>}
             */
            classes() {
                return {
                    [`blank-state-${this.size}`]: true,
                    'blank-state-inner': this.inner
                };
            }
        }
    };
</script>
