<template>
    <!-- Error message -->
    <div class="widget-container">
        <div v-if="errorMessage" class="mb-15">
            <div class="notification is-danger">
                <button class="delete" @click="errorMessage = null"></button>
                {{ errorMessage }}
            </div>
        </div>

        <!-- Not accepting submissions alert -->
        <div v-if="state === 'not-accepting'">
            <not-accepting />
        </div>

        <div v-if="isAndroidWebview || isIosWebview">
            <in-app-browser :is-android-webview="isAndroidWebview" :is-ios-webview="isIosWebview" />
        </div>

        <!-- Find contact alert -->
        <div v-else-if="state === 'find-contact'">
            <find-contact :campaign_id="page.campaign_id" :page_id="page.id" />
        </div>

        <!-- Contact found message -->
        <div v-else-if="state === 'contact-found'">
            <contact-found
                :contact-found="contact_found"
                :submission-waiting="submission_waiting"
                :contact-is-user="contact_is_user"
                :contact-record-type="contact_record_type"
            />
        </div>

        <!-- Contact Application -->
        <div v-else-if="state === 'application'">
            <contact-application :email="email" />
        </div>

        <!-- Attach Networks -->
        <div v-else-if="state === 'networks'">
            <Networks />
        </div>

        <!-- if they have completed their application -->
        <div v-else-if="state === 'application-complete'">
            <div class="notification">
                <template v-if="page.submitted_message">
                    <span v-html="$sanitize(page.submitted_message)" />
                </template>
                <template v-else>
                    <strong>Thank you!</strong>
                    Your application has been submitted for review.
                </template>
                <span v-if="page.support_email">
                    If you need help or have any questions you can email us at
                    <a :href="`mailto:${page.support_email}?subject=Influencer%20Program%20Application`">{{
                        page.support_email
                    }}</a>
                </span>
            </div>
            <div v-if="hasProgram && contact.live_url" class="has-text-centered">
                <a :href="`/${$root.contact.live_url}`" target="_blank">View Your Influencer Dashboard</a>
            </div>
        </div>

        <proposal-form
            v-else-if="state === 'proposal'"
            :campaign="campaign"
            @proposal-complete="handleCompletedCampaign"
            @notify-error="handleNotifyError"
            @request-error="handleRequestError"
            @clear-error="handleClearError"
        />
    </div>
    <!-- Proposal form -->
</template>

<script>
    import FindContact from './join/FindContact.vue';
    import NotAccepting from './join/NotAccepting.vue';
    import InAppBrowser from './join/InAppBrowser.vue';
    import ProposalForm from './campaign/proposal/ProposalForm';
    import ContactApplication from './join/ContactApplication';
    import ContactFound from './join/ContactFound';
    import Networks from './join/Networks';
    import _ from 'lodash';

    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'Join',

        provide() {
            return {
                context: 'landing'
            };
        },

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            FindContact,
            NotAccepting,
            InAppBrowser,
            ProposalForm,
            ContactApplication,
            ContactFound,
            Networks
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The page data
             *
             * @returns {Object}
             */
            page() {
                return this.$root.page;
            },

            /**
             * The contact data
             *
             * @returns {Object}
             */
            contact() {
                return this.$root.contact;
            },

            /**
             * Whether the page has an associated program (campaign or activation)
             *
             * @returns {boolean}
             */
            hasProgram() {
                return !!this.page.campaign_id || !!this.page.activation_id;
            },

            /**
             * Whether or not the current account is accepting submissions
             *
             * @returns {boolean}
             */
            accepting_submissions() {
                return parseInt(this.page.accepting) === 1;
            },

            /**
             * tells if android app webview
             *
             * @returns {Object}
             */
            isAndroidWebview() {
                return /; wv/.test(navigator.userAgent);
            },

            /**
             * Tells if ios app webview
             *
             * @returns {Object}
             */
            isIosWebview() {
                return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
            }
        },

        /**
         *
         * Data
         *
         * @types {function}
         */
        data() {
            return {
                errorMessage: null,
                campaign: false,
                email: null,
                submission_waiting: null,
                contact_found: null,
                state: 'find-contact',
                contact_is_user: false,
                contact_record_type: undefined
            };
        },

        /**
         * The component methods
         */
        methods: {
            /**
             * Handle successful campaign submission.
             *
             * @param campaign
             */
            handleCompletedCampaign(campaign) {
                this.state = 'application-complete';
                this.errorMessage = null;
            },
            handleNotifyError(message) {
                this.errorMessage = message;
            },
            handleRequestError() {
                this.errorMessage = 'There was an error on the server.';
            },
            handleClearError() {
                this.errorMessage = null;
            }
        },

        /**
         * Created
         * @type{Object}
         */
        created() {
            if (!this.accepting_submissions) {
                this.state = 'not-accepting';
            }

            this.$bus.$on('join.error', (message) => {
                this.errorMessage = this.contact_is_user || this.contact_found ? false : message;
            });

            this.$bus.$on('join.error.clear', () => {
                this.errorMessage = null;
            });

            // Display proposal form
            this.$bus.$on('join.contact.proposal', (campaign) => {
                const campaignUrl = `${window.location.origin}/${this.$root.contact.live_url}/v2/campaigns/${campaign.id}`;
                window.location.replace(campaignUrl);
            });

            // Display activation brief form
            this.$bus.$on('join.contact.activation', (activation_brief_id) => {
                const activationBriefUrl = `${window.location.origin}/${this.$root.contact.live_url}/v2/activation-brief/${activation_brief_id}`;
                window.location.replace(activationBriefUrl);
            });

            // Contact form is complete
            this.$bus.$on('join.contact.form.complete', () => {
                if (this.page.networks_enabled) {
                    this.state = 'networks';
                } else {
                    this.state = 'application-complete';
                }
            });

            // Full application is complete
            this.$bus.$on('join.contact.application.complete', () => {
                this.state = 'application-complete';
                const contact_id = _.get(this.contact, 'id', null);
                if (contact_id) {
                    this.$root.loaderStart('join.contact.submitting');
                    this.$http
                        .post('/complete', { contact_id })
                        .then(() => {
                            this.$bus.$emit('join.error.clear');
                        })
                        .catch(() => {
                            this.$bus.$emit(
                                'join.error',
                                `There was a problem. Please try again or contact us at ${this.$root.page.support_email}`
                            );
                        })
                        .then(() => {
                            this.$root.loaderStop('join.contact.submitting');
                        });
                }
            });

            // Full application is complete
            this.$bus.$on('join.reset-email', () => {
                this.state = 'find-contact';
            });

            this.$bus.$on('join.contact.find', (result) => {
                const { contact_found, submission_waiting, email, contact_is_user } = result;

                this.contact_found = contact_found;
                this.email = email;
                this.contact_is_user = contact_is_user;
                this.submission_waiting = !contact_is_user ? submission_waiting : false;
                this.contact_record_type = contact_found ? this.contact.record_type : null;

                if (contact_found || submission_waiting) {
                    if (!contact_is_user && !this.$root.userHasLinkedRequiredNetworks) {
                        this.state = 'networks';
                    } else {
                        this.state = 'contact-found';
                    }
                } else {
                    this.state = 'application';
                }
            });
        }
    };
</script>

<style scoped>
    .widget-container {
        padding: 1.5rem;
    }
</style>
