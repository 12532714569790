<template>
    <div id="app-start" class="p-15">
        <div class="notification is-dark">
            <strong>Thanks!</strong> It looks like we don't have you in our program yet.
            To submit an application just complete the form below. After that we will be in touch!
        </div>
        <contact-application-form :email="join.email"/>
    </div>
</template>
<script>
import ContactApplicationForm from './ContactApplicationForm';

    export default {
        /**
         * The components name
         */
        name: "ContactApplication",

        /**
         * Component Component's
         *
         */
        components: {
            ContactApplicationForm,
        },

        /**
         * Props
         *
         * @type {Object}
         */
        props: {
            email: {
                type: String,
            }
        },

        /**
         * Data
         *
         * @type {function}
         */
        data() {
            return {
                application_complete: false,
                application_step_1: false,
                join: {
                    email: this.email
                }
            }
        },

        /**
         * Computed
         *
         * @type {Object}
         */
        computed: {
            /**
             * The page data
             *
             * @returns {Object}
             */
            page() {
                return this.$root.page;
            },

            /**
             * The custom fields for the account
             *
             * @returns {array}
             */
            fields(){
                return this.$root.fields;
            },
        },
    }
</script>
