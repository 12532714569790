import { isEmpty } from 'lodash';
import { defineStore } from 'pinia';
import useHttp from '../src/composables/use-http';
import useContactStore from './contact';

const usePayoutAccountStore = defineStore('payout-account', {
    state: () => {
        return {
            data: null,
            loading: false,
            error: null
        };
    },
    actions: {
        async load(withExternal = true) {
            this.loading = true;
            const contact = useContactStore().data;
            const { http } = useHttp();

            let url = `/api/v1/contacts/${contact.id}/payout-account`;

            if (withExternal) {
                url += '?expand[]=external_account&expand[]=payout_card';
            }

            try {
                const response = await http.get(url);

                if (!isEmpty(response.data)) {
                    this.data = response.data;
                }
            } catch (e) {
                this.error = 'Sorry, there was an error loading the payout account information.';
            }

            this.loading = false;
        }
    }
});

export default usePayoutAccountStore;
