<template>
    <div class="notification mtb-5" v-if="campaign.discount_code">
        Your discount code: <strong>{{ campaign.discount_code.code }}</strong>
    </div>
</template>
<script>
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'DiscountCodeNotification',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The campaign
             */
            campaign: {
                type: Object,
                required: true
            },
        }
    }
</script>