<template>
    <el-dialog visible title="Choose Your Product" custom-class="product-modal" @close="handleClose">
        <div class="product-wizard">
            <nav v-if="breadcrumbs.length" class="breadcrumb">
                <ul>
                    <li :class="{ 'is-active': !breadcrumbs.length }">
                        <a @click="goTo()">Home</a>
                    </li>

                    <li
                        v-for="(breadcrumb, index) in breadcrumbs"
                        :class="{ 'is-active': index === breadcrumbs.length - 1 }"
                    >
                        <a @click="goTo(index)">
                            {{ breadcrumb.name }}
                        </a>
                    </li>
                </ul>
            </nav>

            <div class="modal-inner" style="overflow-y: auto">
                <loader v-if="$root.loading('compensation.products.wizard')" />

                <div v-else-if="categories.length" class="columns is-multiline is-mobile">
                    <div
                        v-for="category in categories"
                        :key="category.id"
                        class="column is-6"
                        @click="setCategory(category)"
                    >
                        <div class="card">
                            <div class="card-image">
                                <figure
                                    class="image is-square category-image"
                                    :style="`background-image: url(${category.image || placeholder_image})`"
                                ></figure>
                                <div class="image is-square overlay"></div>
                            </div>
                            <div class="card-content p-10">
                                <div class="content text-center">
                                    <div class="product-title">{{ category.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="products.length" class="columns is-multiline is-mobile">
                    <products-compensation-list-item
                        v-for="product in selected_product ? [selected_product] : products"
                        :key="product.id"
                        :product="product"
                        :show-prices="showPrices"
                        :currency="currency"
                        :allow-select="true"
                        :is-selected="selected_product && selected_product.id === product.id"
                        @select="(selected) => handleProductSelect(product, selected)"
                        @add="(options) => handleProductAdd(product, options)"
                    />

                    <div v-if="!selected_product && paging.total" class="text-center mtb-15" style="width: 100%">
                        <el-pagination
                            class="p-0"
                            layout="prev, pager, next"
                            :total="paging.total"
                            :page-size="paging.per_page"
                            :current-page="paging.page"
                            @current-change="changePage"
                        />
                    </div>
                </div>

                <blank-state v-else inner>
                    <template #content> No products found </template>
                </blank-state>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import _ from 'lodash';
    import ProductsCompensationListItem from './ProductsCompensationListItem.vue';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'ProductsCompensationDialog',

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            ProductsCompensationListItem
        },

        /**
         * The component props
         *
         * @type {Object}
         */
        props: {
            /**
             * The campaign ID
             */
            campaignId: {
                type: String,
                required: true
            },

            /**
             * A deal's proposal ID
             */
            proposalId: {
                type: String,
                default: null
            },

            /**
             * The restriction to apply to the selection
             */
            restriction: {
                type: Object,
                default: null
            },

            /**
             * Whether or not to show product prices
             */
            showPrices: {
                type: Boolean,
                default: false
            },

            /**
             * Currency code
             */
            currency: {
                type: String,
                default: 'usd'
            }
        },

        /**
         * The component data
         *
         * @returns {Object}
         */
        data() {
            return {
                /**
                 * The placeholder image
                 *
                 * @type {String}
                 */
                placeholder_image: 'https://d1igyfcg6nq1cr.cloudfront.net/placeholder/placeholder-square.jpg',

                /**
                 * The selected product
                 *
                 * @type {Object}
                 */
                selected_product: null,

                /**
                 * The breadcrumbs stack
                 *
                 * @type {Array}
                 */
                breadcrumbs: [],

                /**
                 * The paging object
                 *
                 * @type {object}
                 */
                paging: {
                    page: 1,
                    total: 0,
                    per_page: 20
                },
                /**
                 * Current category
                 *
                 * @type {Object}
                 */
                category: null,

                /**
                 * Category list
                 *
                 * @type {Array}
                 */
                categories: [],

                /**
                 * Product list
                 *
                 * @type {Array}
                 */
                products: []
            };
        },

        /**
         * The component methods
         *
         * @type {Object}
         */
        methods: {
            /**
             * Load the items to select from
             */
            requestItems() {
                this.$root.loaderStart('compensation.products.wizard');

                let url = `/campaigns/${this.campaignId}/products/wizard`;

                if (this.proposalId) {
                    url += `/${this.proposalId}`;
                }

                this.$http
                    .post(url, {
                        page: this.paging.page,
                        per_page: this.paging.per_page,
                        restriction: this.restriction,
                        category: this.category ? this.category.id : null
                    })
                    .then((response) => {
                        this.setPaging(response.data.products);
                        this.categories = response.data.categories;
                        this.products = response.data.products.data || [];
                    })
                    .catch(() => this.$notifyError('Could not load products, please try again or contact support'))
                    .then(() => this.$root.loaderStop('compensation.products.wizard'));
            },

            /**
             * Change the page
             *
             * @param {number} page
             */
            changePage(page) {
                this.paging.page = page;

                this.requestItems();
            },

            /**
             * Set the current category
             *
             * @param {Object} category
             */
            setCategory(category) {
                this.breadcrumbs.push(category);

                this.category = category;

                this.requestItems();
            },

            /**
             * Set the paging
             *
             * @param {object} products
             */
            setPaging(products) {
                this.paging.total = products.total;
                this.paging.page = products.current_page;
            },

            /**
             * Handle a breadcrumb click
             *
             * @param {Number} index
             */
            goTo(index = null) {
                this.paging.total = 0;
                this.paging.page = 1;
                this.selected_product = null;

                if (null === index) {
                    this.category = null;
                    this.breadcrumbs = [];
                } else {
                    this.category = this.breadcrumbs[index];
                    this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
                }

                this.requestItems();
            },

            /**
             * Return a product's variant preselected by customer
             *
             * @param {Object} product
             * @returns {Object}
             */
            getPreselectedVariant(product) {
                if (product.variant_id) {
                    return _.find(product.variants, { id: product.variant_id });
                }

                return null;
            },

            /**
             * Return a product's options preselected by customer
             *
             * @param {Object} product
             * @returns {Array}
             */
            getPreselectedOptions(product) {
                const variant = this.getPreselectedVariant(product);

                if (!variant) {
                    return [];
                }

                return variant.option_values.map((option_value) => ({
                    id: option_value.option_id,
                    value_id: option_value.id
                }));
            },

            /**
             * Handle the product select event
             *
             * @param {Object} product
             * @param {boolean} selected
             */
            handleProductSelect(product, selected) {
                if (selected) {
                    this.selected_product = product;
                } else {
                    this.selected_product = null;
                }
            },

            /**
             * Handle the product add event
             *
             * @param {Object} product
             * @param {Array}  options
             */
            handleProductAdd(product, options) {
                if (!product.select_options) {
                    options = this.getPreselectedOptions(product);
                }
                this.$emit('add', product, options);
            },

            /**
             * Handle the close event
             */
            handleClose() {
                this.$emit('close');
            }
        },

        /**
         * When the component is first created
         */
        created() {
            this.requestItems();
        }
    };
</script>

<style lang="css">
    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0);
    }
    .category-image {
        background-size: cover;
        background-position: 50% 50%;
    }
    .product-title {
        font-weight: 500;
        line-height: 18px;
    }
    @media screen and (max-width: 600px) {
        font-weight: 500;
        line-height: 16px;
        font-size: 14px;
        min-height: 50px;
    }
</style>
