import _ from 'lodash';
export default {

    /**
     * The computed properties
     *
     * @type {object}
     */
    computed: {

        /**
         * The campaigns that are currently in progress
         *
         * @returns {array}
         */
        campaigns_in_progress() {
            return this.campaigns.filter(campaign => {
                return campaign.status === 'Accepting Proposals'
                    || this.campaign_ids_with_accepted_proposals.indexOf(campaign.id) >= 0;
            });
        },

        /**
         * An array of campaign ids the influencer has an accepted proposal for
         *
         * @returns {array}
         */
        campaign_ids_with_accepted_proposals() {
            return this.proposals.filter(proposal => {
                return proposal.accepted_at !== null;
            }).map(proposal => proposal.campaign_id);
        },

        /**
         * The jobs that are currently in progress
         *
         * @returns {array}
         */
        proposals_in_progress() {
            return this.proposals.filter(proposal => {
                return proposal.job_status === 'In Progress';
            });
        },

        /**
         * The proposals that are accepted but not completed
         *
         * @returns {array}
         */
        outstanding_proposals() {
            return this.proposals.filter(proposal => {
                return proposal.job_status === 'In Progress' && proposal.status === 'Accepted';
            })
        }
    },

    /**
     * The mixin methods
     *
     * @type {object}
     */
    methods: {

        /**
         * Show the campaign
         *
         * @param {string} campaign_id
         */
        showCampaign(campaign_id) {

            const campaign = this.getCampaign(campaign_id);
            const proposals = this.campaignProposals(campaign_id);

            if(!proposals.length) {
                return this.showCampaignApplication(campaign);
            }

            if(campaign.proposal_interval === 1) {
                //return this.showCampaignProposals(campaign_id);
                return this.$root.go('sponsorships');
            }

            const jobs = this.getJobs(proposals[0].id);

            if(jobs.length) {
                return this.$router.push({name: 'v2.campaign.proposal', params: {campaign_id: campaign.id, proposal_id: proposals[0].id}})
            }
        },

        /**
         * Show the campaign proposals
         *
         * @param {string} campaign_id
         */
        showCampaignProposals(campaign_id) {
            this.$root.go('proposals', { campaign_id });
        },

        /**
         * Show the campaign application
         *
         * @param {Object} campaign
         */
        showCampaignApplication(campaign) {
            if (campaign.hasOwnProperty('version') && campaign.version === 2) {
                this.$root.go('v2.campaign', { campaign_id: campaign.id });
            } else {
                this.$root.go('campaign', { campaign_id: campaign.id });
            }
        },

        /**
         * Show the campaign
         *
         * @param {string} proposal_id
         */
        showJob(proposal_id) {
            this.$root.go('job', { proposal_id });
        },

        /**
         * Show the deal
         *
         * @param {String} id
         */
        showDeal(id) {
            this.$root.go('deal', { id });
        },

        /**
         * Get the proposals for the given campaign
         *
         * @param {string} campaign_id
         */
        campaignProposals(campaign_id) {
            if(!this.$root.proposals) {
                return [];
            }
            return this.$root.proposals.filter(proposal => proposal.campaign_id === campaign_id);
        },

        /**
         * Get the campaign by id
         *
         * @param {number} campaign_id
         * @returns {object|null}
         */
        getCampaign(campaign_id) {
            return _.find(this.$root.campaigns, { id: campaign_id });
        },

        /**
         * Get proposal by id
         *
         * @param {number} proposal_id
         * @returns {object|null}
         */
        getProposal(proposal_id) {
            return _.find(this.$root.proposals, { id: proposal_id });
        },

        /**
         * Get the job by id
         *
         * @param {number} proposal_id
         * @returns {object|null}
         */
        getJobs(proposal_id) {
            return this.$root.jobs.filter(job => job.proposal_id == proposal_id);
        },

        /**
         * Get a deal by ID
         *
         * @param   {String} id
         * @returns {Object}
         */
        getDeal(id) {
            return _.find(this.$root.deals, { id });
        },

        /**
         * Get deal's jobs
         *
         * @param   {String} deal_id
         * @returns {Array}
         */
        getDealJobs(deal_id) {
            return this.$root.jobs.filter(job => job.campaign_id === deal_id);
        },

        /**
         * Whether or not the campaign is accepting proposals
         *
         * @param {string} campaign_id
         * @returns {boolean}
         */
        acceptingProposals(campaign_id) {
            const campaign = this.getCampaign(campaign_id);
            return campaign.tasks.filter(task => task.accepting_proposals).length > 0;
        },


    }
}
