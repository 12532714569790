<template>
    <div>
        <base-box :border="true">
            <template #content>
                <g-level>
                    <g-level-item style="overflow: visible">
                        <div class="contact-network-line">
                            <g-level>
                                <g-level-item style="margin-right: 0; overflow: visible">
                                    <network-icon
                                        :network="contactNetwork.network.slug"
                                        :show-connection-status="true"
                                        :is-connected="true"
                                    />
                                </g-level-item>

                                <g-level-item>
                                    <g-typography class="contact-network-line" tag="span" font-weight="bold">
                                        {{
                                            contactNetwork.network_name
                                                ? contactNetwork.network_name
                                                : contactNetwork.network_username
                                        }}
                                    </g-typography>

                                    <g-typography class="contact-network-line" tag="span">
                                        ({{ contactNetwork.network_username }})
                                    </g-typography>
                                </g-level-item>
                            </g-level>
                        </div>
                    </g-level-item>
                    <g-level-item slot="right" class="contact-network-status-level-item">
                        <template v-if="showDisconnectButton">
                            <g-button v-if="canDisconnect" theme="live" shape="round" @click="disconnect">
                                Disconnect
                            </g-button>
                            <template v-else>
                                <g-icon class="connected-icon" name="check-circle" type="solid" />
                                <g-typography color="secondary" value="Connected" />
                            </template>
                        </template>
                    </g-level-item>
                </g-level>
            </template>
        </base-box>
        <generic-confirmation-modal
            :visible="showDisconnectModal"
            confirm-button-text="Disconnect"
            @cancel="handleDisconnectCancel"
            @continue="handleDisconnect"
        >
            <template #title> Disconnect {{ contactNetwork.network.slug | capitalize }}? </template>

            If you disconnect your {{ contactNetwork.network.slug }} account, you may lose the ability to participate in
            partnerships.
        </generic-confirmation-modal>
    </div>
</template>

<script>
    import { INSTAGRAM_ID, CONNECTION_FAILURES } from '../../../../constants/networks';
    import { EVENT_NAME_NETWORKS_DISCONNECT_CLICK_EVENTS } from '../../../../../app-v2/constants/analytics-events';
    import GenericConfirmationModal from '../../proposals/partials/GenericConfirmationModal.vue';
    import NetworkIcon from '../../../../../src/components/interface/elements/NetworkIcon.vue';
    import useEventTracking from '../../../../../src/composables/use-event-tracking';
    import { useFeatureFlagsStore } from '../../../../../stores';

    export default {
        name: 'ContactNetworkItem',
        components: {
            GenericConfirmationModal,
            NetworkIcon
        },
        props: {
            contactNetwork: {
                type: Object
            },
            context: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showDisconnectModal: false,
                failed: null
            };
        },
        computed: {
            canDisconnect() {
                return this.context === 'profile';
            },
            isSoftConnection() {
                return this.contactNetwork.network_connection_type === 'soft';
            },
            isInstagram() {
                return this.contactNetwork.network_id === INSTAGRAM_ID;
            },
            showDisconnectButton() {
                if (this.useThirdParty) {
                    return true;
                } else {
                    return !(this.isInstagram && this.isSoftConnection);
                }
            },
            hasFailureReason() {
                return this.contactNetwork?.failure_reason;
            },
            failureReasonMessage() {
                return CONNECTION_FAILURES[this.contactNetwork?.failure_reason]?.message;
            },
            failureReasonTitle() {
                return CONNECTION_FAILURES[this.contactNetwork?.failure_reason]?.title;
            },
            failurePage() {
                return CONNECTION_FAILURES[this.contactNetwork?.failure_reason]?.page;
            },
            useThirdParty() {
                return true;
            }
        },
        methods: {
            disconnect() {
                this.disconnectAnalyticsEvent(this.contactNetwork.network.slug);
                this.showDisconnectModal = true;
            },

            handleDisconnectCancel() {
                this.showDisconnectModal = false;
            },

            handleDisconnect() {
                this.$emit('disconnect-network', this.contactNetwork);
                this.showDisconnectModal = false;
            }
        },
        filters: {
            capitalize(value) {
                if (!value) {
                    return '';
                }
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        },
        mounted() {
            if (
                this.$route?.query?.connectById === '' + INSTAGRAM_ID &&
                this.contactNetwork?.network_id === INSTAGRAM_ID
            ) {
                this.$emit('connect-instagram');
                this.$router.replace({ query: null });
            }
        },
        setup() {
            const events = useEventTracking();

            const disconnectAnalyticsEvent = (networkName) => {
                events.trackEvent(EVENT_NAME_NETWORKS_DISCONNECT_CLICK_EVENTS[networkName], {});
            };

            return {
                disconnectAnalyticsEvent
            };
        }
    };
</script>

<style lang="scss" scoped>
    .connected-icon {
        color: $g-color-green-5;
        margin-right: $g-spacing-2;
    }
    .contact-network-line span {
        margin-right: $g-spacing-2;
    }
    .contact-network-status-level-item {
        height: 32px;
        & h5 {
            padding-right: $g-spacing-2;
        }
    }
    .gi-tiktok {
        color: #000;
        width: 1.25rem;
        display: inline-block;
        text-align: center;
        margin-right: $g-spacing-3 !important;
    }
</style>
