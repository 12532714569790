import { NAME_NETWORKS, NAME_PRODUCTS, NAME_CONTENT_AGREEMENT, NAME_COMPENSATION, NAME_SHIPPING_INFO, NAME_PERSONAL_INFO, NAME_CAMPAIGN_ASSETS, NAME_TASKS, NAME_BRAND_ASSETS } from './proposal-steps';
import { INSTAGRAM_ACCOUNT_SLUG, INSTAGRAM_GRAPH_SLUG, INSTAGRAM_PAGE_SLUG, INSTAGRAM_PERMISSIONS_SLUG, INSTAGRAM_SLUG, PINTEREST_SLUG, FACEBOOK_SLUG, TIKTOK_SLUG, TWITCH_SLUG, TWITTER_SLUG, YOUTUBE_SLUG, INSTAGRAM_ID, TIKTOK_ID, PINTEREST_ID } from './networks';
// Navigation Events
export const EVENT_NAME_SETTINGS_AVATAR_CLICKED = 'settings_avatar_clicked';
// Frontend Events
export const EVENT_NAME_PROPOSAL_STARTED = 'proposal_started';
export const EVENT_NAME_PROPOSAL_CONTINUE = 'continue_clicked';
export const EVENT_NAME_PROPOSAL_SUBMITTED = 'proposal_submitted';
export const EVENT_NAME_COMPENSATION_STARTED = 'compensation_started';
export const EVENT_NAME_COMPENSATION_COMPLETED = 'compensation_completed';
export const EVENT_NAME_SELECT_PRODUCTS_STARTED = 'select_products_started';
export const EVENT_NAME_SELECT_PRODUCTS_COMPLETED = 'select_products_completed';
export const EVENT_NAME_PRODUCT_ADDED = 'product_added';
export const EVENT_NAME_CONNECT_NETWORKS_STARTED = 'networks_started';
export const EVENT_NAME_CONNECT_NETWORKS_COMPLETED = 'networks_completed';
export const EVENT_NAME_TASKS_STARTED = 'tasks_started';
export const EVENT_NAME_TASKS_COMPLETED = 'tasks_completed';
export const EVENT_NAME_OPTIONAL_TASK_ADDED = 'optional_task_added';
export const EVENT_NAME_PERSONAL_INFO_STARTED = 'personal_info_started';
export const EVENT_NAME_PERSONAL_INFO_COMPLETED = 'personal_info_completed';
export const EVENT_NAME_SHIPPING_STARTED = 'shipping_started';
export const EVENT_NAME_SHIPPING_COMPLETED = 'shipping_completed';
export const EVENT_NAME_BRAND_ASSETS_STARTED = 'brand_assets_started';
export const EVENT_NAME_BRAND_ASSETS_COMPLETED = 'brand_assets_completed';
export const EVENT_NAME_CONTENT_RIGHTS_STARTED = 'content_rights_started';
export const EVENT_NAME_CONTENT_RIGHTS_COMPLETED = 'content_rights_completed';
export const EVENT_NAME_ACCOUNT_VERIFIED = 'account_verified';
export const EVENT_NAME_ACCOUNT_VERIFICATION_STARTED = 'account_verification_started';
export const EVENT_NAME_PAYOUTS_TAX_SETUP_PROMPT_VIEWED = 'tax_setup_prompt_viewed';
export const EVENT_NAME_PAYOUTS_TAX_SETUP_PROMPT_CLICKED = 'tax_setup_prompt_clicked';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_CARD_TRACKED = 'grinpay_card_tracked';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_CARD_ACTIVATION_STARTED = 'grinpay_card_activation_started';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_CARD_ACTIVATION_COMPLETED = 'grinpay_card_activation_completed';
export const EVENT_NAME_NETWORK_CONNECTED = 'network_connected';
export const EVENT_NAME_PAYOUTS_COUNTRY_SELECTOR_VIEWED = 'country_selector_viewed';
export const EVENT_NAME_PAYOUTS_COUNTRY_SELECTOR_SELECTED = 'country_selector_country_selected';
export const EVENT_NAME_PAYOUTS_COUNTRY_SELECTOR_SUBMITTED = 'country_selector_submitted';
export const EVENT_NAME_PAYOUTS_COUNTRY_SELECTOR_CLOSED = 'country_selector_closed';
export const EVENT_NAME_PAYOUTS_STRIPE_KYC_VIEWED = 'stripe_kyc_viewed';
export const EVENT_NAME_PAYOUTS_STRIPE_KYC_FAILED = 'stripe_kyc_failed';
export const EVENT_NAME_PAYOUTS_ADD_METHOD_VIEWED = 'payout_method_selection_viewed';
export const EVENT_NAME_PAYOUTS_ADD_METHOD_CLOSED = 'payout_method_selection_closed';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_SELECTED = 'grinpay_card_selected';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_BENEFITS_VIEWED = 'grinpay_benefits_viewed';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_BENEFITS_CLOSED = 'grinpay_benefits_closed';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_BENEFITS_CONTINUED = 'grinpay_benefits_continued';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_BENEFITS_BACK = 'grinpay_benefits_goback';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_ADDRESS_VIEWED = 'grinpay_address_viewed';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_ADDRESS_CLOSED = 'grinpay_address_closed';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_ADDRESS_SUBMITTED = 'grinpay_address_submitted';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_ADDRESS_BACK = 'grinpay_address_goback';
export const EVENT_NAME_PAYOUTS_GRIN_PAY_CARD_SENT = 'grinpay_card_sent';
export const EVENT_NAME_PAYOUTS_DEBIT_CARD_SELECTED = 'debit_card_selected';
export const EVENT_NAME_PAYOUTS_DEBIT_CARD_CLOSED = 'debit_card_closed';
export const EVENT_NAME_PAYOUTS_DEBIT_CARD_SUBMITTED = 'debit_card_submitted';
export const EVENT_NAME_PAYOUTS_DEBIT_CARD_BACK = 'debit_card_goback';
export const EVENT_NAME_PAYOUTS_DEBIT_CARD_ADDED = 'debit_card_added';
export const EVENT_NAME_PAYOUTS_MANUAL_ACH_SELECTED = 'manual_ach_selected';
export const EVENT_NAME_PAYOUTS_MANUAL_ACH_CLOSED = 'manual_ach_closed';
export const EVENT_NAME_PAYOUTS_MANUAL_ACH_SUBMITTED = 'manual_ach_submitted';
export const EVENT_NAME_PAYOUTS_MANUAL_ACH_BACK = 'manual_ach_goback';
export const EVENT_NAME_PAYOUTS_MANUAL_ACH_ADDED = 'manual_ach_added';
export const EVENT_NAME_INSTAGRAM_CONTINUE_SUBMITTED = 'instagram_continue_click';
export const EVENT_NAME_INSTAGRAM_BACK_SUBMITTED = 'instagram_back_click';
export const EVENT_NAME_FACEBOOK_TRY_AGAIN_SUBMITTED = 'try_again_click';
export const EVENT_NAME_FACEBOOK_FOLLOW_STEPS_SUBMITTED = 'follow_steps_click';
export const EVENT_NAME_INSTAGRAM_DONT_LINK_CREATOR_CLOSED = 'dont_link_creator_account';
export const EVENT_NAME_NETWORKS_INSTAGRAM_DISCONNECT_CLICK = 'instagram_disconnect';
export const EVENT_NAME_NETWORKS_FACEBOOK_DISCONNECT_CLICK = 'facebook_disconnect';
export const EVENT_NAME_NETWORKS_TIKTOK_DISCONNECT_CLICK = 'tiktok_disconnect';
export const EVENT_NAME_NETWORKS_TWITTER_DISCONNECT_CLICK = 'twitter_disconnect';
export const EVENT_NAME_NETWORKS_YOUTUBE_DISCONNECT_CLICK = 'youtube_disconnect';
export const EVENT_NAME_NETWORKS_PINTEREST_DISCONNECT_CLICK = 'pinterest_disconnect';
export const EVENT_NAME_NETWORKS_TWITCH_DISCONNECT_CLICK = 'twitch_disconnect';
export const EVENT_NAME_GENERAL_NAVIGATION_CLICK = 'navigation';
export const EVENT_NAME_GENERAL_APP_CLOSE = 'app_close';
export const EVENT_NAME_GENERAL_LINK_CLICK = 'link_click';
export const EVENT_NAME_INITIAL_CONNECT_YOUTUBE = 'youtube_initial_connect';
export const EVENT_NAME_INITIAL_CONNECT_TWITTER = 'twitter_initial_connect';
export const EVENT_NAME_INITIAL_CONNECT_INSTAGRAM = 'instagram_initial_connect';
export const EVENT_NAME_INITIAL_CONNECT_FACEBOOK = 'facebook_initial_connect';
export const EVENT_NAME_INITIAL_CONNECT_TWITCH = 'twitch_initial_connect';
export const EVENT_NAME_INITIAL_CONNECT_TIKTOK = 'tiktok_initial_connect';
export const EVENT_NAME_INITIAL_CONNECT_PINTEREST = 'pinterest_initial_connect';
export const EVENT_NAME_ONBOARDING_POLL_SUBMITTED = 'trolley_survey_completed';
export const EVENT_NAME_PAYOUT_DETAILS_VIEWED = 'payout_details_viewed';
export const EVENT_NAME_CONVERSION_DETAILS_VIEWED = 'conversion_details_viewed';
export const EVENT_NAME_DATE_FILTER_APPLIED = 'date_filter_applied';
export const EVENT_NAME_PAYOUT_DETAILS_EXPORTED = 'payouts_conversions_table_exported';
export const EVENT_NAME_ADDRESS_VALIDATED = 'address_validated';
export const EVENT_NAME_INSIGHTS_NAVIGATION_CLICKED = 'insights_navigation_clicked';
export const EVENT_NAME_CONVERSION_CHART_ADJUSTED = 'conversion_chart_adjusted';
export const EVENT_NAME_META_CONNECT_DRAWER_CONTINUE = 'network_connect_drawer_continue_click';
export const EVENT_NAME_META_CONNECT_DRAWER_HELP_LINK = 'network_connect_drawer_help_link_click';
export const EVENT_NAME_CONNECT_REMINDER_LEARN_MORE_CLICKED = 'connect_reminder_learn_more_clicked';
export const EVENT_NAME_CONNECT_REMINDER_CONNECT_CLICKED = 'connect_reminder_connect_clicked';
export const EVENT_NAME_TIKTOK_BACK_CLICKED = 'tiktok_back_click';
export const EVENT_NAME_TIKTOK_CONTINUE_CLICKED = 'tiktok_continue_click';
export const EVENT_NAME_PINTEREST_BACK_CLICKED = 'pinterest_back_click';
export const EVENT_NAME_PINTEREST_CONTINUE_CLICKED = 'pinterest_continue_click';
export const EVENT_NAME_MANUAL_DELIVERY_UPLOAD_CONTENT_CLICKED = 'manual_program_delivery_upload_content_clicked';
export const EVENT_NAME_MANUAL_DELIVERY_UPLOAD_TOO_LARGE = 'manual_program_delivery_upload_too_large';
export const EVENT_NAME_MANUAL_DELIVERY_DELIVER_CLICKED = 'manual_program_delivery_deliver_clicked';
export const EVENT_NAME_LIVE_SITE_SWITCHER_STARTED = 'live_site_switcher_started';
export const EVENT_NAME_LIVE_SITE_SWITCHER_COMPLETED = 'live_site_switcher_completed';
export const EVENT_NAME_CONNECTION_SHARED = '_connection_shared';
export const EVENT_NAME_SHARED_CONNECTION_SKIPPED = '_shared_connection_skipped';
export const EVENT_NAME_DASHBOARD_TASKS_SUMMARY_BANNER_CLICKED = 'dashboard_tasks_summary_banner_clicked';
export const EVENT_NAME_TASKS_SUMMARY_REVIEW_REQUIREMENTS_CLICKED = 'tasks_summary_review_requirements_clicked';
export const EVENT_NAME_TASKS_SUMMARY_DELIVER_CLICKED = 'tasks_summary_deliver_clicked';
export const EVENT_VIEW_AFFILIATE_LINKS = 'view_links_livesite';
export const EVENT_CREATE_AFFILIATE_LINK = 'create_link_livesite';
export const EVENT_COPY_AFFILIATE_LINK = 'copy_link_livesite';
export const EVENT_NAME_DEEP_LINK_VALIDATION_ERROR = 'deep_link_validation_error_livesite';
// Interaction Events
export const LIVE_CAMPAIGN_OVERVIEW_START_EVENT = 'Live - Campaign - Overview - Get Started';
export const LIVE_CAMPAIGN_OVERVIEW_CONTINUE_EVENT = 'Live - Campaign - Overview - Continue';
export const LIVE_CAMPAIGN_PROPOSAL_SUBMIT_SUCCESS_EVENT = 'Live - Campaign - Proposal - Submit Success';
export const LIVE_CAMPAIGN_PROPOSAL_SUBMIT_ERROR_EVENT = 'Live - Campaign - Proposal - Submit Error';
export const LIVE_CAMPAIGN_PROPOSAL_OPTIONAL_TASK_ADDED_EVENT = 'Live - Campaign - Proposal - Optional Task Added';
export const LIVE_CAMPAIGN_PROPOSAL_PRODUCT_SELECTION_START_EVENT = 'Live - Campaign - Proposal - Product Selection Started';
export const LIVE_CAMPAIGN_PROPOSAL_VIEW_PRODUCT_DETAILS_EVENT = 'Live - Campaign - Proposal - View Product Details';
export const LIVE_CAMPAIGN_PROPOSAL_ADD_PRODUCT_EVENT = 'Live - Campaign - Proposal - Add Product';
// Content approval events
export const EVENT_NAME_CONTENT_APPROVAL_EDIT_UPLOAD_CLICK = 'content_revision_started';
export const EVENT_NAME_CONTENT_APPROVAL_VIEW_DELIVERABLES_LIST = 'view_deliverables_list_clicked';
export const EVENT_NAME_CONTENT_APPROVAL_VIEW_DELIVERABLE = 'view_deliverable_clicked';
export const EVENT_NAME_CONTENT_APPROVAL_APPROVAL_HISTORY = 'approval_history_clicked_by_creator';
export const EVENT_NAME_CONTENT_APPROVAL_COMPLETE_DELIVERABLE = 'complete_deliverable_clicked';
// Network Events
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_FACEBOOK_CONNECT_SUCCESS = 'Live - Campaign - Proposal - Networks - Facebook - Connect Success';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_FACEBOOK_CONNECT_ERROR = 'Live - Campaign - Proposal - Networks - Facebook - Connect Error';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITCH_CONNECT_SUCCESS = 'Live - Campaign - Proposal - Networks - Twitch - Connect Success';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITCH_CONNECT_ERROR = 'Live - Campaign - Proposal - Networks - Twitch - Connect Error';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITTER_CONNECT_SUCCESS = 'Live - Campaign - Proposal - Networks - Twitter - Connect Success';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITTER_CONNECT_ERROR = 'Live - Campaign - Proposal - Networks - Twitter - Connect Error';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_SUCCESS = 'Live - Campaign - Proposal - Networks - Instagram - Connect Success';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_ERROR = 'Live - Campaign - Proposal - Networks - Instagram - Connect Error';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_YOUTUBE_CONNECT_SUCCESS = 'Live - Campaign - Proposal - Networks - Youtube - Connect Success';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_YOUTUBE_CONNECT_ERROR = 'Live - Campaign - Proposal - Networks - Youtube - Connect Error';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TIKTOK_CONNECT_SUCCESS = 'Live - Campaign - Proposal - Networks - Tik Tok - Connect Success';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TIKTOK_CONNECT_ERROR = 'Live - Campaign - Proposal - Networks - Tik Tok - Connect Error';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_PINTEREST_CONNECT_SUCCESS = 'Live - Campaign - Proposal - Networks - Pinterest - Connect Success';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_PINTEREST_CONNECT_ERROR = 'Live - Campaign - Proposal - Networks - Pinterest - Connect Error';
// Step Events
export const LIVE_CAMPAIGN_PROPOSAL_COMPENSATION_STEP_START_EVENT = 'Live - Campaign - Proposal - Compensation Step - Start';
export const LIVE_CAMPAIGN_PROPOSAL_COMPENSATION_STEP_COMPLETE_EVENT = 'Live - Campaign - Proposal - Compensation Step - Complete';
export const LIVE_CAMPAIGN_PROPOSAL_PRODUCTS_STEP_START_EVENT = 'Live - Campaign - Proposal - Products Step - Start';
export const LIVE_CAMPAIGN_PROPOSAL_PRODUCTS_STEP_COMPLETE_EVENT = 'Live - Campaign - Proposal - Products Step - Complete';
export const LIVE_CAMPAIGN_PROPOSAL_TASKS_STEP_START_EVENT = 'Live - Campaign - Proposal - Tasks Step - Start';
export const LIVE_CAMPAIGN_PROPOSAL_TASKS_STEP_COMPLETE_EVENT = 'Live - Campaign - Proposal - Tasks Step - Complete';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_STEP_START_EVENT = 'Live - Campaign - Proposal - Networks Step - Start';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORKS_STEP_COMPLETE_EVENT = 'Live - Campaign - Proposal - Networks Step - Complete';
export const LIVE_CAMPAIGN_PROPOSAL_SHIPPING_STEP_START_EVENT = 'Live - Campaign - Proposal - Shipping Step - Start';
export const LIVE_CAMPAIGN_PROPOSAL_SHIPPING_STEP_COMPLETE_EVENT = 'Live - Campaign - Proposal - Shipping Step - Complete';
export const LIVE_CAMPAIGN_PROPOSAL_PERSONAL_INFO_STEP_START_EVENT = 'Live - Campaign - Proposal - Personal Info Step - Start';
export const LIVE_CAMPAIGN_PROPOSAL_PERSONAL_INFO_STEP_COMPLETE_EVENT = 'Live - Campaign - Proposal - Personal Info Step - Complete';
export const LIVE_CAMPAIGN_PROPOSAL_ASSETS_STEP_START_EVENT = 'Live - Campaign - Proposal - Assets Step - Start';
export const LIVE_CAMPAIGN_PROPOSAL_ASSETS_STEP_COMPLETE_EVENT = 'Live - Campaign - Proposal - Assets Step - Complete';
export const LIVE_CAMPAIGN_PROPOSAL_AGREEMENTS_STEP_START_EVENT = 'Live - Campaign - Proposal - Agreements Step - Start';
export const LIVE_CAMPAIGN_PROPOSAL_AGREEMENTS_STEP_COMPLETE_EVENT = 'Live - Campaign - Proposal - Agreements Step - Complete';
export const LIVE_CAMPAIGN_ALL_EVENTS = [
    //Interaction events
    LIVE_CAMPAIGN_OVERVIEW_START_EVENT,
    LIVE_CAMPAIGN_OVERVIEW_CONTINUE_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_SUBMIT_SUCCESS_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_SUBMIT_ERROR_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_OPTIONAL_TASK_ADDED_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_PRODUCT_SELECTION_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_VIEW_PRODUCT_DETAILS_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_ADD_PRODUCT_EVENT,
    // Connection events
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_FACEBOOK_CONNECT_SUCCESS,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_FACEBOOK_CONNECT_ERROR,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITCH_CONNECT_SUCCESS,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITCH_CONNECT_ERROR,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITTER_CONNECT_SUCCESS,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITTER_CONNECT_ERROR,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_SUCCESS,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_ERROR,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_YOUTUBE_CONNECT_SUCCESS,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_YOUTUBE_CONNECT_ERROR,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TIKTOK_CONNECT_SUCCESS,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TIKTOK_CONNECT_ERROR,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_PINTEREST_CONNECT_SUCCESS,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_PINTEREST_CONNECT_ERROR,
    // Step events
    LIVE_CAMPAIGN_PROPOSAL_COMPENSATION_STEP_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_COMPENSATION_STEP_COMPLETE_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_PRODUCTS_STEP_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_PRODUCTS_STEP_COMPLETE_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_TASKS_STEP_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_TASKS_STEP_COMPLETE_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_STEP_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_NETWORKS_STEP_COMPLETE_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_SHIPPING_STEP_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_SHIPPING_STEP_COMPLETE_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_PERSONAL_INFO_STEP_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_PERSONAL_INFO_STEP_COMPLETE_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_ASSETS_STEP_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_ASSETS_STEP_COMPLETE_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_AGREEMENTS_STEP_START_EVENT,
    LIVE_CAMPAIGN_PROPOSAL_AGREEMENTS_STEP_COMPLETE_EVENT
];
const STEP_START = 'start';
const STEP_COMPLETE = 'complete';
export const LIVE_CAMPAIGN_PROPOSAL_STEP_EVENTS = {
    [NAME_COMPENSATION]: {
        [STEP_START]: LIVE_CAMPAIGN_PROPOSAL_COMPENSATION_STEP_START_EVENT,
        [STEP_COMPLETE]: LIVE_CAMPAIGN_PROPOSAL_COMPENSATION_STEP_COMPLETE_EVENT
    },
    [NAME_PRODUCTS]: {
        [STEP_START]: LIVE_CAMPAIGN_PROPOSAL_PRODUCTS_STEP_START_EVENT,
        [STEP_COMPLETE]: LIVE_CAMPAIGN_PROPOSAL_PRODUCTS_STEP_COMPLETE_EVENT
    },
    [NAME_TASKS]: {
        [STEP_START]: LIVE_CAMPAIGN_PROPOSAL_TASKS_STEP_START_EVENT,
        [STEP_COMPLETE]: LIVE_CAMPAIGN_PROPOSAL_TASKS_STEP_COMPLETE_EVENT
    },
    [NAME_NETWORKS]: {
        [STEP_START]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_STEP_START_EVENT,
        [STEP_COMPLETE]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_STEP_COMPLETE_EVENT
    },
    [NAME_SHIPPING_INFO]: {
        [STEP_START]: LIVE_CAMPAIGN_PROPOSAL_SHIPPING_STEP_START_EVENT,
        [STEP_COMPLETE]: LIVE_CAMPAIGN_PROPOSAL_SHIPPING_STEP_COMPLETE_EVENT
    },
    [NAME_PERSONAL_INFO]: {
        [STEP_START]: LIVE_CAMPAIGN_PROPOSAL_PERSONAL_INFO_STEP_START_EVENT,
        [STEP_COMPLETE]: LIVE_CAMPAIGN_PROPOSAL_PERSONAL_INFO_STEP_COMPLETE_EVENT
    },
    [NAME_CAMPAIGN_ASSETS]: {
        [STEP_START]: LIVE_CAMPAIGN_PROPOSAL_ASSETS_STEP_START_EVENT,
        [STEP_COMPLETE]: LIVE_CAMPAIGN_PROPOSAL_ASSETS_STEP_COMPLETE_EVENT
    },
    [NAME_CONTENT_AGREEMENT]: {
        [STEP_START]: LIVE_CAMPAIGN_PROPOSAL_AGREEMENTS_STEP_START_EVENT,
        [STEP_COMPLETE]: LIVE_CAMPAIGN_PROPOSAL_AGREEMENTS_STEP_COMPLETE_EVENT
    }
};
export const ANALYTICS_PROPOSAL_STEP_EVENTS = {
    [NAME_COMPENSATION]: {
        [STEP_START]: EVENT_NAME_COMPENSATION_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_COMPENSATION_COMPLETED
    },
    [NAME_PRODUCTS]: {
        [STEP_START]: EVENT_NAME_SELECT_PRODUCTS_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_SELECT_PRODUCTS_COMPLETED
    },
    [NAME_TASKS]: {
        [STEP_START]: EVENT_NAME_TASKS_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_TASKS_COMPLETED
    },
    [NAME_NETWORKS]: {
        [STEP_START]: EVENT_NAME_CONNECT_NETWORKS_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_CONNECT_NETWORKS_COMPLETED
    },
    [NAME_SHIPPING_INFO]: {
        [STEP_START]: EVENT_NAME_SHIPPING_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_SHIPPING_COMPLETED
    },
    [NAME_PERSONAL_INFO]: {
        [STEP_START]: EVENT_NAME_PERSONAL_INFO_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_PERSONAL_INFO_COMPLETED
    },
    [NAME_CAMPAIGN_ASSETS]: {
        [STEP_START]: EVENT_NAME_BRAND_ASSETS_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_BRAND_ASSETS_COMPLETED
    },
    [NAME_CONTENT_AGREEMENT]: {
        [STEP_START]: EVENT_NAME_CONTENT_RIGHTS_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_CONTENT_RIGHTS_COMPLETED
    },
    [NAME_BRAND_ASSETS]: {
        [STEP_START]: EVENT_NAME_BRAND_ASSETS_STARTED,
        [STEP_COMPLETE]: EVENT_NAME_BRAND_ASSETS_COMPLETED
    }
};
const CONNECT_SUCCESS = 'success';
const CONNECT_ERROR = 'error';
export const LIVE_CAMPAIGN_PROPOSAL_NETWORK_CONNECTION_EVENTS = {
    [FACEBOOK_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_FACEBOOK_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_FACEBOOK_CONNECT_ERROR
    },
    [INSTAGRAM_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_ERROR
    },
    [INSTAGRAM_GRAPH_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_ERROR
    },
    [INSTAGRAM_ACCOUNT_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_ERROR
    },
    [INSTAGRAM_PAGE_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_ERROR
    },
    [INSTAGRAM_PERMISSIONS_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_INSTAGRAM_CONNECT_ERROR
    },
    [YOUTUBE_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_YOUTUBE_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_YOUTUBE_CONNECT_ERROR
    },
    [TWITTER_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITTER_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITTER_CONNECT_ERROR
    },
    [TWITCH_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITCH_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TWITCH_CONNECT_ERROR
    },
    [TIKTOK_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TIKTOK_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_TIKTOK_CONNECT_ERROR
    },
    [PINTEREST_SLUG]: {
        [CONNECT_SUCCESS]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_PINTEREST_CONNECT_SUCCESS,
        [CONNECT_ERROR]: LIVE_CAMPAIGN_PROPOSAL_NETWORKS_PINTEREST_CONNECT_ERROR
    }
};
export const EVENT_NAME_NETWORKS_DISCONNECT_CLICK_EVENTS = {
    [FACEBOOK_SLUG]: EVENT_NAME_NETWORKS_FACEBOOK_DISCONNECT_CLICK,
    [INSTAGRAM_SLUG]: EVENT_NAME_NETWORKS_INSTAGRAM_DISCONNECT_CLICK,
    [YOUTUBE_SLUG]: EVENT_NAME_NETWORKS_YOUTUBE_DISCONNECT_CLICK,
    [TWITTER_SLUG]: EVENT_NAME_NETWORKS_TWITTER_DISCONNECT_CLICK,
    [TWITCH_SLUG]: EVENT_NAME_NETWORKS_TWITCH_DISCONNECT_CLICK,
    [TIKTOK_SLUG]: EVENT_NAME_NETWORKS_TIKTOK_DISCONNECT_CLICK,
    [PINTEREST_SLUG]: EVENT_NAME_NETWORKS_PINTEREST_DISCONNECT_CLICK
};
export const NETWORK_INITIAL_CONNECT_EVENTS = {
    [FACEBOOK_SLUG]: EVENT_NAME_INITIAL_CONNECT_FACEBOOK,
    [INSTAGRAM_SLUG]: EVENT_NAME_INITIAL_CONNECT_INSTAGRAM,
    [YOUTUBE_SLUG]: EVENT_NAME_INITIAL_CONNECT_YOUTUBE,
    [TWITTER_SLUG]: EVENT_NAME_INITIAL_CONNECT_TWITTER,
    [TWITCH_SLUG]: EVENT_NAME_INITIAL_CONNECT_TWITCH,
    [TIKTOK_SLUG]: EVENT_NAME_INITIAL_CONNECT_TIKTOK,
    [PINTEREST_SLUG]: EVENT_NAME_INITIAL_CONNECT_PINTEREST
};
export const NETWORK_CONNECTION_INFO_EVENTS_BY_ID = {
    [INSTAGRAM_ID]: {
        back: EVENT_NAME_INSTAGRAM_BACK_SUBMITTED,
        continue: EVENT_NAME_INSTAGRAM_CONTINUE_SUBMITTED,
        dontLink: EVENT_NAME_INSTAGRAM_DONT_LINK_CREATOR_CLOSED
    },
    [TIKTOK_ID]: {
        back: EVENT_NAME_TIKTOK_BACK_CLICKED,
        continue: EVENT_NAME_TIKTOK_CONTINUE_CLICKED
    },
    [PINTEREST_ID]: {
        back: EVENT_NAME_PINTEREST_BACK_CLICKED,
        continue: EVENT_NAME_PINTEREST_CONTINUE_CLICKED
    }
};
