<template>
    <div>
        <data-accept ref="terms">
            I accept and agree to the
        </data-accept>
        <div class="text-center">
            <div class="field has-addons">
                <p class="control is-expanded">
                    <input name="email" autocomplete="email" class="input is-medium" placeholder="Your email address" v-model="form.email">
                </p>
                <p class="control">
                    <button
                            class="button is-medium"
                            :class="{'is-loading': $root.loading('join.finding')}"
                            :style="{backgroundColor: $root.buttonBackgroundColor, color: $root.buttonFontColor}"
                            @click="findContact()">
                        Get Started
                    </button>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    import DataAccept from "../../interface/elements/DataAccept.vue";
    import ContactFound from "./ContactFound";
    import ContactApplication from './ContactApplication';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'FindContact',

        /**
         * The components child components
         * @type {Object}
         */
        components: {
            DataAccept,
            ContactFound,
            ContactApplication,
        },
        /**
         * The component properties
         *
         * @type {Array}
         */
        props: ['campaign_id', 'page_id'],

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {

                /**
                 * The form model
                 *
                 * @type {Object}
                 */
                form: {
                    email: null,
                    page_id: this.page_id,
                    campaign_id: this.campaign_id
                },
                contact_found: false,
                contact_record_type: undefined,
                submission_waiting: false,
                hasCampaign: false,
            };
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {

            /**
             * Find the contact by an email and emit an event
             * to the parent component
             */
            findContact() {
                if (!this.$refs.terms || !this.$refs.terms.hasAccepted()) {
                    this.$bus.$emit('join.error', 'The terms and conditions are not accepted.');
                    return;
                }

                this.$root.loaderStart('join.finding');

                this.$http.post('/find', this.form)
                    .then(res => {
                        this.$bus.$emit('join.error.clear');
                        this.$root.contact = res.data.contact;
                        // status 200

                        if (res.status === 201) {
                            this.submission_waiting = !res.data.contact.application_completed_at;
                        }

                        this.$bus.$emit('join.contact.find', {
                            contact_found: !!res.data.contact,
                            submission_waiting: this.submission_waiting,
                            email: this.form.email,
                        });
                    })
                    .catch((error) => {
                        this.$bus.$emit('join.error.clear');
                        if (error.status === 404) {
                            this.contact_found = false;

                            this.$bus.$emit('join.contact.find', {
                                contact_found: false,
                                submission_waiting: false,
                                email: this.form.email,
                            });
                            return;
                        }

                        if (error.status === 401) {
                            this.$root.contact = error.data.contact;

                            this.$bus.$emit('join.contact.find', {
                                contact: error.data.contact,
                                contact_found: !!error.data.contact,
                                submission_waiting: !error.data.contact.application_completed_at,
                                email: this.form.email,
                            });
                            return;
                        }

                        if (error.status === 406) {
                            if (error.data.contact) {
                                this.$root.contact = error.data.contact;
                                this.$root.networks = error.data.contact.networks;
                            } else {
                                this.$root.contact = error.data;
                                this.$root.networks = error.data.networks;
                            }

                            if (error.data.contact || error.data.contact_is_user) {
                                this.$bus.$emit('join.contact.find', {
                                    contact_found: true,
                                    submission_waiting: !error.data.contact.application_completed_at,
                                    email: this.form.email,
                                    contact_is_user: error.data.contact_is_user
                                });
                            } else if (this.$root.page.campaign_id) {
                                this.hasCampaign = true;
                                return this.$root.getCampaign(this.$root.contact.id, this.$root.page.campaign_id);
                            } else {
                                this.$bus.$emit('join.contact.form.complete');
                                return;
                            }
                        }

                        this.form.email = null;
                        this.$bus.$emit('join.error', 'Please enter a valid email');
                    })
                    .then(() => {
                        this.$root.loaderStop('join.finding');
                    });
            }
        },
    }
</script>

