import Echo from 'laravel-echo';

/**
 * Setup Pusher and Laravel Echo for broadcasting
 *
 * @returns {string|null}
 */
export default function () {
    window.Pusher = require('pusher-js');
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: '748d69d243367bd42a1d',
        cluster: 'mt1',
        encrypted: true
    });
}
