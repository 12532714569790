<template>
    <div>
        <div v-for="option in orderedOptions" :key="option.id">
            <el-radio v-model="model" :label="option.value">
                {{ option.value }}
            </el-radio>
        </div>
    </div>
</template>
<script>
    import FieldInputMixin from './FieldInputMixin';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'RadioSelectInput',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            FieldInputMixin
        ]
    }
</script>

