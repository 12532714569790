<template>
    <el-popover
            placement="top-start"
            title="Secure Social Network Authentication"
            width="375"
            trigger="click">
        <a :class="linkClasses" slot="reference">{{ linkText }}</a>
        <div class="networks-connect-info">
            <p>
                {{ network || 'Instagram' }} and the other social networks use an authorization pattern called 
                <a href="https://en.wikipedia.org/wiki/OAuth" target="_blank">OAuth</a>, which is very common and extremely secure.
                When connecting a social profile, we do not receive your password or any sensitive information at all.
                {{ network || 'The social network' }} gives us an access token once they verify your account.
            </p>
            <p>
                Hundreds of influencers are syncing their profiles via our platform every day, and
                we have taken great effort to make sure the process is secure and follows the best practices
                laid out by {{ network || 'the social network' }}.
            </p>
        </div>
    </el-popover>
</template>
<style lang="scss">
    .el-popover__title {
        font-weight: bold;
        font-size: 0.9em;
    }
    .networks-connect-info {
        font-size: 0.9em;
        p {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
<script>
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'SocialAuthInfoPopover',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The network being connected
             */
            network: {
                type:     String,
                required: false,
                default:  null
            },

            /**
             * The text of the link that triggers the popover
             */
            linkText: {
                type:     String,
                required: true
            },

            /**
             * The link classes
             */
            linkClasses: {
                type:     String,
                required: false,
                default: 'button is-link'
            }
        }
    }
</script>