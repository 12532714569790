<template>
    <div>
        <loader v-if="this.$root.loading('compensation.products.preselected')" />

        <div v-else>
            <products-compensation-list-item
                v-for="(product, index) in availableProducts"
                :key="`${product.id}-${index}`"
                :product="product"
                :show-prices="!!settings.show_prices"
                :currency="campaign.brand.currency"
                :allow-select="false"
                :is-selected="true"
                :read-only="true"
                @option-select="(id, value) => handleOptionSelect(product, id, value)"
            />
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ProductsCompensationListItem from './ProductsCompensationListItem.vue';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'ProductsCompensationPreselected',

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            ProductsCompensationListItem
        },

        /**
         * The component properties
         *
         * @type {Object}
         */
        props: {
            /**
             * The campaign
             */
            campaign: {
                type:     Object,
                required: true
            },

            /**
             * A deal's proposal
             */
            proposal: Object,

            /**
             * Selected products
             */
            products: Array
        },

        /**
         * The component data
         *
         * @returns {Object}
         */
        data() {
            return {
                /**
                 * The campaign products
                 *
                 * @type {Array}
                 */
                campaignProducts: [],

                /**
                 * The selected products
                 *
                 * @type {Array}
                 */
                selectedProducts: []
            };
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The compensation settings
             *
             * @returns {Object}
             */
            settings() {
                return (this.proposal ? this.proposal.compensation_settings : this.campaign);
            },

            /**
             * Products to choose from
             *
             * @returns {Array}
             */
            availableProducts() {
                return (this.products || this.campaignProducts);
            }
        },

        /**
         * The component methods
         *
         * @type {Object}
         */
        methods: {
            /**
             * Load the campaign products
             */
            requestProducts() {
                this.$root.loaderStart('compensation.products.preselected');

                let url = `/campaigns/${this.campaign.id}/products/preselected`;

                if (this.proposal) {
                    url += `/${this.proposal.id}`;
                }

                this.$http.get(url)
                    .then(res => this.receiveProducts(res))
                    .catch(() => this.$notifyError('Could not load products, please try again or contact support'))
                    .then(() => this.$root.loaderStop('compensation.products.preselected'))
                ;
            },

            /**
             * Handle products response
             *
             * @param {Object} response
             */
            receiveProducts(response) {
                this.campaignProducts = response.data;

                this.setSelectedProducts();
            },

            /**
             * Set the selected products' initial value
             */
            setSelectedProducts() {
                // TODO: why did I have to do this?
                this.campaignProducts = this.campaignProducts.map(product => ({
                    ...product,
                    selected_options: product.select_options ? product.options.map(option => ({...option, value_id: null })) : this.getPreselectedOptions(product)
                }));


                this.selectedProducts = this.availableProducts.map(product => ({
                    id:      product.id,
                    options: this.getPreselectedOptions(product)
                }));

                this.handleChange();
            },

            /**
             * Return preselected options for a product
             *
             * @param {Object} product
             * @returns {Array}
             */
            getPreselectedOptions(product) {
                if (!product.has_options || !product.variant_id) {
                    return [];
                }

                const variant = _.find(product.variants, { id: product.variant_id });

                return variant.option_values.map(option_value => ({
                    id:       option_value.option_id,
                    value_id: option_value.id
                }));
            },

            /**
             * Handle the product option select event
             */
            handleOptionSelect(product, optionId, optionValueId) {
                const productIndex = _.findIndex(this.selectedProducts, { id: product.id });
                const optionIndex  = _.findIndex(this.selectedProducts[productIndex].options, { id: optionId });

                if (optionIndex === -1) {
                    this.selectedProducts[productIndex].options.push({
                        id:       optionId,
                        value_id: optionValueId
                    });
                } else {
                    this.selectedProducts[productIndex].options[optionIndex].value_id = optionValueId;
                }

                this.handleChange();
            },

            /**
             * Handle the products change event
             */
            handleChange() {
                this.$emit('change', this.selectedProducts);
            }
        },

        /**
         * When the component first created
         */
        created() {
            if (this.products) {
                this.setSelectedProducts();
            } else {
                this.requestProducts();
            }
        }
    }
</script>