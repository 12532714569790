<template>
    <div v-if="needToCheck">
        <g-loader v-if="isLoading" type="block" />

        <div v-else-if="!payoutAccount" class="notification mtb-10">
            <strong>Important</strong>
            You have not set up a method to get paid.
            Once you join the sponsorship head to your
            <a @click.prevent="$root.go('edit.profile')">settings</a> and set one up.
        </div>
    </div>
</template>

<script>
    import PayoutAccountMixin from '../../../../mixins/PayoutAccountMixin';

    export default {
        name: 'PayoutAccountNotification',

        mixins: [
            PayoutAccountMixin,
        ],

        props: {
            campaign: {
                type:     Object,
                required: true,
            },
        },

        computed: {
            needToCheck() {
                return this.campaign.makes_affiliate_payments;
            },
        },

        mounted() {
            if (this.needToCheck) {
                this.loadPayoutAccount();
            }
        },
    };
</script>
