<template>
    <div>
        <span v-if="settings.product_text" v-html="$sanitize(productTextHtml)"></span>

        <p v-else-if="settings.select_products">
            Choose the product{{ settings.max_products === 1 ? '' : 's' }} you would like.

            <span v-if="settings.max_products > 1">
                You can select up to <strong>{{ settings.max_products }}</strong> products.
            </span>

            <span v-if="settings.show_prices && settings.max_product_price > 0">
                You can select up to
                <strong>
                    <money-display :value="settings.max_product_price" :currency="campaign.brand.currency" />
                </strong>
                worth of products.
            </span>
        </p>

        <div v-if="settings.use_checkout_url">
            Head to
            <a :href="campaign.brand.website_url" target="_blank">
                {{ campaign.brand.website_url }}
            </a>
            and add the product(s) you want to your cart.
            <span v-if="selectionMethodWebsiteShopifySharedCart">
                Then head to the cart page, click the Share Cart button to copy the URL, and paste it below. The url
                will look something like this:
                {{ campaign.brand.website_url }}/shared-cart/197659668480:1,196142759117:2
            </span>
            <span v-else>
                Then head to the first step of checkout, copy the url and paste it below. The url will look something
                like this: {{ campaign.brand.website_url }}/254781/checkouts/bc2b897c2
            </span>
        </div>

        <div class="mt-25" v-if="hasProductsAvailable">
            <div v-if="settings.select_products">
                <products-compensation-checkout
                    :campaign="campaign"
                    :proposal="proposal"
                    @change="handleChange"
                    v-if="settings.use_checkout_url"
                />

                <products-compensation-wizard
                    :campaign="campaign"
                    :proposal="proposal"
                    :products="products"
                    @change="handleChange"
                    v-else
                />
            </div>

            <div v-else>
                <products-compensation-preselected
                    :campaign="campaign"
                    :proposal="proposal"
                    :products="products"
                    @change="handleChange"
                />
            </div>
        </div>

        <div v-else-if="settings.select_products" class="notification">
            We are currently out of products for this campaign.
        </div>
    </div>
</template>

<script>
    import { linkify } from '../../../../../helpers';
    import { useFeatureFlagsStore } from '../../../../../../stores';
    import MoneyDisplay from '../../../../interface/elements/MoneyDisplay.vue';
    import ProductsCompensationWizard from './ProductsCompensationWizard.vue';
    import ProductsCompensationPreselected from './ProductsCompensationPreselected.vue';
    import ProductsCompensationCheckout from './ProductsCompensationCheckout.vue';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'ProductsCompensation',

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            MoneyDisplay,
            ProductsCompensationWizard,
            ProductsCompensationPreselected,
            ProductsCompensationCheckout
        },

        /**
         * The component props
         *
         * @type {Object}
         */
        props: {
            /**
             * The campaign
             */
            campaign: {
                type: Object,
                required: true
            },

            /**
             * A deal's proposal
             */
            proposal: Object,

            /**
             * Selected products
             */
            products: Array
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The compensation settings
             *
             * @returns {Object}
             */
            settings() {
                return this.proposal ? this.proposal.compensation_settings : this.campaign;
            },

            /**
             * The product text html
             *
             * @returns {string}
             */
            productTextHtml() {
                return linkify(this.settings.product_text);
            },

            /**
             * Whether there are products left to show
             *
             * @returns {Boolean}
             */
            hasProductsAvailable() {
                if (this.settings.use_checkout_url) {
                    return true;
                }

                return this.proposal ? this.proposal.has_configured_products : this.campaign.has_configured_products;
            },

            selectionMethodWebsiteShopifySharedCart() {
                const isEnabled = useFeatureFlagsStore().data['product-select-from-website-q2-2024'] || false;

                return this.settings.use_checkout_url && isEnabled;
            }
        },

        /**
         * The component methods
         *
         * @type {Object}
         */
        methods: {
            /**
             * Handle the "change" event
             *
             * @param products
             */
            handleChange(products) {
                this.$emit('change', products);
            }
        }
    };
</script>
