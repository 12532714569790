<template>
    <div>
        <div class="notification is-dark">
            <strong>
                This page is best viewed in a mobile browser. Copy the link below paste into a mobile web browser.
            </strong>
            <span v-if="isInstagramBrowser">
                Tap the button
                (<i v-if="isAndroidWebview" class="far fa-ellipsis-v"></i><i v-if="isIosWebview" class="far fa-ellipsis-h"></i>)
                at the top right to view your browser options.
            </span>
        </div>

        <div class="field has-addons g-mt-5">
            <p class="control is-expanded">
                <input
                    ref="urlInput"
                    readonly
                    class="input is-medium"
                    :value="pageUrl"
                    @focus="$event.target.select()"
                />
            </p>

            <p class="control">
                <button
                    class="button is-medium"
                    :style="{ backgroundColor: $root.buttonBackgroundColor, color: $root.buttonFontColor }"
                    @click="copyUrl"
                >
                    Copy
                </button>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppWebviewNotice",
        data() {
            return {
            }
        },
        props:    {
            isAndroidWebview: {
                type:     Boolean,
                required: true,
            },
            isIosWebview: {
                type:     Boolean,
                required: true,
            }
        },

        computed: {
            isInstagramBrowser() {
                return /(I|i)nstagram/.test(navigator.userAgent);
            },

            pageUrl() {
                return window.location.href;
            }
        },

        methods: {
            copyUrl() {
                this.$refs.urlInput.focus();
                document.execCommand('copy');
            }
        }
    }
</script>
