/**
 * NOTE: This mixin is deprecated. Please use the `useLocations`
 *       composable going forward.
 *
 * @deprecated
 */
export default {
    /**
     * The mixin data
     *
     * @returns {object}
     */
    data() {
        return {
            /**
             * The country list
             *
             * @type {array}
             */
            countries: [],

            /**
             * The state list
             *
             * @type {array}
             */
            states: [],

            /**
             * The city list
             *
             * @type {array}
             */
            cities: []
        };
    },

    /**
     * The mixin methods
     *
     * @type {object}
     */
    methods: {
        /**
         * Get the list of countries
         */
        getCountries(countryCodeList = null, labelField = 'name', valueField = 'code') {
            this.$http.get('/location/countries').then((res) => {
                this.countries = res.data.data;
                if (countryCodeList) {
                    this.countries = this.countries.filter((obj) => countryCodeList.includes(obj[valueField]));
                }
                // Allow for use as `select` (and `g-select`) options
                this.countries.forEach((country) => {
                    country.label = country[labelField];
                    country.value = country[valueField];
                });
            });
        },

        /**
         * Get the list of states by country
         *
         * @param {string} country_id
         */
        getStates(country_id) {
            this.$http.get(`/location/countries/${country_id}/states`).then((res) => {
                this.states = res.data.data;
            });
        },

        /**
         * Get the list of cities by state
         *
         * @param {string} state_id
         */
        getCities(state_id) {
            this.$http.get(`/location/states/${state_id}/cities`).then((res) => {
                this.cities = res.data.data;
            });
        },

        /**
         * Get the country by name
         *
         * @param  {string} type
         * @param  {string} name
         * @return {Promise}
         */
        getLocationByName(type, name) {
            return this.$http.post(`/location/name/${type}`, { name });
        }
    }
};
