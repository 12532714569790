<template>
    <div>
        <slot />

        <g-form>
            <g-row type="flex" :gutter="16">
                <g-column :xs="24" :md="12">
                    <g-form-item label="First Name" class="g-mb-3">
                        <g-input v-model="names.first_name" class="input" />
                    </g-form-item>
                </g-column>
                <g-column :xs="24" :md="12">
                    <g-form-item label="Last Name" class="g-mb-3">
                        <g-input v-model="names.last_name" class="input" />
                    </g-form-item>
                </g-column>
            </g-row>
            <address-input :address="address" ref="address" @changed="updateProfile" />
        </g-form>
        <div class="mt-20" v-if="inSettings">
            <g-button :disabled="loading" shape="round" theme="live" @click="updateProfile">
                <g-typography font-weight="semibold">Update</g-typography>
            </g-button>
        </div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { mapState } from 'pinia';
    import { countries } from "../../../../helpers";
    import AddressInput from '../../../interface/form/AddressInput.vue';
    import useContactStore from '../../../../../stores/contact';
    import { message } from '@grininc/grin-ui';
    import useEventTracking from '../../../../composables/use-event-tracking';
    import {
        EVENT_NAME_ADDRESS_VALIDATED
    } from '../../../../../app-v2/constants/analytics-events';

    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'AddressInfo',

        props: {

            /**
             * Whether the form is being displayed in settings
             */
            inSettings: {
                type:     Boolean,
                required: false,
                default:  true
            }
        },

        /**
         * The child components
         *
         * @type {object}
         */
        components: {
            AddressInput
        },

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            const contact = useContactStore().data;

            return {

                /**
                 *
                 */
                names: {
                    first_name: contact.first_name,
                    last_name:  contact.last_name,
                },

                /**
                 * The address model
                 */
                address: {
                    id:       _.get(contact, 'primary_address.id', null),
                    street:   _.get(contact, 'primary_address.street', null),
                    street_2: _.get(contact, 'primary_address.street_2', null),
                    city:     _.get(contact, 'primary_address.city', null),
                    state:    _.get(contact, 'primary_address.state', null),
                    zip:      _.get(contact, 'primary_address.zip', null),
                    country:  _.get(contact, 'primary_address.country', null),
                },

                /**
                 * The available countries
                 *
                 * @type {array}
                 */
                countries
            }
        },

        computed: {
            ...mapState(useContactStore, ['loading'])
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {
            /**
             * Get the child address payload
             *
             * @returns {object}
             */
            getPayload() {
                this.address = this.$refs.address.getPayload();
                return {...this.address, ...this.names};
            },

            /**
             * Update the contact information
             */
            async updateProfile() {
                const contactStore = useContactStore();

                await contactStore.updateAddress(this.getPayload());
                this.sendAddressAnalyticsPayload(this.$refs.address.getPayload().country);
                this.$bus.$emit('profile.address.updated');

                if (this.inSettings && !contactStore.error) {
                    message.success('Your profile has been updated');
                } else if (this.inSettings && contactStore.error) {
                    message.error(contactStore.error);
                }
            },
        },
        setup() {
            const events = useEventTracking();
            const contact = useContactStore().data;

            const sendAddressAnalyticsPayload = (address_country) => {
                events.trackEvent(EVENT_NAME_ADDRESS_VALIDATED, {
                    dl_contact_id: contact.id,
                    dl_address_country: address_country
                });
            }

            return {
                sendAddressAnalyticsPayload
            }
        }
    }
</script>
