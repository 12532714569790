<template>
    <div>
        <el-checkbox v-model="model" :true-label="1" :false-label="0">
            {{ model ? 'Yes' : 'No' }}
        </el-checkbox>
    </div>
</template>
<script>
    import FieldInputMixin from './FieldInputMixin';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'SingleCheckboxInput',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            FieldInputMixin
        ],

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {

                /**
                 * The default model
                 *
                 * @type {string|null}
                 */
                default_model: 0
            }
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {

            /**
             * Get the model value
             *
             * @returns {string}
             */
            getValue() {
                return parseInt(this.model);
            }
        }
    }
</script>

