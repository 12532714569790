<template>
    <article class="media mt-15 mb-10">
        <div class="media-content">
            <div class="content">
                <slot />

                <template v-if="campaign.show_revenue_tracking_information">
                    <affiliate-link-notification :campaign="campaign" />
                    <discount-code-notification :campaign="campaign" />
                </template>
            </div>
        </div>
    </article>
</template>

<script>
    import CampaignProposalMixin from '../CampaignProposalMixin';
    import AffiliateLinkNotification from '../../partials/AffiliateLinkNotification.vue';
    import DiscountCodeNotification from '../../partials/DiscountCodeNotification.vue';

    export default {
        name: 'AffiliateCompensation',

        mixins: [
            CampaignProposalMixin,
        ],

        components: {
            DiscountCodeNotification,
            AffiliateLinkNotification,
        },
    };
</script>
