<template>
    <div class="dropdown" :class="{ 'is-active': active }">
        <div class="dropdown-trigger">
            <button
                v-click-outside="closeDropdown"
                class="button"
                :disabled="disabled"
                :class="classObject"
                @click="toggleDropdown"
            >
                <span>{{ text }}</span>
                <span class="icon is-small">
                    <i class="far fa-angle-down"></i>
                </span>
            </button>
        </div>
        <div class="dropdown-menu" :class="{ ['dropdown-menu-right']: right }">
            <div class="dropdown-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import clickOutside from 'vue-click-outside';
    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'DropdownButton',

        /**
         * The component directives
         *
         * @type {object}
         */
        directives: { clickOutside },

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {
            /**
             * The button text
             */
            text: {
                type: String,
                required: true
            },

            /**
             * The loading boolean
             */
            loading: {
                type: Boolean,
                required: false,
                default: false
            },

            /**
             * The disabled boolean
             */
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },

            /**
             * The button classes
             */
            classes: {
                type: Array,
                required: false,
                default() {
                    return [];
                }
            },

            /**
             * Whether the dropdown should align to the right
             */
            right: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {
                /**
                 * Whether or not the dropdown is active
                 *
                 * @type {boolean}
                 */
                active: false
            };
        },

        /**
         * The component's computed properties
         *
         * @type {object}
         */
        computed: {
            /**
             * The class object for the button
             */
            classObject() {
                const cls = { ['is-loading']: this.loading };
                this.classes.forEach((c) => {
                    return (cls[c] = true);
                });
                return cls;
            }
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {
            /**
             * Close the dropdown
             */
            toggleDropdown() {
                this.active = !this.active;
            },

            /**
             * Close the dropdown
             */
            closeDropdown() {
                this.active = false;
            }
        },

        /**
         * When the component is mounted
         *
         * @type {function}
         */
        mounted() {
            // prevent close when clicking in dropdown
            //this.popupItem = this.$el
        }
    };
</script>
