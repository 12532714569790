<template>
    <div>
        <div v-if="context === 'profile'" class="margin-bottom-normal">
            <g-button @click="$emit('go-back')">Back</g-button>
        </div>
        <div class="margin-bottom-large">
            <g-typography tag="h1" font-weight="semibold" value="Frequently Asked Questions" />
        </div>
        <g-collapsible-card
            title="Why do I need an Instagram creator/business account?"
            :showArrow="false"
            class="margin-bottom-normal collapsible-card"
        >
            <p>
                Some Instagram story tasks may require you to provide analytics on the story you post.
                The only way for us to pull in story analytics is by syncing a creator/business account
                through an industry standard authentication protocol called OAuth verification.
            </p>
        </g-collapsible-card>
        <g-collapsible-card
            title="What does it mean for me when I sync my Instagram creator/business account through OAuth verification?"
            :showArrow="false"
            class="margin-bottom-normal collapsible-card"
        >
            <p>
                OAuth verification allows brands to securely access content analytics without the need for
                you to share your password. During the verification process, you grant brands access to read
                your stories, metrics and audience data.
            </p>
            <p>
                Syncing through Oauth does NOT give brands any personal information that isn't shared on your
                public profile nor access to your personal messages. It simply grants them permission to pull
                in content (such as Instagram stories and posts) along with metrics like comments, shares,
                impressions and likes, which are used to analyze success of the content.
            </p>
        </g-collapsible-card>
        <g-collapsible-card
            title="How do I create an Instagram creator/business account?"
            :showArrow="false"
            class="margin-bottom-normal collapsible-card"
        >
            <p>
                Learn how to convert your Instagram account into a Creator account
                <a :href="instagramConnectionFAQ" target="_blank">here</a>.
            </p>
        </g-collapsible-card>
        <g-collapsible-card
            title="Why do I need a Facebook page?"
            :showArrow="false"
            class="margin-bottom-normal collapsible-card"
        >
            <p>
                In order to sync your Instagram Creator account, it must be connected to a Facebook page.
            </p>
            <p>
                Learn how to create a Facebook page and connect your Instagram Creator account to it
                <a :href="connectInstagramWithinFacebookPageFAQ" target="_blank">here</a>.
            </p>
        </g-collapsible-card>
        <g-collapsible-card
            title="I have an Instagram creator account that is connected to a Facebook page. Why isn't my account connecting?"
            :showArrow="false"
            class="margin-bottom-normal collapsible-card"
        >
            <p>
                There may be a few reasons for account connection failure. When making the connection, be sure to...
            </p>
            <ul>
                <li>log in to the Facebook account that owns the page connected to your Instagram account</li>
                <li>select the Facebook page connected to your Creator account when prompted</li>
                <li>leave all Facebook permissions turned ON when prompted</li>
            </ul>
        </g-collapsible-card>
    </div>
</template>

<script>
    import {
        FAQ_INSTAGRAM_CONNECTION,
        FAQ_CONNECT_INSTAGRAM_WITHIN_FACEBOOK_PAGE
    } from '../../../../constants/faqs';

    export default {
        name: 'IgConnectionFaq',
        props: {
            context: {
                type: String,
                default: 'proposal'
            }
        },
        computed: {
            instagramConnectionFAQ() {
                return FAQ_INSTAGRAM_CONNECTION;
            },
            connectInstagramWithinFacebookPageFAQ() {
                return FAQ_CONNECT_INSTAGRAM_WITHIN_FACEBOOK_PAGE;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .margin-bottom-normal {
        margin-bottom: $g-spacing-5;
    }

    .margin-bottom-large {
        margin-bottom: $g-spacing-6;
    }

    .collapsible-card p:last-child {
        margin-bottom: 0;
    }
</style>
