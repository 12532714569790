<template>
	<!-- Campaign Dates -->
	<div class="form-section-container" v-if="has_dates">
		<div class="campaign-form-title">
			{{ customizationText('dates_header', 'Important Dates') }}
		</div>
		<div class="mt-10">
			<div v-if="proposals_end !== 'N/A' && showProposalDates">
				<strong>Proposal Submissions:</strong> Your proposal must be submitted by {{ proposals_end }}
			</div>
			<div v-if="content_end !== 'N/A'">
				<strong>Content Submissions:</strong> Your content must be posted by {{ content_end }}
			</div>
		</div>
	</div>
</template>
<script>
	import CampaignCustomizeMixin from "../../../mixins/CampaignCustomizeMixin";
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'CampaignDates',

		/**
		 * The component properties
		 *
		 * @type {Object}
		 */
		props: {

		    /**
		     * The campaign
		     */
            campaign: {
                type: Object
			},

			showProposalDates: {
                type: Boolean,
				default: true
			}
		},

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            CampaignCustomizeMixin
		],

        /**
         * The component's computed properties
         *
         * @type {object}
         */
        computed: {

            /**
             * Whether the campaign has content and proposal dates
             *
             * @returns {boolean}
             */
            has_dates() {
                return this.content_end !== 'N/A' || this.proposals_end !== 'N/A';
            },

            /**
             * The campaign content end date
             *
             * @returns {string}
             */
            content_end() {
                return this.campaign.dates.content.end;
            },

            /**
             * The campaign proposals end date
             *
             * @returns {string}
             */
            proposals_end() {
                return this.campaign.dates.proposals.end;
            },
		}
    }
</script>
