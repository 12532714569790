<template>
    <fullscreen-drawer
        :visible="visible"
        :closable="true"
        :container="container"
        style="z-index: 1003"
        @close="$emit('close')"
    >
        <g-loader v-if="loading" />
        <div v-else>
            <div v-if="hasAuthedInstagram">
                <g-typography tag="h1" font-weight="semibold" text-align="center" class="g-mb-5">
                    Welcome Back!
                </g-typography>
                <g-typography tag="p" class="g-mb-5">
                    We see that you previously connected an Instagram account with another brand. You'll need to connect
                    your instagram to this brand so they can see your posts, Stories, and insights. Don't worry,
                    reconnecting will be much faster this time!
                </g-typography>
                <g-typography tag="p" color="grey-6" class="g-mb-5">
                    We know how vital data security is for you. We use a secure login flow provided by Meta to protect
                    your data.
                </g-typography>

                <g-typography tag="p" color="grey-6" class="g-mb-7">
                    For setup support, visit our help page
                    <a @click.prevent="handleHelpLinkClick(FAQ_CONNECTING_INSTAGRAM)">Connecting Your Instagram</a>.
                </g-typography>

                <g-button
                    class="continue-button"
                    shape="round"
                    type="primary"
                    size="xlarge"
                    block
                    @click="handleContinue"
                >
                    Continue
                </g-button>
            </div>
            <div v-else>
                <g-typography
                    tag="h1"
                    font-weight="semibold"
                    text-align="center"
                    class="g-mb-3"
                    data-testid="meta-connect-title"
                >
                    Instagram/Facebook<br />Connection
                </g-typography>
                <g-typography tag="p" color="grey-6" class="g-mb-5">
                    We use a secure login flow provided by Meta to protect your data. We're here to help you navigate
                    the the process.
                </g-typography>

                <g-typography tag="ul" color="grey-6" class="g-mb-5 g-text-h3">
                    <li class="g-mb-5">
                        You’ll need either an
                        <a @click.prevent="handleHelpLinkClick(FAQ_INSTAGRAM_BUSINESS)">Instagram Business</a> or
                        <a @click.prevent="handleHelpLinkClick(FAQ_INSTAGRAM_CREATOR)">Instagram Creator</a>
                        account.
                    </li>
                    <li class="g-mb-5">
                        Connect your Instagram account to your
                        <a @click.prevent="handleHelpLinkClick(FAQ_FACEBOOK_CREATE_A_PAGE)">Facebook Business page</a>.
                    </li>
                    <li>
                        Accept the required
                        <a @click.prevent="handleHelpLinkClick(FAQ_CONNECTING_NETWORKS_PERMISSIONS)">permissions</a>. We
                        only ask for the minimum needed.
                    </li>
                </g-typography>
                <g-typography tag="p" color="grey-6" class="g-mb-5">
                    We know how vital data security is for creators. This connection gives us read-only access to your
                    social media profile, posts, metrics, and insights. We never see any account passwords.
                </g-typography>

                <g-typography tag="p" color="grey-6" class="g-mb-7">
                    For setup support, visit our
                    <a @click.prevent="handleHelpLinkClick(FAQ_CONNECTING_INSTAGRAM)">help documentation</a>.
                </g-typography>

                <g-button
                    class="continue-button"
                    shape="round"
                    type="primary"
                    size="xlarge"
                    block
                    data-testid="meta-connect-drawer-continue"
                    @click="handleContinue"
                >
                    Continue
                </g-button>
            </div>
        </div>
    </fullscreen-drawer>
</template>

<script>
    import { computed, ref, onMounted } from 'vue-demi';
    import {
        FAQ_INSTAGRAM_BUSINESS,
        FAQ_INSTAGRAM_CREATOR,
        FAQ_FACEBOOK_CREATE_A_PAGE,
        FAQ_CONNECTING_NETWORKS_PERMISSIONS,
        FAQ_CONNECTING_INSTAGRAM
    } from '../../../../constants/faqs';
    import FullscreenDrawer from '../../../library/FullscreenDrawer.vue';
    import useHttp from '../../../../../src/composables/use-http';
    import { INSTAGRAM_ID, NETWORK_INFO_BY_ID } from '../../../../constants/networks';
    import useEventTracking from '../../../../../src/composables/use-event-tracking';
    import {
        EVENT_NAME_META_CONNECT_DRAWER_CONTINUE,
        EVENT_NAME_META_CONNECT_DRAWER_HELP_LINK
    } from '../../../../constants/analytics-events';
    export default {
        components: {
            FullscreenDrawer
        },
        name: 'MetaConnectionInfoDrawer',
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            isLandingPage: {
                type: Boolean,
                default: false
            },
            networkId: {
                type: Number,
                default: null
            },
            taskId: {
                type: Number,
                default: null
            },
            briefId: {
                type: String,
                default: ''
            },
            liveUrl: {
                type: String,
                default: ''
            }
        },
        setup(props, { emit, root }) {
            //composables
            const events = useEventTracking();

            //state
            const loading = ref(false);
            const hasAuthedInstagram = ref(false);

            const container = computed(() => {
                return props.isLandingPage ? '#grin-widget' : '#app';
            });

            //functions
            const handleContinue = () => {
                const network = NETWORK_INFO_BY_ID[props.networkId].slug;
                const location = hasAuthedInstagram.value ? 'welcome_back' : 'initial';
                events.trackEvent(EVENT_NAME_META_CONNECT_DRAWER_CONTINUE, {
                    network,
                    location
                });
                emit('continue');
            };
            const handleClose = () => {
                emit('close');
            };

            const handleHelpLinkClick = (url) => {
                window.open(url, '_blank');

                let destination = null;
                switch (url) {
                    case FAQ_INSTAGRAM_BUSINESS:
                        destination = 'ig_business';
                        break;
                    case FAQ_INSTAGRAM_CREATOR:
                        destination = 'ig_creator';
                        break;
                    case FAQ_FACEBOOK_CREATE_A_PAGE:
                        destination = 'fb_business';
                        break;
                    case FAQ_CONNECTING_NETWORKS_PERMISSIONS:
                        destination = 'permissions';
                        break;
                    case FAQ_CONNECTING_INSTAGRAM:
                        destination = 'grin_help';
                }

                if (destination) {
                    events.trackEvent(EVENT_NAME_META_CONNECT_DRAWER_HELP_LINK, {
                        destination
                    });
                }
            };

            onMounted(async () => {
                // Temporarily disabling due to performance issues.
                // if (props.networkId === INSTAGRAM_ID) {
                //     loading.value = true;
                //     const { http } = useHttp();
                //     await http
                //         .get(`/api/v1/contacts/${root.contact_id}/networks/${INSTAGRAM_ID}/hasAuthed`)
                //         .then((res) => {
                //             hasAuthedInstagram.value = !!res.data;
                //         });
                //     loading.value = false;
                // }
            });

            return {
                FAQ_INSTAGRAM_BUSINESS,
                FAQ_INSTAGRAM_CREATOR,
                FAQ_FACEBOOK_CREATE_A_PAGE,
                FAQ_CONNECTING_NETWORKS_PERMISSIONS,
                FAQ_CONNECTING_INSTAGRAM,
                container,
                handleContinue,
                handleClose,
                loading,
                hasAuthedInstagram,
                handleHelpLinkClick
            };
        }
    };
</script>

<style lang="scss" scoped>
    .text-align-center {
        text-align: center;
    }
    .continue-button {
        background-color: #5c61ff;
        border: solid 1px #5c61ff;
        &:hover {
            background-color: #4246e7;
            border-color: #4246e7;
        }
    }
    ::v-deep .FullscreenDrawer__content {
        max-width: 400px !important;
    }
    ::v-deep .ant-drawer-content-wrapper {
        box-shadow: none !important;
    }
</style>
