<template>
    <div class="shared-networks">
        <g-typography text-align="center" tag="h1" font-weight="bold">
            {{ NETWORK_INFO_BY_ID[networkId].name }} Connection
        </g-typography>
        <div class="g-mt-3">
            <g-typography tag="p">
                {{ headerText }}
            </g-typography>
        </div>
        <div v-if="filteredLiveUrls.length > 1" class="brand-toggle-wrapper">
            <div class="brand-toggle g-mt-6">
                <g-typography value="Select All" tag="p" font-weight="bold" />
                <g-switch v-model="selectAll" @change="executeSelectAll" />
            </div>
            <div v-for="liveUrl in filteredLiveUrls" :key="liveUrl.url" class="brand-toggle-wrapper">
                <div class="brand-toggle g-mt-6">
                    <g-typography :value="showLiveSiteSwitcher.formatBrandNames(liveUrl)" tag="p" font-weight="bold" />
                    <g-switch v-model="liveUrl.value" @change="handleToggleChange" />
                </div>
            </div>
        </div>
        <div class="shared-networks-disclaimers g-mt-6">
            <g-typography color="grey-6" size="small" tag="p" font-weight="label">
                We know how vital <a :href="FAQ_DATA_PRIVACY" target="_blank">data security</a> is for you. We use a
                secure login flow provided by {{ NETWORK_INFO_BY_ID[networkId].parentCompanyName }} to protect your
                data.
            </g-typography>
        </div>
        <div class="shared-networks-disclaimers g-mt-5">
            <g-typography color="grey-6" size="small" tag="p" font-weight="label">
                For setup support, visit our help page
                <a :href="FAQ_CONNECTING_NETWORKS_PERMISSIONS" target="_blank">Managing your social network</a>.
            </g-typography>
        </div>
        <g-button
            class="g-mt-6"
            shape="round"
            theme="live"
            type="primary"
            height="50px"
            size="large"
            :disabled="selectedAccountIds.length <= 0"
            block
            data-testid="connect"
            @click="doConnect"
        >
            Yes, auto connect
        </g-button>
        <div data-testid="doLater" @click="doLater">
            <g-typography tag="p" text-align="center" class="connection-no-button g-mt-5">
                {{ doLaterText }}
            </g-typography>
        </div>
    </div>
</template>

<script>
    import { message } from '@grininc/grin-ui';
    import { useCreatorStore, useContactStore } from '../../../../stores';
    import useEventTracking from '../../../../src/composables/use-event-tracking';
    import {
        EVENT_NAME_SHARED_CONNECTION_SKIPPED,
        EVENT_NAME_CONNECTION_SHARED
    } from '../../../constants/analytics-events';
    import { computed, onMounted, ref } from 'vue-demi';
    import { NETWORK_INFO_BY_ID } from '../../../constants/networks';
    import { FAQ_CONNECTING_NETWORKS_PERMISSIONS, FAQ_DATA_PRIVACY } from '../../../../../assets/app-v2/constants/faqs';
    import useLiveSiteSwitcher from '../../../../../assets/src/composables/use-live-site-switcher';
    import useHttp from '../../../../../assets/src/composables/use-http';
    import NetworkConnectionMixin from '../../../mixins/NetworkConnectionMixin';

    export default {
        name: 'SharedNetworks',
        props: {
            networkId: {
                type: Number
            },
            socialNetworkId: {
                type: Number
            },
            contactId: {
                type: String,
                required: false
            },
            host: {
                type: String,
                required: false
            },

        },
        mixins: [NetworkConnectionMixin],
        setup(props, context) {
            const creator = useCreatorStore();
            const contact = useContactStore().data;
            const events = useEventTracking();
            const { http } = useHttp();
            const showLiveSiteSwitcher = useLiveSiteSwitcher();
            const selectAll = ref(true);
            const filteredLiveUrls = ref([]);
            const socialNetworkToShare = ref({});
            const isSubmitting = ref(false);
            const isPostConnect = ref(!!props.socialNetworkId);

            onMounted(() => {
                // If we were given a specific socialNetworkId to share (post-connect flow)
                // let's find the socialNetwork object for it.
                if (props.socialNetworkId) {
                    socialNetworkToShare.value = creator.getSocialNetworkById(props.socialNetworkId);
                } else {
                    // Otherwise, this is the pre-connect flow and we want to find the first
                    // active socialNetwork to share.
                    socialNetworkToShare.value = creator.getFirstActiveSocialNetwork(props.networkId);
                }

                buildFilteredLiveUrls();
            });

            const buildFilteredLiveUrls = () => {
                creator.data?.liveUrls?.forEach((liveUrl) => {
                    if (
                        creator.canShareToBrand(
                            liveUrl,
                            props.networkId,
                            socialNetworkToShare.value.external_id,
                            isPostConnect.value
                        )
                    ) {
                        const liveUrlCopy = JSON.parse(JSON.stringify(liveUrl));
                        liveUrlCopy.value = true;
                        filteredLiveUrls.value.push(liveUrlCopy);
                    }
                });
            };

            const selectedAccountIds = computed(() => {
                const accountIds = [];
                if (filteredLiveUrls.value.length <= 0) {
                    return accountIds;
                }

                if (filteredLiveUrls.value.length > 1) {
                    filteredLiveUrls.value.forEach((liveUrl) => {
                        if (liveUrl.value === true) {
                            accountIds.push(liveUrl.brands[0].account_id);
                        }
                    });
                } else {
                    accountIds.push(filteredLiveUrls.value[0].brands[0].account_id);
                }

                return accountIds;
            });

            const doConnect = () => {
                const filteredBrandIds = [];

                const filteredBrands = filteredLiveUrls.value.filter(filteredBrandIdsCheck);

                function filteredBrandIdsCheck(liveUrl) {
                    return liveUrl.value;
                }

                filteredBrands.forEach((liveUrl) => {
                    liveUrl.brands.forEach((brand) => {
                        filteredBrandIds.push(brand.id);
                    });
                });

                events.trackEvent(`${NETWORK_INFO_BY_ID[props.networkId].name}${EVENT_NAME_CONNECTION_SHARED}`, {
                    contact_id: contact?.id,
                    brand_ids_added: filteredBrandIds,
                    total_authed_connections: selectedAccountIds?.value?.length,
                    select_all_selected: selectAll.value
                });

                isSubmitting.value = true;

                http.post(`/creator/share-network-with-brands`, {
                    social_network_id: socialNetworkToShare.value.id,
                    account_ids: selectedAccountIds.value
                })
                    .then(() => {
                        message.success('Account successfully connected.');
                        isSubmitting.value = false;
                        context.emit('success');
                    })
                    .catch(() => {
                        message.error('Failed to share network.');
                        isSubmitting.value = false;
                    });
            };

            const headerText = computed(() => {
                let text = '';

                if (props.socialNetworkId) {
                    if (filteredLiveUrls.value?.length > 1) {
                        text =
                            `We notice you're working with additional brands. ` +
                            `Would you like to automatically connect your ` +
                            `${NETWORK_INFO_BY_ID[props.networkId].name} ` +
                            `to these brands as well?`;
                    } else {
                        text =
                            `We notice you're working with an additional brand. ` +
                            `Would you like to automatically connect your ` +
                            `${NETWORK_INFO_BY_ID[props.networkId].name} ` +
                            `for ${showLiveSiteSwitcher.formatBrandNames(filteredLiveUrls.value[0])} as well?`;
                    }
                } else {
                    text =
                        `We noticed you've already connected your ` +
                        `${NETWORK_INFO_BY_ID[props.networkId].name} with another brand. ` +
                        `Would you like to automatically connect your ` +
                        `${NETWORK_INFO_BY_ID[props.networkId].name} `;

                    if (filteredLiveUrls.value?.length > 1) {
                        text += `to these brands as well?`;
                    } else {
                        text += `for ${showLiveSiteSwitcher.formatBrandNames(filteredLiveUrls.value[0])} as well?`;
                    }
                }

                return text;
            });

            const doLaterText = computed(() => {
                return props.socialNetworkId ? "No, I'll do this later" : 'No, proceed to manually connect';
            });

            const doLater = () => {
                events.trackEvent(
                    `${NETWORK_INFO_BY_ID[props.networkId].name}${EVENT_NAME_SHARED_CONNECTION_SKIPPED}`,
                    {
                        contact_id: contact?.id,
                        brand_id: filteredLiveUrls.value[0]?.brands[0]?.id
                    }
                );
                if (props.socialNetworkId) {
                    context.emit('back');
                } else {
                    context.emit('proceed-to-connect', props.networkId);
                }
            };

            const executeSelectAll = () => {
                filteredLiveUrls.value?.forEach((liveUrl) => {
                    liveUrl.value = selectAll.value;
                });
            };

            const handleToggleChange = () => {
                if (filteredLiveUrls.value.length <= 1) {
                    return;
                }

                if (selectedAccountIds.value.length !== filteredLiveUrls.value.length && selectAll.value === true) {
                    selectAll.value = false;
                } else if (
                    selectedAccountIds.value.length === filteredLiveUrls.value.length &&
                    selectAll.value === false
                ) {
                    selectAll.value = true;
                }
            };

            return {
                NETWORK_INFO_BY_ID,
                doConnect,
                selectAll,
                doLater,
                handleToggleChange,
                executeSelectAll,
                headerText,
                doLaterText,
                showLiveSiteSwitcher,
                filteredLiveUrls,
                selectedAccountIds,
                FAQ_CONNECTING_NETWORKS_PERMISSIONS,
                FAQ_DATA_PRIVACY,
                isSubmitting
            };
        }
    };
</script>

<style lang="scss" scoped>
    .shared-networks {
        display: flex;
        flex-flow: column;
        justify-content: center;
    }
    .brand-toggle {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &-wrapper {
            width: 100%;
        }
    }
    .shared-networks-disclaimers {
        span {
            color: $g-color-primary;
        }
        span:hover {
            cursor: pointer;
        }
    }
    .connection-no-button {
        cursor: pointer;
    }
</style>
