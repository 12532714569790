<template>
    <div class="field">
        <!--<label class="label">{{ title || 'Preview' }}</label>-->
        <div class="content-rights-preview-card">
            <div class="content" v-if="!isCustom">
                <p>
                    {{ contentRightsIntro }}
                </p>
                <ul>
                    <li v-for="contentRight in selectedContentRights">{{ contentRight.text }}</li>
                </ul>
            </div>
            <div v-else>
                <div>
                    <div v-html="$sanitize(contentRights.custom_content_rights)"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    p,
    ul {
        font-size: 0.9em;
    }
</style>
<script>
    import _ from 'lodash';

    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'ContentRightsPreview',

        /**
         * The component props
         *
         * @type {object}
         */
        props: {
            /**
             * The content rights
             *
             * @type {object}
             */
            contentRights: {
                type: Object
            },

            /**
             * The title to be displayed
             */
            title: {
                type: String,
                required: false
            }
        },

        /**
         * The components data
         */
        data() {
            return {
                defaultSelectedContentRights: [
                    { id: 1, text: 'Posting to social media platforms' },
                    { id: 2, text: 'Paid support of social media posts' },
                    { id: 3, text: "Publishing to Brand's websites" },
                    { id: 4, text: "Use in Brand's digital, email or text message communications" },
                    { id: 5, text: 'Use in online advertising within social media platforms' }
                ],
                defaultContentRightsHeader:
                    'Influencer grants Brand a worldwide, irrevocable, royalty-free, fully paid-up, transferrable, sub-licensable and perpetual right and license to reproduce, publish, distribute, display, repost, share and edit all Content created for or on behalf of Brand in any and all media now known or developed in the future, including the right to create derivative works; which includes but is not limited to:'
            };
        },

        /**
         * The components computed methods
         * @type {object}
         */
        computed: {
            /**
             * Check is custom content rights are being used.
             * @returns {boolean}
             */
            isCustom() {
                return !!_.get(this.contentRights, 'custom_content_rights', null);
            },
            /**
             * Get the selected content rights or empty array.
             * @returns {*}
             */
            selectedContentRights() {
                return _.get(
                    this.contentRights,
                    'standard_content_rights.selected_content_rights',
                    this.defaultSelectedContentRights
                ).sort((elA, elB) => elA.id - elB.id);
            },
            /**
             * Get the content rights intro or empty string.
             * @returns {*}
             */
            contentRightsIntro() {
                return _.get(
                    this.contentRights,
                    'standard_content_rights.content_rights_header',
                    this.defaultContentRightsHeader
                );
            }
        }
    };
</script>
