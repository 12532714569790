<template>
    <div class="notification is-dark">
        <strong>Sorry!</strong> We are not currently accepting influencer program submissions.
        <span v-if="$root.account.support_email">
            If you have any questions you can email us
            at <a :href="`mailto:${$root.account.support_email}?subject=Influencer%20Program%20Application`">
                {{ $root.account.support_email }}
            </a>
        </span>
    </div>
</template>
<script>
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'NotAccepting'
    }
</script>

