import _ from 'lodash';

export default {

    /**
     * The computed properties
     *
     * @type {object}
     */
    computed: {

        /**
         * The contact id
         *
         * @returns {string|null}
         */
        contact_id() {
            return _.get(this.contact, 'id', null);
        },

        /**
         * Whether or not the contact has their shipping address
         *
         * @returns {boolean}
         */
        has_shipping() {
            return (
                _.isString(_.get(this.contact, 'street') || null)
                // &&
                // _.isString(_.get(this.contact, 'city') || null)
                //&&
                //_.isString(_.get(this.contact, 'zip') || null)
                &&
                _.isString(_.get(this.contact, 'country') || null)
            );
        }
    },

    /**
     * The mixin methods
     *
     * @type {object}
     */
    methods: {

        /**
         * The section to reload
         *
         * @param {string} section
         */
        reloadContact(section = 'full') {
            return this.$http.get(`/contact/${this.contact_id}/${section}`).then(res => {
                if(section === 'full') {
                    for(let part in res.data) {
                        this[part] = res.data[part];
                    }
                }

                this[section] = res.data;

                return res;
            });
        },

        /**
         * Update the contact information
         *
         * @param {object} payload
         * @returns {Promise}
         */
        updateAddress(payload) {
            return this.$http.post(`/contact/${this.$root.contact_id}/update/shipping`, payload)
                .then(response => this.$root.contact = response.data)
            ;
        },

        /**
         * Update the contact record
         *
         * @param {object} payload
         * @returns {Promise}
         */
        updateContact(payload) {
            return this.$http.post(`/contact/${this.$root.contact.id}/campaign/update/contact`, payload)
                .then(response => this.$root.contact = response.data)
            ;
        }


    }
}