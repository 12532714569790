export const NAME_COMPENSATION = 'compensation';
export const NAME_PRODUCTS = 'products';
export const NAME_TASKS = 'tasks';
export const NAME_UPLOAD_FILES = 'upload_files';
export const NAME_NETWORKS = 'networks';
export const NAME_SHIPPING_INFO = 'shipping_info';
export const NAME_PERSONAL_INFO = 'personal_info';
export const NAME_CAMPAIGN_ASSETS = 'campaign_assets';
export const NAME_CONTENT_AGREEMENT = 'content_agreement';
export const NAME_BRAND_ASSETS = 'brand_assets';
