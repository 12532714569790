import googleAnalyticsDriver from './analytics/operators/google-analytics';
import useRoute from './use-route';
import useRouter from './use-router';

const useEventTracking = () => {
    const router = useRouter().get();
    const route = useRoute();

    const clients = [googleAnalyticsDriver];

    /**
     * @param {string}  event       - event constant
     * @param {Object}  properties  - key/values to save with event
     * @param {boolean} success     - if event was successful
     */
    const trackEvent = (event, properties = {}, success = true) => {
        if (typeof properties.was_successful === 'undefined') {
            properties.was_successful = success;
        }
        if (typeof properties.reference_page === 'undefined' && router) {
            properties.reference_page = {
                name: route.convertRouteToPageName(router.currentRoute),
                category: router.currentRoute?.meta.category,
                params: router.currentRoute?.params
            };
        }

        clients.forEach((client) => {
            const c = client();
            if (c.isSupportedEvent(event, properties)) {
                c.trackEvent(event, properties);
            }
        });
    };

    return {
        trackEvent
    };
};

export default useEventTracking;
