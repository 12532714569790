<template>
    <div>

        <field label="Phone Number" v-if="show_phone">
            <phone-input ref="phone" />
        </field>

        <field label="Gender" v-if="show_gender">
            <el-radio v-model="contact_model.gender" label="Male">Male</el-radio>
            <el-radio v-model="contact_model.gender" label="Female">Female</el-radio>
            <el-radio v-model="contact_model.gender" label="Other">Other</el-radio>
        </field>

        <div v-if="show_custom_fields">
            <field v-for="field in custom_fields" :label="field.display_label" :pop_text="field.description" :key="field.id">
                <component :is="`${field.component}Input`"
                           :field="field"
                           :value="getFieldValue(field)"
                           ref="field_refs" />
            </field>
        </div>

        <field v-if="show_submit && inSettings">
            <button class="button" :class="{'is-loading': $root.loading('proposal.campaign.fields')}" @click="updateContact">
                Update
            </button>
        </field>
    </div>
</template>
<script>
    import CrmFieldInputMixin from '../../../join/mixins/CrmFieldInputsMixin';
    import PhoneInput from "../../../../interface/form/PhoneInput.vue";

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'CampaignAdditionalFields',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The campaign page fields
             */
            pageFields: {
                type: Array,
                required: false,
                default() {
                    return []
                }
            },

            /**
             * Whether the form is in settings
             */
            inSettings: {
                type:     Boolean,
                required: false,
                default:  true
            }
        },

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            CrmFieldInputMixin
        ],

        /**
         * The child components
         *
         * @type {object}
         */
        components: {
            PhoneInput
        },

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {

                /**
                 * The custom fields for the account
                 *
                 * @type {array}
                 */
                fields: SERVER_DATA.fields,

                /**
                 * The fields that should show up on the page
                 *
                 * @type {array}
                 */
                page_fields: this.pageFields,

                /**
                 * The contact model
                 *
                 * @type {object}
                 */
                contact_model: {
                    gender: this.$root.contact.gender
                }
            }
        },

        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {

            /**
             * Whether or not to show the phone input
             *
             * @returns {boolean}
             */
            show_phone() {
                return this.hasField('phone_number') && !this.contact_model.phone_number;
            },

            /**
             * Whether or not to show the gender input
             *
             * @returns {boolean}
             */
            show_gender() {
                return this.hasField('gender') && !this.contact_model.phone_number;
            },

            /**
             * Whether or not to show the custom fields
             *
             * @returns {boolean}
             */
            show_custom_fields() {
                return true; //this.custom_fields.filter(field => !this.getFieldValue(field)).length;
            },

            /**
             * Whether or not to show the submit button
             *
             * @returns {boolean}
             */
            show_submit() {
                return this.show_phone || this.show_gender || this.show_custom_fields;
            }
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {

            /**
             * Get the contact field payload
             *
             * @returns {object}
             */
            getPayload() {
                let payload = this.contact_model;

                if(this.$refs.field_refs && this.$refs.field_refs.length) {
                    this.$refs.field_refs.forEach(field_ref => {
                        payload[`field_${field_ref.field.id}`] = field_ref.getValue();
                    });
                }

                if(this.hasField('phone_number')) {
                    Object.assign(payload, { phone: this.$refs.phone.getPhoneNumber()});
                }

                return payload;
            },

            /**
             * Update the contact record
             */
            updateContact() {
                return this.$root.updateContact(this.getPayload())
                    .then(() => this.$bus.$emit('proposal.campaign.fields.updated'))
                ;
            }
        }
    }
</script>