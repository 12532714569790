<template>
    <span class="tag" :class="classList">
        {{ status }}
    </span>
</template>
<script>
    import { statusColor } from '../../../helpers';
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'StatusTag',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The task status
             */
            status: {
                type: String,
                required: true
            },

            /**
             * The override color
             */
            color: {
                type: String,
                required: false,
                default: null
            },

            /**
             * The tag size
             */
            size: {
                type: String,
                required: false,
                default: 'medium'
            }
        },

        /**
         * The component's computed properties
         *
         * @type {object}
         */
        computed: {

            /**
             * The tag type (color)
             *
             * @returns {string}
             */
            type() {
                if(this.color) {
                    return this.color;
                }

                return statusColor(this.status, true);
            },

            /**
             * The tag classes
             *
             * @returns {object.boolean}
             */
            classList() {
                return {
                    [`is-${this.size}`]: true,
                    [`is-${this.type}`]: true
                };
            }

        }
    }
</script>