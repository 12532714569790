import VueMoney from 'v-money';
import VueRouter from 'vue-router';
import VueBus from '../plugins/bus';
import VueNotify from '../plugins/notify';
import VueSanitize from 'vue-sanitize';
import { install } from 'vue-demi';
import { PiniaVuePlugin } from 'pinia';
import sanitizeHtml from 'sanitize-html';
import { mask } from 'vue-the-mask';
import Vue from 'vue';

function createVueInstance() {
    install(Vue);
    Vue.use(PiniaVuePlugin);
    // use components
    Vue.use(VueRouter);

    Vue.use(VueMoney, {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false
    });

    Vue.use(VueBus);
    Vue.use(VueNotify);
    const tagAttributes = [
        'abbr',
        'accept',
        'accept-charset',
        'accesskey',
        'action',
        'allow',
        'alt',
        'as',
        'autocapitalize',
        'autocomplete',
        'blocking',
        'charset',
        'cite',
        'class',
        'color',
        'cols',
        'colspan',
        'content',
        'contenteditable',
        'coords',
        'crossorigin',
        'data',
        'datetime',
        'decoding',
        'dir',
        'dirname',
        'download',
        'draggable',
        'enctype',
        'enterkeyhint',
        'fetchpriority',
        'for',
        'form',
        'formaction',
        'formenctype',
        'formmethod',
        'formtarget',
        'headers',
        'height',
        'hidden',
        'high',
        'href',
        'hreflang',
        'http-equiv',
        'id',
        'imagesizes',
        'imagesrcset',
        'inputmode',
        'integrity',
        'is',
        'itemid',
        'itemprop',
        'itemref',
        'itemtype',
        'kind',
        'label',
        'lang',
        'list',
        'loading',
        'low',
        'max',
        'maxlength',
        'media',
        'method',
        'min',
        'minlength',
        'name',
        'nonce',
        'optimum',
        'pattern',
        'ping',
        'placeholder',
        'popover',
        'popovertarget',
        'popovertargetaction',
        'poster',
        'preload',
        'referrerpolicy',
        'rel',
        'rows',
        'rowspan',
        'sandbox',
        'scope',
        'shape',
        'size',
        'sizes',
        'slot',
        'span',
        'spellcheck',
        'src',
        'srcdoc',
        'srclang',
        'srcset',
        'start',
        'step',
        'style',
        'tabindex',
        'target',
        'title',
        'translate',
        'type',
        'usemap',
        'value',
        'width',
        'wrap'
    ];
    Vue.use(VueSanitize, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
        allowedAttributes: {
            a: tagAttributes,
            img: tagAttributes,
            div: tagAttributes,
            span: tagAttributes
        }
    });

    // directives
    Vue.directive('mask', mask);

    // initialize devtools when not in production
    // if (__DEVELOPMENT__ || __STAGE__) {
    Vue.config.devtools = process.env.NODE_ENV !== 'production';
    Vue.config.productionTip = false;
    // }

    // return Vue for further setup
    return Vue;
}

export default createVueInstance;
