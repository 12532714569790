<template>
    <input class="input" type="number" v-model="model" />
</template>
<script>
    import FieldInputMixin from './FieldInputMixin';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'NumberInput',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            FieldInputMixin
        ],

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {

            /**
             * Get the model value
             *
             * @returns {string}
             */
            getValue() {
                return parseFloat(this.model);
            }
        }
    }
</script>

