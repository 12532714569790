import { computed } from 'vue-demi';
import { useCreatorStore, useFeatureFlagsStore } from '../../stores';

const useLiveSiteSwitcher = () => {
    const flags = useFeatureFlagsStore().data;

    const showLiveSiteSwitcher = computed(() => {
        return (flags['live-site-switcher'] || false) && useCreatorStore().worksWithMulitpleBrands;
    });

    const getCurrentAccount = computed(() => {
        return useCreatorStore().getCurrentLiveUrlAccount;
    });

    const getCurrentAccountBrandNames = computed(() => {
        return formatBrandNames(getCurrentAccount.value);
    });

    const isCurrentAccount = (liveUrlAccount) => {
        return useCreatorStore().isCurrentLiveUrlAccount(liveUrlAccount);
    };

    const formatBrandNames = (account) => {
        let brands = '';
        account?.brands.forEach((brand) => (brands += brand.name + ', '));
        brands = brands.replace(/,\s*$/, '');
        return brands;
    };

    return {
        showLiveSiteSwitcher,
        getCurrentAccount,
        getCurrentAccountBrandNames,
        isCurrentAccount,
        formatBrandNames
    };
};

export default useLiveSiteSwitcher;
