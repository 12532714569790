const useAddress = () => {
    const isGooglePlaceAutoCompleteSuggestionMissingStreetNumber = (addressParts) => {
        // It is not a Google Place autocomplete suggestion if we do not have any of the fields
        // (other than the street).
        if (!addressParts.country && !addressParts.city && !addressParts.state && !addressParts.zip) {
            return false;
        }
        // No street at all? We are definitely missing response data.
        const street = addressParts.street;
        if (!street) {
            return true;
        }
        const streetParts = street.split(' ');
        // Check to see if the first part of the street has a number in it.
        // Note: this may not be valid for every county, as some might not
        // have numbers in them, or might now have them in the first part
        // of the street.
        if (!/\d/.test(streetParts[0])) {
            return true;
        }
        return false;
    };
    return {
        isGooglePlaceAutoCompleteSuggestionMissingStreetNumber
    };
};
export default useAddress;
