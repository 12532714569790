<template>
    <div class="field has-addons">
        <div class="control">
            <el-select v-model="model.code" filterable placeholder="Country" size="small">
                <el-option
                    v-for="prefix in country_prefixes"
                    :key="prefix.country"
                    :label="`+${prefix.number} (${prefix.country})`"
                    :value="prefix.code"
                />
            </el-select>
        </div>
        <div class="control is-expanded">
            <g-input
                v-model="model.phone_number"
                name="tel"
                autocomplete="tel"
                class="input"
                placeholder="(XXX) XXX-XXXX"
                style="height: 100%"
                @keyup="formatNumber"
            />
        </div>
    </div>
</template>
<script>
    import { parseNumber, AsYouType } from 'libphonenumber-js';
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'PhoneInput',

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {

                /**
                 * The phone number model
                 */
                model: {
                    code:         'US',
                    phone_number: null
                },

                /**
                 * The country
                 */
                country_prefixes: [],
            }
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {

            /**
             * Format the phone number
             */
            formatNumber() {
                if (this.model.phone_number && this.model.phone_number.length > 5) {
                    this.model.phone_number = new AsYouType(this.model.code).input(this.model.phone_number);
                }
            },

            /**
             * Get the country phone prefixes
             */
            getPrefixes() {
                this.$http.get('/location/prefixes')
                    .then(res => this.country_prefixes = res.data.data);
            },

            /**
             * Set the model
             *
             * @param {string} number
             * @param {string} code
             */
            setModel(number, code) {
                this.model.code = code;
                this.model.phone_number = number;
                this.formatNumber();
            },

            /**
             * Get the phone number object
             *
             * @returns {object|null}
             */
            getPhoneNumber() {
                if(!this.model.phone_number || !this.model.phone_number.length) {
                    return null;
                }
                return parseNumber(this.model.phone_number, this.model.code);
            }
        },

        /**
         * WHen the component is first created
         */
        created() {
            return this.getPrefixes();
        },

        /**
         * When the component is mounted
         */
        mounted() {
            if(this.$root.contact) {
                this.setModel(
                    this.$root.contact.phone_number,
                    this.$root.contact.phone_country_code
                );
            }
        }
    }
</script>

