import { defineStore } from 'pinia';

const useAccountStore = defineStore('account', {
    state: () => {
        return {
            data: window.SERVER_DATA.account || {}
        };
    }
});

export default useAccountStore;
