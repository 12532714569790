export const PLACEHOLDER_CONTACT = 'https://d1igyfcg6nq1cr.cloudfront.net/placeholder/placeholder-contacts.jpg';
export const PLACEHOLDER_IMAGE = 'https://d1igyfcg6nq1cr.cloudfront.net/placeholder/placeholder-image.png';
export const LIVE_IMAGES = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/';
export const INSTAGRAM_CONNECT_LOGO = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/modal-instagram.png';
export const INSTAGRAM_REELS_ICON = 'https://d38xvr37kwwhcm.cloudfront.net/image/instagram-reels.svg';
export const TIKTOK_CONNECT_LOGO = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/modal-tiktok.png';
export const PINTEREST_CONNECT_LOGO = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/tiktok-account%402x.png';
export const FACEBOOK_CONNECT_LOGO = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/modal-facebook.png';
export const FAILED_LOGO = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/modal-error.png';
export const PAYOUT_METHOD_CARD_IMAGE = 'https://d1igyfcg6nq1cr.cloudfront.net/image/logos/payment-methods/card-payment-method.svg';
export const PAYOUT_METHOD_BANK_IMAGE = 'https://d1igyfcg6nq1cr.cloudfront.net/image/logos/payment-methods/ach-plaid-payment-method.svg';
export const PAYOUT_METHOD_BANK_MANUAL_IMAGE = 'https://d1igyfcg6nq1cr.cloudfront.net/image/logos/payment-methods/ach-manual-payment-method.svg';
export const PAYOUT_METHOD_GRIN_DEBIT_CARD_IMAGE = 'https://d1igyfcg6nq1cr.cloudfront.net/image/logos/payment-methods/grin-pay-icon.svg';
export const PAYOUT_METHOD_ADD_BANK_ACCOUNT_IMAGE = 'https://d38xvr37kwwhcm.cloudfront.net/image/settings/ach-verify.svg';
export const ONBOARDING_POLL_OPTION_VERY_DIFFICULT = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/very-difficult.svg';
export const ONBOARDING_POLL_OPTION_FAIRLY_DIFFICULT = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/fairly-difficult.svg';
export const ONBOARDING_POLL_OPTION_STANDARD = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/standard.svg';
export const ONBOARDING_POLL_OPTION_FAIRLY_EASY = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/fairly-easy.svg';
export const ONBOARDING_POLL_OPTION_VERY_EASY = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/very-easy.svg';
export const GRIN_LOGO_BLACK = 'https://d38xvr37kwwhcm.cloudfront.net/image/logos/grin-logo-black.svg';
export const INSIGHT_NULL_STATE = 'https://d38xvr37kwwhcm.cloudfront.net/image/influencer/insight-null-state.png';
