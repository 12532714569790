import isEmpty from 'lodash/isEmpty';
import { message } from '@grininc/grin-ui';

/**
 * NOTE: This mixin is deprecated. Please use the `usePayoutAccountStore`
 *       store going forward.
 *
 * @deprecated
 */
export default {
    data() {
        return {
            isLoading: true,
            payoutAccount: null
        };
    },

    methods: {
        loadPayoutAccount(withExternal = false) {
            this.isLoading = true;

            let url = `/api/v1/contacts/${this.$root.contact.id}/payout-account`;

            if (withExternal) {
                url += '?expand=external_account';
            }

            this.$http
                .get(url)
                .then((response) => {
                    if (!isEmpty(response.data)) {
                        this.payoutAccount = response.data;
                        this.$root.contact.payoutAccount = this.payoutAccount;
                    }
                })
                .catch(() => {
                    message.error('Unable to load your payout account.');
                })
                .finally(() => (this.isLoading = false));
        }
    }
};
