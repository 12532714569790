import { INSTAGRAM_CONNECT_LOGO, FACEBOOK_CONNECT_LOGO, TIKTOK_CONNECT_LOGO, PINTEREST_CONNECT_LOGO } from './cdn-assets';
/**
 * Network Slugs
 */
export const FACEBOOK_SLUG = 'facebook';
export const YOUTUBE_SLUG = 'youtube';
export const TWITTER_SLUG = 'twitter';
export const TWITCH_SLUG = 'twitch';
export const TIKTOK_SLUG = 'tiktok';
export const INSTAGRAM_SLUG = 'instagram';
export const INSTAGRAM_GRAPH_SLUG = 'graph';
export const PINTEREST_SLUG = 'pinterest';
/**
 * Network error slugs
 */
export const INSTAGRAM_ACCOUNT_SLUG = 'ig-account';
export const INSTAGRAM_PAGE_SLUG = 'ig-page';
export const INSTAGRAM_PERMISSIONS_SLUG = 'ig-permissions';
export const PINTEREST_ACCOUNT_SLUG = 'pinterest-account';
export const TIKTOK_PERMISSIONS_SLUG = 'tiktok-permissions';
export const FACEBOOK_PENDING_SLUG = 'facebook-pending';
export const FACEBOOK_MISSING_USERNAME = 'facebook-missing-username';
/**
 * Network connection failure reasons
 */
export const CONNECTION_FAILURE_REASON_INCOMPLETE = 'incomplete';
export const CONNECTION_FAILURE_REASON_ACCOUNT_MISSING = 'account_missing';
export const CONNECTION_FAILURE_REASON_CONNECTION_PENDING = 'connection_pending';
export const CONNECTION_FAILURES = {
    [CONNECTION_FAILURE_REASON_INCOMPLETE]: {
        title: 'Connection Incomplete',
        message: "Without a connection, your posts, Stories, and insights won't automatically sync. You will need to manually deliver your content.",
        page: INSTAGRAM_PAGE_SLUG
    },
    [CONNECTION_FAILURE_REASON_ACCOUNT_MISSING]: {
        title: 'Connection Failed',
        message: "Without a connection, your posts, Stories, and insights won't automatically sync. You will need to manually deliver your content.",
        page: INSTAGRAM_ACCOUNT_SLUG
    },
    [CONNECTION_FAILURE_REASON_CONNECTION_PENDING]: {
        title: 'Connection Pending',
        message: 'No further action needed. Once confirmed, this message will disappear.',
        page: FACEBOOK_PENDING_SLUG
    }
};
/**
 * Discovery API responses
 */
export const DISCOVERY_NOT_DISCOVERABLE = 'not discoverable';
export const DISCOVERY_INVALID_BRAND_TOKEN = 'invalid brand token';
export const DISCOVERY_INVALID_BRAND_PERMISSIONS = 'Invalid permissions';
export const DISCOVERY_NO_BRAND_TOKEN = 'no brand token';
/**
 * Network Connection Types
 */
export const NETWORK_CONNECTION_TYPE_SOFT = 'soft';
export const NETWORK_CONNECTION_TYPE_OAUTH = 'oauth';
export const NETWORK_CONNECTION_TYPE_FAILED = 'failed';
/**
 * Network Connection Permissions
 */
export const NETWORK_CONNECTION_PERMISSIONS_NONE = 'none';
export const NETWORK_CONNECTION_PERMISSIONS_FULL = 'full';
/**
 * Network Account Types
 */
export const NETWORK_ACCOUNT_TYPE_SOFT = 'standard';
export const NETWORK_ACCOUNT_TYPE_CREATOR = 'creator';
export const NETWORK_ACCOUNT_TYPE_PRIVATE = 'private';
/**
 * Network IDs
 */
export const YOUTUBE_ID = 1;
export const TWITTER_ID = 2;
export const FACEBOOK_ID = 3;
export const TWITCH_ID = 4;
export const INSTAGRAM_ID = 5;
export const PINTEREST_ID = 6;
export const LINKEDIN_ID = 7;
export const SNAPCHAT_ID = 8;
export const TIKTOK_ID = 9;
export const NETWORK_INFO_BY_ID = {
    [YOUTUBE_ID]: {
        id: YOUTUBE_ID,
        name: 'YouTube',
        parentCompanyName: 'Google',
        position: 1,
        slug: YOUTUBE_SLUG
    },
    [TWITTER_ID]: {
        id: TWITTER_ID,
        name: 'Twitter',
        parentCompanyName: 'Twitter',
        position: 2,
        slug: TWITTER_SLUG
    },
    [INSTAGRAM_ID]: {
        id: INSTAGRAM_ID,
        name: 'Instagram',
        parentCompanyName: 'Meta',
        position: 3,
        slug: INSTAGRAM_SLUG
    },
    [FACEBOOK_ID]: {
        id: FACEBOOK_ID,
        name: 'Facebook',
        parentCompanyName: 'Meta',
        position: 4,
        slug: FACEBOOK_SLUG
    },
    [TWITCH_ID]: {
        id: TWITCH_ID,
        name: 'Twitch',
        parentCompanyName: 'Twitch',
        position: 5,
        slug: TWITCH_SLUG
    },
    [TIKTOK_ID]: {
        id: TIKTOK_ID,
        name: 'TikTok',
        parentCompanyName: 'TikTok',
        position: 6,
        slug: TIKTOK_SLUG
    },
    [PINTEREST_ID]: {
        id: PINTEREST_ID,
        name: 'Pinterest',
        parentCompanyName: 'Pinterest',
        position: 7,
        slug: PINTEREST_SLUG
    }
};
export const NETWORK_INFO_BY_SLUG = {
    [YOUTUBE_SLUG]: {
        id: YOUTUBE_ID,
        name: 'YouTube',
        position: 1,
        slug: YOUTUBE_SLUG
    },
    [TWITTER_SLUG]: {
        id: TWITTER_ID,
        name: 'Twitter',
        position: 2,
        slug: TWITTER_SLUG
    },
    [INSTAGRAM_SLUG]: {
        id: INSTAGRAM_ID,
        name: 'Instagram',
        position: 3,
        slug: INSTAGRAM_SLUG
    },
    [FACEBOOK_SLUG]: {
        id: FACEBOOK_ID,
        name: 'Facebook',
        position: 4,
        slug: FACEBOOK_SLUG
    },
    [TWITCH_SLUG]: {
        id: TWITCH_ID,
        name: 'Twitch',
        position: 5,
        slug: TWITCH_SLUG
    },
    [TIKTOK_SLUG]: {
        id: TIKTOK_ID,
        name: 'TikTok',
        position: 6,
        slug: TIKTOK_SLUG
    },
    [PINTEREST_SLUG]: {
        id: PINTEREST_ID,
        name: 'Pinterest',
        position: 7,
        slug: PINTEREST_SLUG
    }
};
/**
 * Available Networks
 */
export const AVAILABLE_NETWORKS = [
    NETWORK_INFO_BY_ID[INSTAGRAM_ID],
    NETWORK_INFO_BY_ID[TIKTOK_ID],
    NETWORK_INFO_BY_ID[FACEBOOK_ID],
    NETWORK_INFO_BY_ID[TWITTER_ID],
    NETWORK_INFO_BY_ID[YOUTUBE_ID],
    NETWORK_INFO_BY_ID[PINTEREST_ID],
    NETWORK_INFO_BY_ID[TWITCH_ID]
];
export const TYPE_INSTAGRAM_POST = 'instagram_post';
export const TYPE_INSTAGRAM_REEL = 'instagram_reel';
export const TYPE_INSTAGRAM_STORY = 'instagram_story';
export const TYPE_TIKTOK_VIDEO = 'tiktok_video';
export const TYPE_YOUTUBE_VIDEO = 'youtube_video';
export const TYPE_TWITCH_VIDEO = 'twitch_video';
export const TYPE_FACEBOOK_POST = 'facebook_post';
export const TYPE_TWITTER_POST = 'twitter_post';
export const TYPE_PINTEREST_POST = 'pinterest_post';
export const TYPE_CUSTOM = 'custom';
export const META_UPLOADED_CONTENT_DELIVERY_NETWORKS = [TYPE_INSTAGRAM_POST, TYPE_FACEBOOK_POST, TYPE_INSTAGRAM_REEL];
export const META_UPLOADED_CONTENT_DELIVERY_NETWORK_SLUGS = [INSTAGRAM_SLUG, FACEBOOK_SLUG];
export const NETWORK_TYPE_MAP = {
    [TYPE_INSTAGRAM_POST]: INSTAGRAM_SLUG,
    [TYPE_INSTAGRAM_REEL]: INSTAGRAM_SLUG,
    [TYPE_INSTAGRAM_STORY]: INSTAGRAM_SLUG,
    [TYPE_TIKTOK_VIDEO]: TIKTOK_SLUG,
    [TYPE_YOUTUBE_VIDEO]: YOUTUBE_SLUG,
    [TYPE_TWITCH_VIDEO]: TWITCH_SLUG,
    [TYPE_FACEBOOK_POST]: FACEBOOK_SLUG,
    [TYPE_TWITTER_POST]: TWITTER_SLUG,
    [TYPE_PINTEREST_POST]: PINTEREST_SLUG
};
export const NETWORK_LABEL_MAP = {
    [TYPE_INSTAGRAM_POST]: 'Instagram Post',
    [TYPE_INSTAGRAM_REEL]: 'Instagram Reel',
    [TYPE_INSTAGRAM_STORY]: 'Instagram Story',
    [TYPE_TIKTOK_VIDEO]: 'TikTok',
    [TYPE_YOUTUBE_VIDEO]: 'Youtube',
    [TYPE_TWITCH_VIDEO]: 'Twitch',
    [TYPE_FACEBOOK_POST]: 'Facebook',
    [TYPE_TWITTER_POST]: 'Twitter',
    [TYPE_PINTEREST_POST]: 'Pinterest',
    [TYPE_CUSTOM]: 'Custom Task'
};
export const NETWORK_INFO_MAP = {
    [TYPE_INSTAGRAM_POST]: {
        id: INSTAGRAM_ID,
        name: 'Instagram',
        slug: INSTAGRAM_SLUG,
        type: TYPE_INSTAGRAM_POST
    },
    [TYPE_INSTAGRAM_REEL]: {
        id: INSTAGRAM_ID,
        name: 'Instagram',
        slug: INSTAGRAM_SLUG,
        type: TYPE_INSTAGRAM_REEL
    },
    [TYPE_INSTAGRAM_STORY]: {
        id: INSTAGRAM_ID,
        name: 'Instagram',
        slug: INSTAGRAM_SLUG,
        type: TYPE_INSTAGRAM_STORY
    },
    [TYPE_TIKTOK_VIDEO]: {
        id: TIKTOK_ID,
        name: 'TikTok',
        slug: TIKTOK_SLUG,
        type: TYPE_TIKTOK_VIDEO
    },
    [TYPE_YOUTUBE_VIDEO]: {
        id: YOUTUBE_ID,
        name: 'Youtube',
        slug: YOUTUBE_SLUG,
        type: TYPE_YOUTUBE_VIDEO
    },
    [TYPE_TWITCH_VIDEO]: {
        id: TWITCH_ID,
        name: 'Twitch',
        slug: TWITCH_SLUG,
        type: TYPE_TWITCH_VIDEO
    },
    [TYPE_FACEBOOK_POST]: {
        id: FACEBOOK_ID,
        name: 'Facebook',
        slug: FACEBOOK_SLUG,
        type: TYPE_FACEBOOK_POST
    },
    [TYPE_TWITTER_POST]: {
        id: TWITTER_ID,
        name: 'Twitter',
        slug: TWITTER_SLUG,
        type: TYPE_TWITTER_POST
    },
    [TYPE_PINTEREST_POST]: {
        id: PINTEREST_ID,
        name: 'Pinterest',
        slug: PINTEREST_SLUG,
        type: TYPE_PINTEREST_POST
    }
};
/**
 * Network Connection Info - Username Page - 1st party
 */
export const NETWORK_CONNECTION_INFO_BY_ID = {
    [INSTAGRAM_ID]: {
        title: 'Connect a Professional Instagram account',
        logo: INSTAGRAM_CONNECT_LOGO,
        url: 'https://www.instagram.com/',
        placeholder: 'Enter your Instagram @handle',
        cta: 'Continue',
        error: ''
    },
    [TIKTOK_ID]: {
        title: 'Connect a TikTok account',
        logo: TIKTOK_CONNECT_LOGO,
        url: 'https://www.tiktok.com/@',
        placeholder: 'Enter your TikTok @username',
        cta: 'Continue',
        error: ''
    },
    [PINTEREST_ID]: {
        title: 'Connect a Business Pinterest account',
        logo: PINTEREST_CONNECT_LOGO,
        url: 'https://www.pinterest.com/',
        placeholder: 'Enter your Pinterest @username',
        cta: 'Continue',
        error: ''
    }
};
/**
 * Network Connection Info - Username Page - 3rd party
 */
export const THIRD_PARTY_NETWORK_CONNECTION_INFO_BY_ID = {
    [INSTAGRAM_ID]: {
        title: 'Connect an Instagram account',
        logo: INSTAGRAM_CONNECT_LOGO,
        url: 'https://www.instagram.com/',
        placeholder: 'Enter your Instagram username',
        cta: 'Connect',
        error: 'There was a problem connecting your account. Please make sure the username is valid and your account is public.'
    },
    [FACEBOOK_ID]: {
        title: 'Connect a Facebook account',
        logo: FACEBOOK_CONNECT_LOGO,
        url: 'https://www.facebook.com/',
        placeholder: 'Enter your Facebook username',
        cta: 'Connect',
        error: 'There was a problem connecting your account. Please make sure the username is valid and your account is public.'
    }
};
/**
 * Network marketplace names
 */
export const NETWORK_MARKETPLACE_NAMES = {
    [TIKTOK_ID]: 'TikTok Creator Marketplace'
};
