import md5 from 'blueimp-md5';

const googleAnalyticsDriver = () => {
    /**
     * Array of PII event parameters to hash
     */
    const hashedKeys = ['email'];

    /**
     * Track an event.
     *
     * @param {string}  event       - event constant
     * @param {Object}  properties  - key/values to save with event
     */
    const trackEvent = (event, properties = {}) => {
        if (window.dataLayer) {
            try {
                hashedKeys.forEach((key) => {
                    if (properties[key]) {
                        properties[key] = md5(properties[key]);
                    }
                });

                dataLayer.push({
                    ...properties,
                    event: event
                });
            } catch (e) {
                console.warn(`Failed to track GA event ${event}`);
            }
        }
    };

    /**
     * Check if event supported by the service.
     *
     * @param {string}  event       - event constant
     * @param {Object}  properties  - key/values to save with event
     */
    const isSupportedEvent = (event, properties = {}) => {
        return true;
    };

    return {
        trackEvent,
        isSupportedEvent
    };
};

export default googleAnalyticsDriver;
