<template>
    <div>
        <products-compensation-list-item
            v-for="(product, index) in selectedProducts"
            :key="`${index}-${product.id}`"
            :product="product"
            :show-prices="!!settings.show_prices"
            :currency="campaign.brand.currency"
            :allow-delete="true"
            :read-only="true"
            @delete="removeProduct(index)"
        />

        <div
            v-if="!settings.max_products || selectedProducts.length < settings.max_products"
            class="clickable-block success-block media mt-15"
            @click="showDialog"
        >
            <div class="media-content">
                <strong>Select Product</strong>
            </div>

            <div class="media-right">
                <span class="icon"><i class="far fa-plus"></i></span>
            </div>
        </div>

        <p v-if="settings.show_prices && settings.max_product_price" class="mt-10">
            Remaining Balance:
            <strong :class="{'has-text-green': (remaining_funds >= 0), 'has-text-red': (remaining_funds < 0)}">
                <money-display :value="remaining_funds.toFixed(2)" :currency="campaign.brand.currency" />
            </strong>
        </p>

        <products-compensation-dialog
            v-if="show_dialog"
            :campaign-id="campaign.id"
            :proposal-id="(proposal ? proposal.id : null)"
            :restriction="current_restriction"
            :show-prices="!!settings.show_prices"
            :currency="campaign.brand.currency"
            @add="addProduct"
            @close="hideDialog"
        />
    </div>
</template>

<style>
    .clickable-block {
        cursor: pointer;
    }
</style>

<script>
    import _ from 'lodash';
    import MoneyDisplay from '../../../../interface/elements/MoneyDisplay.vue';
    import ProductsCompensationDialog from './ProductsCompensationDialog.vue';
    import ProductsCompensationListItem from './ProductsCompensationListItem.vue';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'ProductsCompensationWizard',

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            MoneyDisplay,
            ProductsCompensationDialog,
            ProductsCompensationListItem
        },

        /**
         * The component props
         *
         * @type {Object}
         */
        props: {
            /**
             * The campaign
             */
            campaign: {
                type:     Object,
                required: true
            },

            /**
             * A deal's proposal
             */
            proposal: Object,

            /**
             * Selected products
             */
            products: Array
        },

        /**
         * The component data
         *
         * @returns {Object}
         */
        data() {
            return {
                /**
                 * The pool of restrictions
                 *
                 * @type {Array}
                 */
                restrictions: [],

                /**
                 * The selected products
                 *
                 * @type {Array}
                 */
                selectedProducts: [],

                /**
                 * Whether or not to show the dialog
                 *
                 * @type {Boolean}
                 */
                show_dialog: false,

                /**
                 * The restriction currently used by the dialog
                 *
                 * @type {Object}
                 */
                current_restriction: null
            };
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The compensation settings
             *
             * @returns {Object}
             */
            settings() {
                return (this.proposal ? this.proposal.compensation_settings : this.campaign);
            },

            /**
             * The remaining funds an influencer can use
             *
             * @returns {Number|null}
             */
            remaining_funds() {
                if (!this.settings.show_prices || !this.settings.max_product_price) {
                    return null;
                }

                return this.selectedProducts.reduce(
                    (total, product) => total - this.getProductPrice(product),
                    parseFloat(this.settings.max_product_price)
                );
            }
        },

        /**
         * The component methods
         *
         * @type {Object}
         */
        methods: {
            /**
             * Return a product's price or selected variant's price
             *
             * @param {Object} product
             * @returns {Number}
             */
            getProductPrice(product) {
                if (!product.selected_options || !product.selected_options.length) {
                    return parseFloat(product.price);
                }

                let price = null;

                for (let i in product.variants) {
                    const variant = product.variants[i];

                    price = variant.price;

                    for (let j in variant.option_values) {
                        const value_id = variant.option_values[j].pivot.option_value_id;

                        if (_.findIndex(product.selected_options, { value_id }) === -1) {
                            price = null;
                            break;
                        }
                    }

                    if (price !== null) {
                        break;
                    }
                }

                return (price ? parseFloat(price) : 0);
            },

            /**
             * Build the restrictions pool after creation the component
             */
            setRestrictions() {
                let restrictions = [];

                if (this.settings.restrict_products) {
                    this.settings.product_restrictions.forEach(restriction => {
                        restrictions.push(restriction);
                    });
                }

                this.restrictions = restrictions;
            },

            /**
             * Show the product selection dialog
             */
            showDialog() {
                this.current_restriction = this.restrictions.shift() || null;
                this.show_dialog         = true;
            },

            /**
             * Hide the product selection dialog
             */
            hideDialog() {
                if (this.current_restriction) {
                    this.restrictions.unshift(this.current_restriction);
                }

                this.show_dialog         = false;
                this.current_restriction = null;
            },

            /**
             * Set the initial products
             */
            setSelectedProducts() {
                if (this.products) {
                    this.selectedProducts = _.clone(this.products);

                    this.handleChange();
                }
            },

            /**
             * Add the selected product
             *
             * @param {Object} product
             * @param {Array}  options
             */
            addProduct(product, options) {
                product.selected_options = options;
                product.restriction      = this.current_restriction;

                this.selectedProducts.push(product);

                this.handleChange();

                this.show_dialog         = false;
                this.current_restriction = null;
            },

            /**
             * Remove a product
             *
             * @param {Number} index
             */
            removeProduct(index) {
                const product = this.selectedProducts[index];

                if (product.restriction) {
                    this.restrictions.unshift(product.restriction);
                }

                this.selectedProducts.splice(index, 1);

                this.handleChange();
            },

            /**
             * Handle the "change" event
             */
            handleChange() {
                this.$emit('change', this.selectedProducts.map(product => ({
                    id:          product.id,
                    options:     product.selected_options,
                    restriction: product.restriction
                })));
            }
        },

        /**
         * When the component is first created
         */
        created() {
            this.setRestrictions();
            this.setSelectedProducts();
        }
    }
</script>
