<template>
    <el-date-picker class="is-fullwidth" value-format="yyyy-MM-dd" format="MMM dd yyyy"
                    v-model="model" type="date" size="small" placeholder="Date">
    </el-date-picker>
</template>
<script>
    import FieldInputMixin from './FieldInputMixin';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'DatePickerInput',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            FieldInputMixin
        ],

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {

            /**
             * Get the model value
             *
             * @returns {string}
             */
            getValue() {
                return this.model ? this.model : null;
            }
        }
    }
</script>

