import { get } from 'lodash';
import { defineStore } from 'pinia';
import { useContactStore } from './index';
import useHttp from '../src/composables/use-http';

const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            data: get(window.SERVER_DATA, 'auth', null),
            loading: false,
            error: null
        };
    },
    getters: {
        isLoggedIn(state) {
            const contact = useContactStore().data;
            return state.data === contact.id;
        }
    },
    actions: {
        async checkAuth() {
            const contact = useContactStore().data;
            if (contact.id) {
                const { http } = useHttp();
                this.loading = true;

                try {
                    const response = await http.get(`/contact/${contact.id}/login/status`);
                    this.data = response.data;
                } catch (e) {
                    this.data = null;
                    this.error = 'Sorry, there was an authentication error.';
                }

                this.loading = false;
            }
        }
    }
});

export default useAuthStore;
