<template>
    <div>
        <div v-for="option in orderedOptions" :key="option.id">
            <el-checkbox v-model="model" :label="option.value">
                {{ option.value }}
            </el-checkbox>
        </div>
    </div>
</template>
<script>
    import FieldInputMixin from './FieldInputMixin';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'MultipleCheckboxesInput',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            FieldInputMixin
        ],

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {

                /**
                 * The default model
                 *
                 * @type {string|null}
                 */
                default_model: []
            }
        }
    }
</script>

