<template>
    <div>
        <g-loader v-if="creatorStore.isLoading" />
        <div v-else>
            <div style="margin-bottom: 0.5rem">
                <g-typography
                    v-if="errorMessage"
                    tag="div"
                    color="error"
                    :value="errorMessage"
                    class="margin-bottom-normal"
                />
            </div>

            <network-connection-info
                v-if="displayMode === 'network-connection-info'"
                :context="context"
                :contact-id="contactId"
                :network-id="displayNetworkId"
                :instagram-graph-required="fullConnection || instagramGraphRequired"
                :failure-page="failurePage"
                @show-instagram-faq="setDisplayMode('ig-connection-faq')"
                @go-back="setDisplayMode('list-networks')"
                @reset-failure-page="handleResetFailurePage"
                @popup-closed="handlePopupClosed"
            />
            <ig-connection-faq
                v-else-if="displayMode === 'ig-connection-faq'"
                :context="context"
                @go-back="setDisplayMode('network-connection-info')"
            />
            <shared-networks
                v-else-if="displayMode === 'shared-networks'"
                :network-id="sharedNetworks.networkId"
                :social-network-id="sharedNetworks.socialNetworkId"
                :contact-id="contactId"
                :host="host"
                @back="handleCloseSharedNetworks"
                @proceed-to-connect="proceedToConnect"
                @popup-closed="handlePopupClosed"
                @success="handleShareNetworkSuccess"
            />
            <template v-else>
                <div v-for="network in networks" :key="network.id" class="margin-bottom-normal">
                    <contact-network-item
                        v-if="isConnected(network)"
                        :contact-network="getConnectedContactNetwork(network)"
                        :context="context"
                        :required="isRequired(network)"
                        @connect-instagram="showNetworkConnection(INSTAGRAM_ID)"
                        @disconnect-network="disconnectNetwork"
                    />
                    <float-on-mask v-else tag="div" :float-up="readonly">
                        <connect-network-item
                            :network="network"
                            :contact-network="getContactNetwork(network.id)"
                            :contact-id="contactId"
                            :context="context"
                            :required="isRequired(network)"
                            :is-landing-page="isLandingPage"
                            :proceed-to-connect="proceedToConnectProp"
                            @load-contact="reloadContact"
                            @popup-closed="handlePopupClosed"
                            @network-connection-info="handleNetworkConnect"
                            @show-failure-page="handleFailure"
                            @show-shared-networks="handleShowSharedNetworks"
                            @close-shared-networks="handleCloseSharedNetworks"
                            @clear-proceed-to-connect="clearProceedToConnect"
                        />
                    </float-on-mask>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import ContactNetworkItem from './ContactNetworkItem.vue';
    import ConnectNetworkItem from './ConnectNetworkItem.vue';
    import FloatOnMask from '../../../library/FloatOnMask.vue';
    import {
        INSTAGRAM_ID,
        INSTAGRAM_SLUG,
        PINTEREST_ID,
        PINTEREST_SLUG,
        TIKTOK_ID,
        TIKTOK_SLUG,
        FACEBOOK_ID,
        FACEBOOK_SLUG,
        NETWORK_INFO_BY_ID,
        NETWORK_INFO_BY_SLUG
    } from '../../../../constants/networks';
    import { getMeta } from '../../../../../src/helpers';
    import IgConnectionFaq from './IgConnectionFaq.vue';
    import NetworkConnectionInfo from './NetworkConnectionInfo.vue';
    import NetworkConnectionMixin from '../../../../mixins/NetworkConnectionMixin';
    import { get } from 'lodash';
    import useNetworks from '../../../../../src/composables/use-networks';
    import SharedNetworks from '../../../../../app-v2/components/data-views/sharedNetworks/SharedNetworks.vue';
    import { useCreatorStore } from '../../../../../stores';

    export default {
        name: 'NetworkManager',
        components: {
            IgConnectionFaq,
            NetworkConnectionInfo,
            FloatOnMask,
            ConnectNetworkItem,
            ContactNetworkItem,
            SharedNetworks
        },
        mixins: [NetworkConnectionMixin],
        props: {
            contactId: {
                type: String,
                required: true
            },
            context: {
                type: String,
                required: true
            },
            networks: {
                type: Array,
                default() {
                    return [];
                }
            },
            contactNetworks: {
                type: Array,
                default() {
                    return [];
                }
            },
            requiredNetworks: {
                type: Array,
                default() {
                    return [];
                }
            },
            tasks: {
                type: Array,
                default() {
                    return [];
                }
            },
            readonly: {
                type: Boolean,
                default: false
            },
            isLandingPage: {
                type: Boolean,
                default: false
            },
            proceedToConnectCampaignsProp: {
                type: Object,
                default: () => ({
                    value: false,
                    networkId: 0
                }),
                required: false
            }
        },
        data() {
            const creatorStore = useCreatorStore();
            return {
                errorMessage: null,
                fullConnection: false,
                displayMode: 'list-networks',
                displayNetworkId: null,
                failurePage: null,
                creatorStore,
                sharedNetworks: {
                    networkId: null,
                    socialNetworkId: null
                },
                proceedToConnectProp: {
                    value: false,
                    networkId: 0
                }
            };
        },
        computed: {
            /**
             * Whether an Instagram creator/business connection is required
             *
             * @returns {boolean}
             */
            instagramGraphRequired() {
                return this.tasks && this.tasks.filter((task) => this.taskRequiresInstagramOauth(task)).length > 0;
            },
            host() {
                return getMeta('routing-domain');
            },
        },
        methods: {
            isConnected(network) {
                return !!this.getConnectedContactNetwork(network);
            },

            isRequired(network) {
                return !!this.requiredNetworks.find((n) => {
                    return n.slug === network.slug;
                });
            },

            getConnectedContactNetwork(network) {
                return useNetworks().getConnectedContactNetworkByIdFromContactNetworks(
                    network?.id,
                    this.contactNetworks
                );
            },

            getContactNetwork(networkId) {
                return useNetworks().getContactNetworkByIdFromContactNetworks(networkId, this.contactNetworks);
            },

            /**
             * Whether the given task requires Instagram creator/business connection
             *
             * @returns {boolean}
             */
            taskRequiresInstagramOauth(task) {
                return (
                    task.network_id === INSTAGRAM_ID &&
                    (task.media_type === 'story' || task.media_type === 'reel') &&
                    task.requires_oauth
                );
            },

            /**
             * Show the instagram failure page
             */
            handleFailure(failurePage, networkId) {
                this.failurePage = failurePage;
                this.displayNetworkId = networkId;
                this.setDisplayMode('network-connection-info');
            },

            handleResetFailurePage() {
                this.failurePage = null;
            },

            /**
             * Show the network connection - opens flow to username page
             */
            showNetworkConnection(networkId) {
                this.fullConnection = true;

                const contactNetwork = networkId ? this.getContactNetwork(networkId) : null;
                const username = contactNetwork?.network_username || '';

                if (this.context === 'proposal') {
                    return this.$router.push({
                        name: `v2.campaign.proposal.networks.${NETWORK_INFO_BY_ID[networkId]?.slug}`,
                        query: {
                            stories: true,
                            username
                        }
                    });
                }

                if (this.$route) {
                    this.$route.query.username = username;
                }

                this.$emit('start-account-connection');
                this.displayNetworkId = networkId;
                this.setDisplayMode('network-connection-info');
            },

            /**
             * Disconnect the given network
             *
             * @param {object} network
             */
            disconnectNetwork(network) {
                const slug = network.network.slug;
                this.$root.loaderStart(`network.${slug}.disconnect`);
                this.$http.get(`/contact/${this.contactId}/networks/${network.id}/remove`).then(
                    () => {
                        this.$root.loaderStop(`network.${slug}.disconnect`);
                        this.reloadContact();
                        this.reloadSharedNetworks();
                    },
                    () => {
                        this.$root.loaderStop(`network.${slug}.disconnect`);
                        this.errorMessage = 'Could not remove network, please try again or contact support.';
                    }
                );
            },
            async reloadContact() {
                if (this.context === 'proposal') {
                    this.$emit('load-contact');
                } else {
                    await this.$root.reloadContact('networks');
                }
            },
            async reloadSharedNetworks() {
                await this.creatorStore.getAllLiveUrls();
                await this.creatorStore.getAllSocialNetworks();
            },
            async handlePopupClosed(networkId) {
                await this.reloadContact();
                await this.reloadSharedNetworks();

                const socialNetwork = this.creatorStore.getCurrentLiveUrlSocialNetwork(networkId);
                if (useNetworks().showSharedNetworkPostConnect(socialNetwork, this.$root.networks, this.isLandingPage)) {
                    this.sharedNetworks.socialNetworkId = socialNetwork.id;
                    this.sharedNetworks.networkId = networkId;
                    this.displayMode = 'shared-networks';
                    this.$emit('show-shared-networks', networkId, socialNetwork.id);
                } else {
                    this.handlePopupClosedRedirect();
                }
            },
            handleNetworkConnect(networkId) {
                this.displayNetworkId = networkId;
                this.sharedNetworks.networkId = networkId;
                this.$emit('start-account-connection');
                this.displayMode = 'network-connection-info';
            },
            setDisplayMode(displayMode) {
                if (
                    [INSTAGRAM_ID, FACEBOOK_ID, TIKTOK_ID, PINTEREST_ID].includes(this.displayNetworkId) &&
                    displayMode !== 'network-connection-info'
                ) {
                    this.$emit('finish-account-connection');
                }

                this.displayMode = displayMode;
            },
            handleShowSharedNetworks(networkId, socialNetworkId) {
                this.sharedNetworks.networkId = networkId;
                this.sharedNetworks.socialNetworkId = socialNetworkId;
                this.displayMode = 'shared-networks';
                this.$emit('show-shared-networks', networkId, socialNetworkId);
            },
            handleCloseSharedNetworks() {
                this.displayMode = 'list-networks';
                this.$emit('close-shared-networks');
            },
            proceedToConnect(networkId) {
                this.displayMode = 'list-networks';
                this.proceedToConnectProp = {
                    value: true,
                    networkId: networkId
                }
            },
            clearProceedToConnect() {
                this.proceedToConnectProp = {
                    value: false,
                    networkId: 0
                }
                this.$emit('clear-proceed-to-connect');
            },
            handleShareNetworkSuccess() {
                this.handleCloseSharedNetworks();
                this.reloadContact();
                this.reloadSharedNetworks();
            },
            /**
             * Redirects the user after the popup is closed in certain cases
             */
            handlePopupClosedRedirect() {
                // Redirect to Deliverable Delivery Page - Content Approval
                if (
                    this.$router?.history?.current.query.briefId &&
                    this.$router?.history?.current.query.deliverableId &&
                    this.$router?.history?.current.query.taskId
                ) {
                    this.$router.push({
                        name: 'v2.activation.brief.task.deliverable.delivery',
                        params: {
                            brief_id: this.$router?.history?.current.query.briefId,
                            deliverable_id: this.$router?.history?.current.query.deliverableId,
                            task_id: this.$router?.history?.current.query.taskId
                        }
                    });
                }
            }
        },
        created() {
            const networkSlug = this.$route ? get(this.$route, 'query.network', null) : null;
            if ([INSTAGRAM_SLUG, FACEBOOK_SLUG, TIKTOK_SLUG, PINTEREST_SLUG].includes(networkSlug)) {
                this.showNetworkConnection(NETWORK_INFO_BY_SLUG[networkSlug]?.id);
            }

            if (this.context !== 'proposal') {
                this.reloadSharedNetworks();
            }

            if (this.proceedToConnectCampaignsProp?.value === true) {
                this.proceedToConnectProp = this.proceedToConnectCampaignsProp
            }
        }
    };
</script>

<style lang="scss" scoped>
    .margin-bottom-normal {
        margin-bottom: $g-spacing-5;
    }
</style>
