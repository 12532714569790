import { defineStore } from 'pinia';

const useFeatureFlagsStore = defineStore('feature-flags', {
    state: () => {
        return {
            data: window.SERVER_DATA?.feature_flags || {}
        };
    }
});

export default useFeatureFlagsStore;
