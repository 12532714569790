<template>
    <div :class="boxClass" v-on="$listeners">
        <slot name="content">{{ content }}</slot>
    </div>
</template>
<script>
    export default {
        name: "BaseBox",
        props: {
            padding: {
                type: String,
                default: 'normal',
            },
            border: {
                type: Boolean,
                default: true,
            },
            borderStyle: {
                type: String,
                default: 'solid',
            },
            borderColor: {
                type: String,
                default: 'default',
            },
            borderRadius: {
                type: String,
                default: 'default',
            },
            backgroundColor: {
                type: String,
                default: 'white',
            },
            content: {
                type: String,
            },
            clickable: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            boxClass() {
                return {
                    'base-box': true,
                    'base-box-border': this.border,
                    [`base-box-border-style-${this.borderStyle}`]: this.border,
                    [`base-box-padding-${this.padding}`]: true,
                    [`base-box-border-color-${this.borderColor}`]: true,
                    [`base-box-border-radius-${this.borderRadius}`]: true,
                    [`base-box-background-color-${this.backgroundColor}`]: true,
                    'clickable':  this.clickable,
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .base-box {
        overflow: auto;
    }
    .base-box-padding-none {
        padding: 0;
    }
    .base-box-padding-small {
        padding: $g-spacing-4;
    }
    .base-box-padding-normal {
        padding: $g-spacing-5;
    }
    .base-box-padding-large {
        padding: $g-spacing-6;
    }
    .base-box-border {
        border-width: 1px;
    }
    .base-box-border-style-solid {
        border-style: solid;
    }
    .base-box-border-style-dotted {
        border-style: dotted;
    }
    .base-box-border-style-dashed {
        border-style: dashed;
    }
    .base-box-border-color-default {
        border-color: $g-color-grey-4;
    }
    .base-box-border-color-red {
        border-color: $g-color-red-5;
    }
    .base-box-border-color-green {
        border-color: $g-color-green-7;
    }
    .base-box-border-radius-small {
        border-radius: 2px;
    }
    .base-box-border-radius-default {
        border-radius: 4px;
    }
    .base-box-border-radius-large {
        border-radius: 6px;
    }
    .base-box-background-color-inherit {
        background-color: inherit;
    }
    .base-box-background-color-transparent {
        background-color: transparent;
    }
    .base-box-background-color-white {
        background-color: $g-color-white;
    }
    .base-box-background-color-grey {
        background-color: $g-color-grey-2;
    }
    .base-box-background-color-blue {
        background-color: #03b8ec;
    }
    .base-box-background-color-green {
        background-color: #D1F0CF;
    }
    .clickable:hover {
        cursor: pointer;
    }
</style>
