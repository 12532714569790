<template>
    <div class="mt-20">
        <div class="mb-10 text-bold">
            Your Products
            <span v-if="campaign.show_prices && proposal.products.length">
                - <span class="has-text-green"><money-display :value="$parent.total_product_price" :currency="campaign.brand.currency" /></span>
            </span>
        </div>

        <div v-if="proposal.products.length">
            <article class="media mt-15" v-for="(product, index) in proposal.products" :key='product.id'>
                <figure class="media-left">
                    <p class="image is-64x64 product-image" :style="`background-image: url(${product.image})`"></p>
                </figure>
                <div class="media-content">
                    <div class="content">
                        <div class="columns">
                            <div class="column">
                                <p>
                                    <strong>{{ product.name }}</strong>
                                    <span v-if="product.options">
                                        (<span v-for="(opt, index) in product.options" :key='opt.id'>
                                            {{ productOptionLabel(product.id, opt.option_id, opt.value_id) }} <span v-if="index !== product.options.length - 1">/</span>
                                        </span>)
                                    </span>
                                    <br>
                                    <span v-if="campaign.show_prices" class="has-text-green">
                                        <money-display :value="product.price.toFixed(2)" :currency="campaign.brand.currency" />
                                    </span>
                                </p>
                            </div>
                            <div class="column is-narrow">
                                <a class="delete" @click.prevent="removeProduct(product, index)"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        <div class="has-text-orange" v-else>You have not selected any products yet</div>
    </div>
</template>
<script>
    import _ from 'lodash';
    import CampaignProposalMixin from '../CampaignProposalMixin';
    import MoneyDisplay from '../../../../interface/elements/MoneyDisplay.vue';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'SelectedProducts',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {},

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            CampaignProposalMixin
        ],

        /**
         * The child components
         *
         * @type {object}
         */
        components: {
            MoneyDisplay
        },

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {}
        },

        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {

            /**
             * Find the product option label
             *
             * @param {string} product_id
             * @param {number} option_id
             * @param {number} value_id
             * @returns {string}
             */
            productOptionLabel(product_id, option_id, value_id) {
                let product = _.find(this.campaign.products, { id: product_id });
                let option = _.find(product.options, { id: option_id });
                let value = _.find(option.values, { id: value_id });
                return value.name;
            },

            /**
             * Remove a product from the proposal
             *
             * @param {object} product
             * @param {number} index
             */
            removeProduct(product, index) {
                let campaign_product = _.find(this.campaign.products, {id: product.id });
                this.$emit('remove_product', index);
                if(!this.campaign.select_products && campaign_product.pivot.select_options) {
                    this.selectProduct(campaign_product);
                }
            },
        }
    }
</script>