<template>
    <component
        :is="tag"
        :class="classes"
    >
        <slot/>
    </component>
</template>

<script>
    /**
     * Use this component to wrap elements that must "float" above the mask blocking
     * interactions for readonly PageLayout
     */
    export default {
        name: "FloatOnMask",
        props: {
            /**
             * The tag to use
             */
            tag: {
                type: String,
                default: 'span',
            },
            /**
             * Whether to float up
             */
            floatUp: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            classes() {
                return {
                    'float-on-mask': this.floatUp,
                };
            }
        },
    }
</script>

<style lang="scss" scoped>
    .float-on-mask {
        position: relative;
        z-index: 1002;
    }
</style>