import { get, find } from 'lodash';
import SingleLineTextInput from '../fields/SingleLineTextInput.vue';
import DatePickerInput from '../fields/DatePickerInput.vue';
import MultiLineTextInput from '../fields/MultiLineTextInput.vue';
import MultipleCheckboxesInput from '../fields/MultipleCheckboxesInput.vue';
import NumberInput from '../fields/NumberInput.vue';
import RadioSelectInput from '../fields/RadioSelectInput.vue';
import SingleCheckboxInput from '../fields/SingleCheckboxInput.vue';
import DropdownSelectInput from '../fields/DropdownSelectInput.vue';

export default {
    /**
     * The child components
     *
     * @type {object}
     */
    components: {
        DropdownSelectInput,
        SingleCheckboxInput,
        RadioSelectInput,
        NumberInput,
        MultipleCheckboxesInput,
        MultiLineTextInput,
        DatePickerInput,
        SingleLineTextInput
    },

    /**
     * THe computed properties
     *
     * @type {object}
     */
    computed: {
        /**
         * Whether the account has custom fields
         *
         * @type {boolean}
         */
        has_custom_fields() {
            return this.fields && this.fields.length;
        },

        /**
         * Get the custom field ids
         *
         * @returns {array}
         */
        custom_field_ids() {
            return this.page_fields
                .filter((field) => {
                    return field && field.id.startsWith('field_');
                })
                .map((field) => parseInt(field.id.replace('field_', '')));
        },

        /**
         * The custom fields for the application form
         *
         * @returns {array}
         */
        custom_fields() {
            if (this.has_custom_fields) {
                return this.custom_field_ids
                    .filter((id) => {
                        return find(this.fields, { id });
                    })
                    .map((id) => {
                        return find(this.fields, { id });
                    });
            }
            return [];
        },

        /**
         * Required fields
         *
         * @returns {array}
         */
        required_field_ids() {
            const baseFields = ['first_name', 'last_name', 'email'];

            if (this.page_fields.length) {
                return baseFields.concat(
                    this.page_fields.filter((pageField) => pageField.required)?.map((field) => field.id) || []
                );
            }

            return baseFields;
        }
    },

    /**
     * The component methods
     *
     * @type {object}
     */
    methods: {
        /**
         * Whether or not the field exists
         *
         * @returns {boolean}
         */
        hasField(id) {
            return !!this.getPageField(id);
        },

        /**
         * Whether or not the field is required
         *
         * @returns {boolean}
         */
        pageFieldIsRequired(id) {
            const field = this.getPageField(id);
            return field?.required || false;
        },

        /**
         * Get the page field by id
         *
         * @returns {boolean}
         */
        getPageField(id) {
            return this.page_fields && find(this.page_fields, { id });
        },

        /**
         * Get the field value for the current contact
         *
         * @param {object} field
         * @returns {number|array|string|null}
         */
        getFieldValue(field) {
            if (this.$root.contact.properties && this.$root.contact.properties.length) {
                const column = `${this.getFieldValueColumn(field.type)}_value`;
                const contact_property = find(this.$root.contact.properties, { field_id: field.id });
                if (contact_property) {
                    return contact_property[column];
                }
            }

            return null;
        },

        /**
         * Find the label of a field by its id
         *
         * @param   {string} id
         * @returns {string}
         */
        findLabel(id) {
            return this.isCustomField(id) ? this.findCustomLabel(id) : this.findDefaultLabel(id);
        },

        /**
         * The custom fields have the ID in the format `field_<id>`, while
         * default ids are plain strings.
         *
         * @param {String} id
         * @returns {Boolean}
         */
        isCustomField(id) {
            return id.indexOf('field_') >= 0;
        },

        /**
         * Find the label for a custom field
         *
         * @param {string} field_id
         * @returns {string}
         */
        findCustomLabel(field_id) {
            const field = find(this.fields, {
                id: parseInt(field_id.replace('field_', ''))
            });

            return get(field, 'label', '');
        },

        /**
         * Find the label of a default field
         *
         * @param {string} field_id
         * @returns {string}
         */
        findDefaultLabel(field_id) {
            return field_id.replace('_', ' ');
        },

        /**
         * Get the field value column
         *
         * @param {string} type
         * @returns {string}
         */
        getFieldValueColumn(type) {
            switch (type) {
                case 'Single-line text':
                case 'Dropdown select':
                case 'Radio select':
                    return 'string';
                case 'Single checkbox':
                case 'Number':
                    return 'number';
                case 'Multi-line text':
                    return 'text';
                case 'Date picker':
                    return 'date';
                case 'Multiple checkboxes':
                    return 'json';
            }
        }
    }
};
