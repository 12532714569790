/**
 * Import the global components
 */
import Field from '../components/interface/form/FormField.vue';
import Logo from '../components/interface/assets/Logo.vue';
import Notice from '../components/interface/elements/Notice.vue';
import GrinGuy from '../components/interface/assets/GrinGuy.vue';
import Loader from '../components/interface/elements/Loader.vue';
import StatusTag from '../components/interface/elements/StatusTag.vue';
import NetworkIcon from '../components/interface/elements/NetworkIcon.vue';
import InfoPopup from '../components/interface/elements/InfoPopup.vue';
import BlankState from '../components/interface/elements/BlankState.vue';
import CollapsibleCard from '../components/interface/elements/CollapsibleCard.vue';
import DropdownButton from '../components/interface/elements/DropdownButton.vue';
import ReadMore from '../components/interface/elements/ReadMore.vue';

/**
 * Setup the global components
 *
 * @param Vue
 */
export default function (Vue) {
    Vue.component('logo', Logo);
    Vue.component('field', Field);
    Vue.component('notice', Notice);
    Vue.component('loader', Loader);
    Vue.component('grin-guy', GrinGuy);
    Vue.component('info-popup', InfoPopup);
    Vue.component('status-tag', StatusTag);
    Vue.component('blank-state', BlankState);
    Vue.component('network-icon', NetworkIcon);
    Vue.component('collapsible-card', CollapsibleCard);
    Vue.component('dropdown-button', DropdownButton);
    Vue.component('read-more', ReadMore);
}
