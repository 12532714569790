<template>
    <div>
        <campaign-task v-for="task in campaign.tasks" :task="task" :key="task.id"></campaign-task>
    </div>
</template>
<script>
    import CampaignTask from "./CampaignTask.vue";
    import CampaignProposalMixin from '../CampaignProposalMixin';
    import TrackingNotification from "../../partials/TrackingNotification.vue";
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'CampaignTasks',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            CampaignProposalMixin
        ],

        /**
         * The child components
         *
         * @type {object}
         */
        components: {
            TrackingNotification,
            CampaignTask
        }
    }
</script>