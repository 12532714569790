import { get, isString } from 'lodash';
import { defineStore } from 'pinia';
import useHttp from '../src/composables/use-http';

const useContactStore = defineStore('contact', {
    state: () => {
        return {
            data: get(window.SERVER_DATA, 'contact', null),
            loading: false,
            error: null
        };
    },
    getters: {
        hasShipping(state) {
            return isString(get(state.contact, 'street') || null) && isString(get(state.contact, 'country') || null);
        }
    },
    actions: {
        async reload() {
            const { http } = useHttp();
            this.loading = true;

            try {
                const response = await http.get(`/api/v1/contacts/${this.data?.id}`);
                this.data = response.data;
            } catch (e) {
                this.error = 'Sorry, there was an error reloading the contact information.';
            }

            this.loading = false;
        },

        async updateAddress(payload) {
            const { http } = useHttp();
            this.loading = true;

            try {
                const response = await http.post(`/contact/${this.data?.id}/update/shipping`, payload);
                this.data = response.data;
            } catch (e) {
                this.error = 'Sorry, there was an error updating the address.';
            }

            this.loading = false;
        },

        async updateContact(payload) {
            const { http } = useHttp();
            this.loading = true;

            try {
                const response = await http.post(`/contact/${this.data?.id}/update/contact`, payload);
                this.data = response.data;
            } catch (e) {
                this.error = 'Sorry, there was an error updating the contact.';
            }

            this.loading = false;
        },

        async updateNotificationChannels(payload) {
            const { http } = useHttp();
            this.loading = true;

            try {
                const response = await http.post(`/contact/${this.data?.id}/update/notifications`, payload);
                this.data = response.data;
            } catch (e) {
                this.error = 'Sorry, there was an error updating the notification channels.';
            }

            this.loading = false;
        },

        async updateNotificationSettings(payload) {
            const { http } = useHttp();
            this.loading = true;
            try {
                const response = await http.post(`/contact/${this.data?.id}/update/notification-settings`, payload);
                this.data = response.data;
            } catch (e) {
                this.error = 'Sorry, there was an error updating the notification settings.';
            }
            this.loading = false;
        }
    }
});

export default useContactStore;
