import Vue from 'vue';

/**
 * The vue event bus
 *
 * @type {Vue}
 */
const Bus = new Vue();

/**
 * The plugin object
 *
 * @param {object} VueConstructor
 */
const VueBus = (VueConstructor) => {
    VueConstructor.$bus = Bus;

    Object.defineProperties(VueConstructor.prototype, {
        $bus: {
            get() {
                return Bus;
            }
        }
    });
};

export default VueBus;
