import get from 'lodash/get';

const useWhiteLabeling = () => {
    const isWhiteLabeled = () => {
        const liveUrl = get(window, 'SERVER_DATA.live_url', null);

        if (liveUrl !== null) {
            return !window.location.origin.includes(liveUrl);
        }

        return false;
    };

    return {
        isWhiteLabeled
    };
};

export default useWhiteLabeling;
