import { getMeta } from './helpers';

// import the setup function to bootstrap the app
import setup from './setup';

import _ from 'lodash';

// import global mixins
import LoadingMixin from './mixins/LoadingMixin';
import NetworkMixin from './mixins/NetworkMixin';
import ContactMixin from './mixins/ContactMixin';
import CampaignMixin from './mixins/CampaignMixin';

import Join from './components/sections/Join.vue';

import gui from '@grininc/grin-ui';
import library from '../app-v2/components/library';
import antDirective from 'ant-design-vue/es/_util/antDirective';
import '@grininc/grin-ui/dist/grin-ui.css';
import useEventTracking from './composables/use-event-tracking';
import { AVAILABLE_NETWORKS } from '../app-v2/constants/networks';
import { createPinia } from 'pinia';
import patterns from './components/sections/join/PatternTemplates';

const Vue = setup();

// include the styles for the app
require('../sass/widget-import.scss');

Vue.use(antDirective);
Vue.use(gui);
Vue.use(library);
Vue.component('RecruitmentWidget', Join);

const pinia = createPinia();

/**
 * The root vue instance
 *
 * @type {Vue}
 */
let app = new Vue({

    /**
     * The element to bind the Vue app to
     *
     * @type {string}
     */
    el: '#grin-widget',

    /**
     * The Pinia instance
     * Only relevant in Vue 2
     * In Vue 3 this will be migrated to adding the pinia instance directly
     * @type {import('pinia').Pinia}
     */
     pinia,

    /**
     * The root mixins
     *
     * @type {array}
     */
    mixins: [
        LoadingMixin,
        NetworkMixin,
        ContactMixin,
        CampaignMixin,
    ],

    setup(props, context) {
        const events = useEventTracking();

        /**
         * Track an event
         * NOTE: Use the useEventTracking composable instead
         *
         * @param {String} name
         * @param {Object} properties
         * @deprecated
         */
        const trackEvent = (name, properties = {}) => {
            events.trackEvent(name, properties);
        };

        return {
            trackEvent
        };
    },

    /**
     * The app data
     *
     * @returns {object}
     */
    data() {
        return {
            fields:                _.get(window.SERVER_DATA, 'fields', null),
            page:                  _.get(window.SERVER_DATA, 'page', null),
            contact:               _.get(window.SERVER_DATA, 'contact', null),
            networks:              _.get(window.SERVER_DATA, 'networks', []),
            account:               _.get(window.SERVER_DATA, 'account', null),
            buttonBackgroundColor: _.get(window.SERVER_DATA, 'buttonBackgroundColor', '#ffffff'),
            buttonFontColor:       _.get(window.SERVER_DATA, 'buttonFontColor', 'black'),
        };
    },

    /**
     * The computed properties
     *
     * @type {object}
     */
    computed: {

        /**
         * The base url for the live server (without the subdomain)
         *
         * @returns {string}
         */
        base_url() {
            return getMeta('base-url');
        },

        pageNetworks() {
            return this.getNetworksBySlugs(this.page.networks.map(network => network.id));
        },

        requiredNetworks() {
            if (!this.page.networks_enabled || null === this.page.networks) {
                return [];
            } else {
                return this.getNetworksBySlugs(
                    this.page.networks.filter(network => network.required).map(network => network.id)
                );
            }
        },

        requiredNetworkSlugs() {
            return this.requiredNetworks.map(network => network.slug);
        },

        /**
         * Returns a set of network objects that a contact has connected prior to arriving at the landing page
         * @returns {*[]}
         */
        usersExistingNetworkConnections() {
            return _.get(this.contact, 'networks', []);
        },

        /**
         * If the user has connected the required networks
         *
         * @returns {boolean}
         */
        userHasLinkedRequiredNetworks() {
            const existing_network_slugs = this.usersExistingNetworkConnections.map(network => network.network.slug);
            const connected = this.networks.map(network => network.network.slug);
            const merged = connected.concat(existing_network_slugs);

            return this.requiredNetworkSlugs.every(item => {
                return merged.includes(item);
            });
        }
    },

    /**
     * The root component methods
     *
     * @type {object}
     */
    methods: {
        /**
         * Get specific campaign
         *
         * @param contact_id {String}
         * @param campaign_id {String}
         * @returns {PromiseLike}
         */
        getCampaign(contact_id, campaign_id) {
            return this.$http.get(`/contact/${contact_id}/campaigns/${campaign_id}`).then(res => res.data)
                .then((campaign) => {
                    this.$bus.$emit('join.contact.proposal', campaign);
                });
        },

        /**
         * Get specific activation brief, for contact + activation
         *
         * @param activation_id {String}
         * @returns {PromiseLike}
         */
        getActivationBrief(contact_id, activation_id) {
            return this.$http.get(`/contact/${contact_id}/activation-brief/${activation_id}`).then(res => res.data)
                .then((activation_brief) => {
                    this.$bus.$emit('join.contact.activation', activation_brief.id);
                });
        },

        /**
         * Get network configurations, by slugs
         * @param slugs {array}
         */
        getNetworksBySlugs(slugs) {
            return slugs.map(slug => {
                slug = 'facebook_page' === slug ? 'facebook' : slug;
                return _.find(AVAILABLE_NETWORKS, { slug });
            });
        },

        setBackgroundTemplate() {
            if (this.page.template_data?.background_type !== 'svg' || !this.page.template_data?.background_pattern) {
                return;
            }

            // Process the new pattern "name" stored in the db.
            if (this.page.template_data.background_pattern.length < 30) {
                const foundPattern = patterns[this.page.template_data.background_pattern];
                if (foundPattern) {
                    const svg = window.btoa(foundPattern(this.page.template_data.foreground_color));
                    document.body.style.backgroundImage = `url(data:image/svg+xml;base64,${svg})`;
                }
            } else {
                // Otherwise it's the old svg "data" string stored in the db.
                document.body.style.backgroundImage = `url(${this.page.template_data.background_pattern})`;
            }
        },
    },
    mounted() {
        this.setBackgroundTemplate();
    },
});
