export default {

    /**
     * The mixin props
     */
    props: {

        /**
         * The field
         */
        field: {
            type: Object,
            required: true,
        },

        /**
         * The contact field value
         */
        value: {
            type: [Array, String, Number, Object],
            required: false
        }
    },

    /**
     * The mixin data
     *
     * @returns {object}
     */
    data() {
        return {

            /**
             * The input model
             *
             * @type {mixed}
             */
            model: null,

            /**
             * The default model (override for different types)
             *
             * @type {string|null}
             */
            default_model: null
        }
    },

    /**
     * The computed data
     */
    computed: {
        /**
         * @returns {array|null}
         */
        orderedOptions() {
            if (!('options' in this.field && Array.isArray(this.field.options))) {
                return null;
            }

            return this.field.options.sort((a, b) => a.position - b.position);
        }
    },

    /**
     * The component methods
     */
    methods: {

        /**
         * Get the value payload
         *
         * @returns {mixed}
         */
        getValue() {
            return this.model;
        }
    },

    /**
     * When the component is created
     */
    created() {
        this.model = this.value || this.default_model;
    }
}
