<template>
    <div class="campaign-task box">
        <div class="is-clearfix">
            <div class="task-title-row">
                <div class="task-title-col task-icon" v-if="task.network">
                    <network-icon :network="task.network.name" />
                </div>
                <div class="task-title-col task-type">
                    <span class="text-semibold">
                        {{ task.type }} <!--<span v-if="task.required" class="tag is-small">required</span>-->
                    </span>
                </div>
                <div class="task-title-col task-check">
                    <el-checkbox v-model="proposal.tasks" :disabled="!canSelectTask" :label="task.id">&nbsp;</el-checkbox>
                </div>
            </div>
        </div>

        <div class="task-requirements content" v-html="$sanitize(transformedRequirements)"></div>

        <!--
        <div class="notification" v-if="isStory && !hasOauth">
            <strong>Story Requirements: </strong> use the "Sync {{ task.network.name }}" button below
            <span v-if="!task.requires_oauth">to auto deliver Story content, for other delivery options
                <a href="https://support.grin.co/influencer-tutorials/how-to-download-instagram-stories-and-find-your-insights" target="_blank">
                    click here
                </a>.
            </span>
            <span v-else>.</span>
        </div>
        -->

        <div class="mb-10 mt-10" v-if="!canSelectTask || showGraphConnection">

            <network-manager
                v-if="context === 'landing'"
                :contact-id="contactId"
                context="profile"
                :networks="connectNetworks"
                :contact-networks="contactNetworks"
            />
            <template v-else>
                <div class="field has-addons" v-if="showTikTok">
                    <div class="control is-expanded">
                        <input class="input" placeholder="@tiktok.username" v-model="tiktokPayload.url"></input>
                    </div>
                    <div class="control">
                        <a class="button" :class="{'is-loading': loadingTiktok}" @click="connectTikTok">
                            Connect
                        </a>
                    </div>

                    <div class="has-text-red" v-if="tiktokErrors">
                        {{ tiktokErrors }}
                    </div>
                </div>

                <a v-else class="button"
                   :class="{'is-loading': $root.loading(`network.${task.network.name.toLowerCase()}.connect`)}"
                   @click="connectNetwork(task.network)">
                <span v-if="task.requires_oauth || !$root.hasNetwork(task.network_id)">
                    Sync {{ task.network.name }} to Add
                </span>
                    <span v-else>
                    Sync {{ task.network.name }}
                </span>
                </a>
            </template>

            <social-auth-info-popover :network="task.network.name" link-classes="button is-link" link-text="What's this?" />
        </div>

    </div>
</template>
<script>
    import NetworkMixin from '../../../../../mixins/NetworkMixin';
    import ReadMore from "../../../../interface/elements/ReadMore.vue";
    import SocialAuthInfoPopover from "../../../settings/SocialAuthInfoPopover";
    import NetworkManager from "../../../../../../app-v2/components/data-views/networks/partials/NetworkManager";
    import {AVAILABLE_NETWORKS} from "../../../../../../app-v2/constants/networks";

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: "CampaignTask",

        inject: {
            context: { default: 'proposal' },
        },

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The task
             */
            task: {
                type: Object,
                required: true,
            }
        },

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            NetworkMixin,
        ],

        /**
         * The child components
         *
         * @type {object}
         */
        components: {
            NetworkManager,
            SocialAuthInfoPopover,
            ReadMore,
        },


        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {

            /**
             * The proposal
             *
             * @returns {object}
             */
            proposal() {
                return this.$parent.$parent.proposal_model;
            },

            /**
             * Whether this task is an Instagram story
             *
             * @returns {boolean}
             */
            isStory() {
                return (this.task.network_id && this.task.media_type === 'story');
            },

            /**
             * If the task is attached to a network and the influencer has
             * connected that network to his account, or if the task has
             * no network requirements, then he can select it.
             *
             * @returns {boolean}
             */
            canSelectTask() {
                if (!this.task.network_id) {
                    return true;
                }

                if (this.task.media_type !== 'story' || !this.task.requires_oauth) {
                    return this.$root.hasNetwork(this.task.network_id);
                }

                return this.$root.hasOAuthToken(this.task.network_id);
            },

            /**
             * Whether to show the graph connection link
             *
             * @returns {boolean}
             */
            showGraphConnection() {
                return  this.isStory
                    && !this.hasOauth
                    && !this.task.requires_oauth;
            },

            /**
             * Whether the influencer has an Oauth for this network
             *
             * @returns {boolean}
             */
            hasOauth() {
                return this.$root.hasOAuthToken(this.task.network_id);
            },

            /**
             * Transform the requirements html
             *
             * @returns {string}
             */
            transformedRequirements() {
                return this.task.requirements.replace('href', 'target="_blank" href');
            },

            contactId() {
                return this.$root.contact.id;
            },
            contactNetworks() {
                return this.$root.networks;
            },
            connectNetworks() {
                return AVAILABLE_NETWORKS.filter(network => this.task.network.id === network.id);
            },
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {

            /**
             * Mark the required tasks as checked
             */
            checkIfRequired() {
                if (this.task.required && this.canSelectTask) {
                    this.proposal.tasks.push(this.task.id);
                }
            }
        },

        /**
         * When the component is first created
         *
         * @type {function}
         */
        created() {
            this.checkIfRequired();
        }
    }
</script>
