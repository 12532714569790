import forEach from 'lodash-es/forEach';
import moment from 'moment-timezone';
/**
 * Get the value of a meta tag by name
 */
export const getMeta = (name) => {
    var _a;
    const meta = document.querySelector(`meta[name="${name}"]`);
    return (_a = meta === null || meta === void 0 ? void 0 : meta.getAttribute('content')) !== null && _a !== void 0 ? _a : '';
};
/**
 * Format a number with commas for thousands places
 *
 * @param {number} value
 * @returns {string}
 */
export const commas = (value) => {
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};
/**
 * Replace nl characters with <b> tags
 */
export const nl2br = (text) => {
    // eslint-disable-next-line no-control-regex
    return text.replace(new RegExp('\r?\n', 'g'), '<br>');
};
/**
 * Format the value as a short date according to app.grin.co
 */
export const platformShortDate = (value, tz = null, ignoreLocal = false) => {
    const returnFormat = 'MMM D, YYYY';
    if (ignoreLocal) {
        return moment.utc(value).format(returnFormat);
    }
    let date = moment.utc(value).local();
    if (tz) {
        date = date.tz(tz);
    }
    return date.format(returnFormat);
};
/**
 * Format the value as a short date
 */
export const shortDate = (value) => {
    return moment.utc(value).local().format('M/D/YY');
};
/**
 * Completed task count
 */
export const completedTaskCount = (tasks) => {
    if (tasks.length === 0) {
        return 0;
    }
    return tasks.filter((task) => task.status === 'Delivered').length;
};
/**
 * The color for the given status
 */
export const statusColor = (status, forTag = false) => {
    switch (status.toLowerCase()) {
        case 'new':
        case 'in progress':
        case 'accepting proposals':
            return forTag ? 'primary' : 'primary';
        case 'has proposals':
        case 'accepted':
        case 'completed':
        case 'complete':
        case 'paid':
        case 'delivered':
            return forTag ? 'success' : 'green';
        case 'waiting':
            return forTag ? 'warning' : 'orange';
        case 'denied':
        case 'declined':
        case 'canceled':
        case 'expired':
        case 'late':
        case 'refunded':
        case 'disputed':
            return forTag ? 'danger' : 'red';
        default:
            return '';
    }
};
/**
 * Get the request errors from the response
 *
 * @param {object} response
 * @returns {string}
 */
export function requestErrors(response) {
    // first we check for a Laravel specific error
    // structure by checking for an errors property
    if (Object.prototype.hasOwnProperty.call(response.data, 'errors')) {
        const all = [];
        // loop through each error section and push
        // the errors into the array that we will return
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        forEach(response.data.errors, (errors) => {
            forEach(errors, (error) => all.push(error));
        });
        // we need to return an error string so we
        // just join the messages by a html break
        return all.join('<br />');
    }
    // next we check to see if we have a message property
    // that is sometimes set via the Laravel controllers
    if (Object.prototype.hasOwnProperty.call(response.data, 'message')) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        return response.data.message;
    }
    // finally, if we don't find anything specific then
    // we just return the entire response as a string
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
}
/**
 * Get the network icon from the network slug
 */
export function networkIcon(slug) {
    switch (slug.toLowerCase()) {
        case 'youtube':
            return 'fab fa-youtube';
        case 'facebook':
            return 'fab fa-facebook-f';
        case 'instagram':
            return 'fab fa-instagram';
        case 'twitter':
            return 'fab fa-twitter';
        case 'linkedin':
            return 'fab fa-linkedin';
        case 'twitch':
            return 'fab fa-twitch';
        case 'vimeo':
            return 'fab fa-vimeo';
        case 'tiktok':
            return 'gi gi-tiktok';
        case 'soundcloud':
            return 'fab fa-soundcloud';
        case 'tumblr':
            return 'fab fa-tumblr';
        case 'pinterest':
            return 'fab fa-pinterest';
        case 'dribbble':
            return 'fab fa-dribbble';
        case 'grin':
            return 'fab fa-smile-o';
        case 'website':
            return 'far fa-globe';
        default:
            return '';
    }
}
/**
 * Add html links to plan text
 */
export function linkify(str) {
    // http://, https://, ftp://
    const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;
    // www. sans http:// or https://
    const pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    // Email addresses
    const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;
    return str
        .replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
        .replace(pseudoUrlPattern, '$1<a href="http://$2" target="_blank">$2</a>')
        .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
}
/**
 * Launches a popup window.
 */
export function popup(url, w, h) {
    const dualScreenLeft = window.screenLeft != null ? window.screenLeft : screen.left;
    const dualScreenTop = window.screenTop != null ? window.screenTop : screen.top;
    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : screen.width;
    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : screen.height;
    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;
    const newWindow = window.open(url, 'Grin', `scrollbars=no,resizable=yes,stats=no,location=no, width=${w}, height=${h}, top=${top}, left=${left}`);
    if (newWindow) {
        newWindow === null || newWindow === void 0 ? void 0 : newWindow.focus();
    }
    return newWindow;
}
/**
 * Validates if a string is an email
 */
export function isEmail(email) {
    return !!String(email)
        .toLowerCase()
        .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
}
