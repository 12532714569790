import { popup } from '../../src/helpers';
import { INSTAGRAM_ID, NETWORK_INFO_BY_SLUG } from '../constants/networks';
import WindowEventMixin from './WindowEventMixin';
import EventTrackingMixin from './EventTrackingMixin';

export default {
    mixins: [WindowEventMixin, EventTrackingMixin],

    data() {
        return {
            connectionMixin: true
        };
    },

    computed: {
        /**
         * Whether or not an Instagram creator/business connection is required
         *
         * @returns {boolean}
         */
        instagramGraphRequired() {
            return (
                this.proposal && this.proposal.tasks.filter((task) => this.taskRequiresInstagramOauth(task)).length > 0
            );
        }
    },

    methods: {
        /**
         * Whether the given task requires Instagram creator/business connection
         *
         * @returns {boolean}
         */
        taskRequiresInstagramOauth(task) {
            return (
                task.network_id === INSTAGRAM_ID &&
                (task.media_type === 'story' || task.media_type === 'reel') &&
                task.requires_oauth
            );
        },

        /**
         * Show the connection window
         */
        showConnectionWindow(url, slug, callback = null) {
            const p = popup(url, 500, 600);

            const i = setInterval(() => {
                if (p.closed) {
                    this.$emit('popup-closed', NETWORK_INFO_BY_SLUG[slug].id);
                    if (callback) {
                        callback();
                    }
                    clearInterval(i);
                }
            }, 200);

            this.listenForMessages();
        },

        /**
         * Listen for cross-window messages
         */
        listenForMessages() {
            let pathName = '';

            if (!this.$route) {
                pathName = 'v2.manage.networks.failed';
            } else {
                switch (this.$route.name) {
                    case 'v2.activation.brief.networks':
                        pathName = 'v2.activation.brief.networks.failed';
                        break;
                    case 'v2.campaign.proposal.networks':
                        pathName = 'v2.campaign.proposal.networks.failed';
                        break;
                    case 'manage.networks':
                    default:
                        pathName = 'v2.manage.networks.failed';
                }
            }

            this.listenForWindowMessage('network-error', (event) => {
                this.$router.push({
                    name: pathName,
                    params: { network: event.data.network }
                });
            });
        }
    }
};
