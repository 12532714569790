<template>
    <span class="nowrap">
        <template v-if="!isWhiteLabeled">
            <g-typography color="secondary">Powered by </g-typography>
            <img class="grin-logo" :src="GRIN_LOGO_BLACK" width="50px" alt="logo" />
            <g-typography color="secondary" margins margin-class="l-2"> | </g-typography>
        </template>
        <g-typography v-if="!hideLinks" class="links">
            <a href="https://help.grin.co/" target="_blank" @click="handleHelpClick">Help</a>
            <span class="g-text-secondary"> | </span>
            <a href="https://grin.co/privacy/" target="_blank" @click="handlePrivacyClick">Privacy</a>
            <span class="g-text-secondary"> | </span>
            <a href="https://grin.co/terms-of-use-influencers/" target="_blank" @click="handleTermsClick">Terms</a>
        </g-typography>
    </span>
</template>

<script>
    import { EVENT_NAME_GENERAL_LINK_CLICK } from '../../constants/analytics-events';
    import useEventTracking from '../../../src/composables/use-event-tracking';
    import { GRIN_LOGO_BLACK } from '../../constants/cdn-assets';
    import useWhiteLabeling from '../../../src/composables/use-white-labeling';

    export default {
        name: 'PoweredByGrin',
        props: {
            clickable: {
                type: Boolean,
                default: false
            },
            hideLinks: {
                type: Boolean,
                default: false
            }
        },

        setup() {
            const events = useEventTracking();

            const handlePrivacyClick = () => {
                events.trackEvent(EVENT_NAME_GENERAL_LINK_CLICK, {
                    link_text: 'Privacy',
                    link_url: 'https://grin.co/privacy/',
                    link_domain: 'https://grin.co'
                });
            };

            const handleTermsClick = () => {
                events.trackEvent(EVENT_NAME_GENERAL_LINK_CLICK, {
                    link_text: 'Terms',
                    link_url: 'https://grin.co/terms-of-use-influencers/',
                    link_domain: 'https://grin.co'
                });
            };

            const handleHelpClick = () => {
                events.trackEvent(EVENT_NAME_GENERAL_LINK_CLICK, {
                    link_text: 'Help',
                    link_url: 'https://grin.co/help/',
                    link_domain: 'help.grin.co'
                });
            };

            const isWhiteLabeled = useWhiteLabeling().isWhiteLabeled();

            return {
                GRIN_LOGO_BLACK,
                isWhiteLabeled,
                handlePrivacyClick,
                handleTermsClick,
                handleHelpClick
            };
        }
    };
</script>

<style lang="scss" scoped>
    .nowrap {
        white-space: nowrap;
        & span {
            font-size: 14px;
        }
    }

    .grin-logo {
        margin-top: -2px;
    }

    .links a {
        color: $g-color-grey-6 !important;
        &:hover {
            color: $g-color-primary-5 !important;
        }
    }
</style>
