export default {

    data() {
        return {

            /**
             * The event sources to exclude
             *
             * @type {Array}
             */
            excludedEventSources: [
                'vue-devtools-proxy',
                'vue-devtools-backend'
            ]
        }
    },

    methods: {

        /**
         * Whether the given event source is excluded
         *
         * @param  {MessageEvent} event
         * @return {Boolean}
         */
        sourceIsExcluded(event) {
            return this.excludedEventSources.includes(event.data.source);
        },

        /**
         * Whether the given event message matches any of the given messages
         *
         * @param  {MessageEvent} event
         * @param  {Array|String} message
         * @return {Boolean}
         */
        messageMatches(event, message) {
            if (!Array.isArray(message)) {
                message = [message];
            }

            return message.includes(event.data.message);
        },

        /**
         * Listen for the given window message
         *
         * @param {Array|String} message
         * @param {Function}     callback
         */
        listenForWindowMessage(message, callback) {
            window.addEventListener('message', event => {
                if (this.sourceIsExcluded(event)) {
                    return;
                }

                if (this.messageMatches(event, message)) {
                    callback(event);
                }
            }, false);
        }
    }

}