import _ from 'lodash';
import { popup, getMeta } from "../helpers";
import { networks } from "../helpers/constants";
import {TIKTOK_ID} from "../../app-v2/constants/networks";

export default {

    /**
     * The mixin data
     *
     * @returns {object}
     */
    data() {
        return {

            /**
             * The available networks
             *
             * @type {object}
             */
            networks,

            showTikTok: false,
            tiktokErrors: null,
            loadingTiktok: false,
            tiktokPayload: {
                network_id: TIKTOK_ID,
                url: null
            }
        }
    },

    /**
     * The mixin methods
     *
     * @type {object}
     */
    methods: {

        /**
		 * Whether or not the user has the given network
		 *
		 * @param {number} network_id
		 * @param {boolean} creatorAccountRequired
		 * @returns {object|undefined}
		 */
        hasNetwork(network_id, creatorAccountRequired = false) {
            return _.find(this.$root.networks, { network_id });
        },

        /**
         * Whether or not the user has connected a given network
         *
         * @param {Number} network_id
         * @returns {Boolean}
         */
        hasOAuthToken(network_id) {
            const network = this.hasNetwork(network_id);

            return network && network.network_connection_type === 'oauth';
        },

        /**
         * Get the contact network by id
         *
         * @param {number} network_id
         * @returns {object|null}
         */
        getNetwork(network_id) {
            let networks = this.networks.filter(network => network.network_id == network_id);
            if(networks && networks.length) {
                return networks[0];
            }
            return null;
        },

        /**
         * Connect the TikTok network
         */
        connectTikTok() {
            this.loadingTiktok = true;
            this.$http
                .post(`/api/v1/contacts/${this.$root.contact_id}/networks`, this.tiktokPayload)
                .then(() => {
                    this.$root.reloadContact('networks');
                    this.showTikTok = false;
                })
                .catch(() => this.tiktokErrors = 'Could not sync profile, please use a valid TikTok username/URL')
                .then(()  => this.loadingTiktok = false);
        },

        /**
         * Connect the given network
         *
         * @param {object} network
         */
        connectNetwork(network) {

            if (network.id === TIKTOK_ID) {
                return this.showTikTok = true;
            }

            const url  = getMeta('routing-domain');
            const slug = network.name.toLowerCase();
            this.$root.loaderStart(`network.${slug}.connect`);
            let p = popup(`https://${url}/contact/${this.$root.contact_id}/auth/${slug}/redirect?start=true`, 500, 600);
            let i = setInterval(() => {
                if(p.closed) {
                    this.$root.reloadContact('networks');
                    this.$root.loaderStop(`network.${slug}.connect`);
                    clearInterval(i);

                }
            }, 200);
        },

    }
}
