// import and use the language for Element UI
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/en';

// Add the corresponding css in import.scss file.
import Alert from 'element-ui/lib/alert';
import Tag from 'element-ui/lib/tag';
import Tabs from 'element-ui/lib/tabs';
import TabPane from 'element-ui/lib/tab-pane';
import Popover from 'element-ui/lib/popover';
import Radio from 'element-ui/lib/radio';
import RadioGroup from 'element-ui/lib/radio-group';
import RadioButton from 'element-ui/lib/radio-button';
import Checkbox from 'element-ui/lib/checkbox';
import CheckboxGroup from 'element-ui/lib/checkbox-group';
import Switch from 'element-ui/lib/switch';
import Dialog from 'element-ui/lib/dialog';
import Tooltip from 'element-ui/lib/tooltip';
import DatePicker from 'element-ui/lib/date-picker';
import Select from 'element-ui/lib/select';
import Option from 'element-ui/lib/option';
import OptionGroup from 'element-ui/lib/option-group';
import Slider from 'element-ui/lib/slider';
import Carousel from 'element-ui/lib/carousel';
import CarouselItem from 'element-ui/lib/carousel-item';
import Pagination from 'element-ui/lib/pagination';

/**
 * Setup the Element UI components
 *
 * @param Vue
 */
export default function (Vue) {
    locale.use(lang);

    Vue.component(Alert.name, Alert);
    Vue.component(Slider.name, Slider);
    Vue.component(Pagination.name, Pagination);
    Vue.component(Carousel.name, Carousel);
    Vue.component(CarouselItem.name, CarouselItem);
    Vue.component(Tag.name, Tag);
    Vue.component(Dialog.name, Dialog);
    Vue.component(Tooltip.name, Tooltip);
    Vue.component(Tabs.name, Tabs);
    Vue.component(TabPane.name, TabPane);
    Vue.component(Radio.name, Radio);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(RadioGroup.name, RadioGroup);
    Vue.component(RadioButton.name, RadioButton);
    Vue.component(Checkbox.name, Checkbox);
    Vue.component(CheckboxGroup.name, CheckboxGroup);
    Vue.component(Switch.name, Switch);
    Vue.component(Popover.name, Popover);
    Vue.component(Select.name, Select);
    Vue.component(Option.name, Option);
    Vue.component(OptionGroup.name, OptionGroup);
}
