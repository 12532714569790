<template>
    <article class="media mt-15 mb-10">
        <div class="media-content">
            <div class="content">
                <p>
                    <span v-if="campaign.customization && campaign.customization.compensation_payment_overview">
                        {{ campaign.customization.compensation_payment_overview }}
                    </span>
                    <span v-else>This will be a payment from {{ campaign.brand.name }}.</span>
                </p>
            </div>
        </div>
    </article>
</template>

<script>
    import CampaignProposalMixin from '../CampaignProposalMixin';

    export default {
        name: 'MoneyCompensation',

        mixins: [
            CampaignProposalMixin,
        ],
    };
</script>
