<template>
    <div class="is-clearfix">
        <slot name="notice"></slot>
        <div class="mb-20">
            <slot name="terms"></slot>
        </div>
        <div class="field has-addons">
            <p class="control" v-if="hasCompensation('money') && campaign.inputs_price">
                <a class="button is-medium is-static">Your Price:</a>
            </p>
            <p class="control is-expanded" v-if="hasCompensation('money') && campaign.inputs_price">
                <money-input classes="is-medium" v-model="$parent.proposal_model.price" :currency="campaign.brand.currency" />
            </p>
            <p class="control">
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script>
    import CampaignProposalMixin from '../CampaignProposalMixin';
    import MoneyInput from '../../../../interface/elements/MoneyInput.vue';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'ProposalPriceAndTerms',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            CampaignProposalMixin
        ],

        /**
         * The used components
         *
         * @type {object}
         */
        components: {
            MoneyInput
        }
    }
</script>