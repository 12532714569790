export default {

    /**
     * The mixin data
     *
     * @returns {object}
     */
    data() {
        return {

            /**
             * The loader keys
             *
             * @type {array}
             */
            loaders: []
        }
    },

    /**
     * The mixin methods
     *
     * @type {object}
     */
    methods: {
        /**
         * If the given key is loading
         *
         * @param {string} key
         * @returns {boolean}
         */
        loading(key) {
            return this.loaders.indexOf(key) >= 0;
        },

        /**
         * Start a loader by key
         *
         * @param {string} key
         */
        loaderStart(key) {
            this.loaders.push(key);
        },

        /**
         * Stop a loader by key
         *
         * @param {string} key
         * @returns {boolean}
         */
        loaderStop(key) {
            if(this.loading(key)) {
                this.loaders.splice(this.loaders.indexOf(key), 1);
            }
        }
    }
}