<template>
    <div class="connect-failed-wrapper">
        <img alt="Failed" :src="failedLogo" class="logo-style" />

        <div class="h1">
            <g-typography tag="h1" text-align="center" font-weight="semibold">
                {{ messages[network].title }}
            </g-typography>
        </div>

        <div class="text-wrapper">
            <div class="margin-p">
                <g-typography tag="p" text-align="center">
                    <span data-testid="error-message" v-html="$sanitize(messages[network].message)" />
                </g-typography>
            </div>
        </div>

        <div v-if="messages[network].info" class="margin-p info-list-wrapper">
            <ul class="info-list font-color-normal">
                <li v-for="item in messages[network].info" :key="item" v-html="$sanitize(item)"></li>
            </ul>
        </div>

        <div class="margin-top-2 margin-bottom-3">
            <g-button class="connect-button" @click="goBack"> Try Again </g-button>
        </div>
    </div>
</template>

<script>
    import { FAILED_LOGO } from '../../../../constants/cdn-assets';
    import * as NETWORKS from '../../../../constants/networks';
    import EventTrackingMixin from '../../../../mixins/EventTrackingMixin';
    import useEventTracking from '../../../../../src/composables/use-event-tracking';
    import { onMounted, onUnmounted } from 'vue-demi';
    import {
        EVENT_NAME_FACEBOOK_FOLLOW_STEPS_SUBMITTED,
        EVENT_NAME_FACEBOOK_TRY_AGAIN_SUBMITTED,
        LIVE_CAMPAIGN_PROPOSAL_NETWORK_CONNECTION_EVENTS
    } from '../../../../constants/analytics-events';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'ConnectNetworkFailed',

        /**
         * The component properties
         *
         * @type {Object}
         */
        props: {
            network: {
                type: String,
                required: true
            }
        },

        /**
         * The component mixins
         *
         * @type {Array}
         */
        mixins: [EventTrackingMixin],

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            failedLogo() {
                return FAILED_LOGO;
            },
            messages() {
                return {
                    [NETWORKS.YOUTUBE_SLUG]: {
                        title: 'Connection Failed',
                        message: 'There may be a few reasons for YouTube connection failure. Check the following:',
                        info: [
                            'Log in to the correct Google / YouTube account',
                            'Make sure the YouTube account has an associated YouTube channel',
                            'Make sure you leave all permissions accepted',
                            'Do not close the dialog window until you have completed the process'
                        ]
                    },
                    [NETWORKS.INSTAGRAM_GRAPH_SLUG]: {
                        title: 'Connection Failed',
                        message: 'There may be a few reasons for this, check that you have done the following:',
                        info: [
                            'Converted your Instagram account into a Creator Account',
                            'Created a Facebook page and connected it to your Instagram account',
                            'When prompted during the connection process, select your Facebook Page',
                            'Leave all permissions turned ON, if you previously turned any of them off, click "Edit Settings" and turn them on.'
                        ]
                    },
                    [NETWORKS.INSTAGRAM_SLUG]: {
                        title: 'Connection Failed',
                        message:
                            'We could not find your Instagram account, please try again after checking the most common issues below.',
                        info: [
                            'Make sure you enter your Instagram username exactly as it is spelled, with no spaces or other characters.',
                            'Make sure your Instagram profile is set to public and can be accessed'
                        ],
                        error: 'Account not found'
                    },
                    [NETWORKS.INSTAGRAM_ACCOUNT_SLUG]: {
                        title: 'Professional Account Missing',
                        message:
                            '<p>This Instagram account doesn\'t exist or is not a Professional account. To continue, switch to a Professional account connected with a Facebook Business page. You can learn how to do it with our help article <a href="https://help.grin.co/docs/connecting-your-instagram" target="_blank">here</a>.</p>'
                    },
                    [NETWORKS.INSTAGRAM_PAGE_SLUG]: {
                        title: 'Facebook Page Missing',
                        message:
                            'It looks like you may not have <strong>a Facebook page linked to your Instagram account.</strong>',
                        info: [
                            'If you don\'t have a Facebook Business Page, you can learn more on how to make one with Meta\'s help article <a href="https://www.facebook.com/business/help/473994396650734?id=939256796236247" target="_blank">here</a>.',
                            'Be sure to connect a Facebook Page to your Instagram Professional account. You can learn how to do it with our help article <a href="https://help.grin.co/docs/connecting-your-instagram#connecting-your-instagram-professional-account-and-facebook-business-page" target="_blank">here</a>.',
                            'Make sure you select the same Facebook Page when connecting with Instagram and when connecting through your Live URL.'
                        ],
                        error: 'Facebook page not linked'
                    },
                    [NETWORKS.INSTAGRAM_PERMISSIONS_SLUG]: {
                        title: 'Permissions Missing',
                        message:
                            'It looks like you did not <strong>accept all of the required permissions</strong>. If you want to continue, please do the following: ',
                        info: [
                            'Click "Try Again" to start the process over',
                            'Click "Connect" and wait for the pop up to appear',
                            'Click "Edit Settings" and make sure the Instagram account and Facebook page you want to connect are checked and click "Next"',
                            'Make sure all of the permissions are checked and set to "YES" and click "Done"'
                        ],
                        error: 'Not all required permissions were accepted'
                    },
                    [NETWORKS.TWITTER_SLUG]: {
                        title: 'Connection Failed',
                        message: 'There may be a few reasons for this, check the following:',
                        info: [
                            'Log in to the correct Twitter account',
                            'Make sure you leave all permissions accepted',
                            'Do not close the dialog window until you have completed the process'
                        ]
                    },
                    [NETWORKS.TWITCH_SLUG]: {
                        title: 'Connection Failed',
                        message: 'There may be a few reasons for this, check the following:',
                        info: [
                            'Log in to the correct Twitch account',
                            'Make sure you leave all permissions accepted',
                            'Do not close the dialog window until you have completed the process'
                        ]
                    },
                    [NETWORKS.PINTEREST_SLUG]: {
                        title: 'Connection Failed',
                        message: 'There may be a few reasons for this, check the following:',
                        info: [
                            'Log in to the correct Pinterest account',
                            'Make sure you leave all permissions accepted',
                            'Do not close the dialog window until you have completed the process'
                        ]
                    },
                    [NETWORKS.PINTEREST_ACCOUNT_SLUG]: {
                        title: 'Business Account Missing',
                        message:
                            '<p>This Pinterest account doesn\'t exist or is not a Business account. To continue, create or switch to a Business account. You can learn how to do it with our help article <a href="https://help.grin.co/docs/connecting-your-pinterest" target="_blank">here</a>.</p>'
                    },
                    [NETWORKS.TIKTOK_SLUG]: {
                        title: 'Connection Failed',
                        message: 'There may be a few reasons for this, check the following:',
                        info: [
                            'Log in to the correct TikTok account',
                            'Make sure you leave all permissions accepted',
                            'Do not close the dialog window until you have completed the process'
                        ]
                    },
                    [NETWORKS.TIKTOK_PERMISSIONS_SLUG]: {
                        title: 'Permissions Missing',
                        message:
                            'It looks like you did not <strong>accept all of the required permissions</strong>. If you want to continue, press "Try Again" and do the following: ',
                        info: [
                            'Press "Connect" and wait for the popup to appear',
                            'Press "Edit access" and make sure "Read your public videos on TikTok" is toggled on',
                            'Press the back arrow and press "Authorize"'
                        ],
                        error: 'Not all required permissions were accepted'
                    },
                    [NETWORKS.FACEBOOK_SLUG]: {
                        title: 'Connection Failed',
                        message: 'There may be a few reasons for this, check the following:',
                        info: [
                            'Log in to the correct Facebook account',
                            "If you don't see the Page you want, make sure you have the correct permissions on that page",
                            'Make sure you leave all permissions accepted',
                            'Do not close the dialog window until you have completed the process'
                        ]
                    },
                    [NETWORKS.FACEBOOK_PENDING_SLUG]: {
                        title: 'Connection Pending',
                        message: '',
                        info: ['No further action needed. Once confirmed, this message will disappear.']
                    },
                    [NETWORKS.FACEBOOK_MISSING_USERNAME]: {
                        title: 'Missing Facebook Username',
                        message:
                            'Your Facebook account requires a custom username to continue, please update your Facebook account and try again. Learn how to create one <a href="https://www.facebook.com/help/121237621291199/?helpref=related_articles" target="_blank">here</a>.'
                    }
                };
            }
        },

        /**
         * The component methods
         *
         * @type {Object}
         */
        methods: {
            /**
             * Track the event
             */
            trackEvent() {
                const events = LIVE_CAMPAIGN_PROPOSAL_NETWORK_CONNECTION_EVENTS[this.network];

                if (events && events.error) {
                    this.$bus.$emit(events.error);
                }
            }
        },

        setup(props, context) {
            const events = useEventTracking();

            const tryAgainFacebookAnalyticsEvent = () => {
                events.trackEvent(EVENT_NAME_FACEBOOK_TRY_AGAIN_SUBMITTED, {
                    network: 'Facebook'
                });
            };

            const followStepsFacebookAnalyticsEvent = () => {
                events.trackEvent(EVENT_NAME_FACEBOOK_FOLLOW_STEPS_SUBMITTED, {
                    network: 'Facebook'
                });
            };

            /**
             * Go back event
             */
            const goBack = () => {
                tryAgainFacebookAnalyticsEvent();
                context.emit('go-back');
            };

            onUnmounted(() => {
                const stepsClick = document.getElementById('stepsClick');
                if (stepsClick) {
                    stepsClick.removeEventListener('click', followStepsFacebookAnalyticsEvent, false);
                }
            });

            return {
                tryAgainFacebookAnalyticsEvent,
                followStepsFacebookAnalyticsEvent,
                goBack,
                onMounted
            };
        },
        /**
         * When the component is created
         */
        created() {
            this.trackEvent();
        },

        /**
         * When the component is mounted
         */
        mounted() {
            this.$nextTick(() => {
                const stepsClick = document.getElementById('stepsClick');
                if (stepsClick) {
                    stepsClick.addEventListener('click', followStepsFacebookAnalyticsEvent);
                }
                const errorMessage = this.messages[this.network].error
                    ? this.messages[this.network].error
                    : this.messages[this.network].title;
                this.trackEventNetworkConnected(this.network, false, errorMessage);
            });
        }
    };
</script>

<style lang="scss" scoped>
    .connect-failed-wrapper {
        text-align: center;

        .margin-top-2 {
            margin-top: $g-spacing-7;
        }

        .margin-bottom-3 {
            margin-bottom: $g-spacing-9;
        }

        .h1 {
            font-size: 1.25rem;
            max-width: 350px;
            text-align: center;
            margin: 0 auto $g-spacing-6 auto;
        }

        .logo-style {
            width: 184px;
            margin-top: $g-spacing-7;
            margin-bottom: $g-spacing-7;
        }

        .text-wrapper {
            margin: 0 auto;
            width: 290px;
        }

        .margin-p {
            margin-bottom: $g-spacing-5;
        }

        .info-list {
            text-align: left;
            margin: 30px 15%;
            background-color: rgba(237, 240, 242, 0.5);
            border-radius: 10px;
            padding: 25px 25px 25px 50px;

            li {
                line-height: 1.75;
                color: $g-font-color-body;
            }
        }

        .info-list-wrapper {
            padding-left: 25px;
        }

        .connect-button {
            display: inline-block;
            background-color: #303c4a;
            font-size: 1rem;
            font-weight: 500;
            color: #fff;
            width: 174px;
            height: 48px;
            border-radius: 24px;
            box-shadow: 0 4px 8px rgba(48, 60, 74, 0.2);

            &:hover {
                border: 0;
            }
        }
    }
</style>
