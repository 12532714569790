<template>
    <!-- If the contact is an "account user": email is ineligible for submission. -->
    <div v-if="contactIsUser" class="notification">
        <span>
            The email address provided is not eligible to become a brand partner. Please try again with a different
            email address.
        </span>
    </div>

    <!-- If contact is found... -->
    <div v-else-if="contactFound" class="notification">
        <!-- ...and their submission is incomplete... -->
        <template v-if="submissionWaiting">
            <!-- ...and they are an "applicant": Let them know to use Live URL to complete submission. -->
            <span v-if="'application' === contactRecordType">
                Please complete your in-progress application, using your Live URL. We just sent you an email with your
                link, it should arrive shortly.
            </span>

            <!-- ...and they are not an "applicant": Show configured "submitted" message, or boilerplate. -->
            <span v-else v-html="$sanitize(page.submitted_message || boilerplate_in_progress)" />
        </template>

        <!-- ...and their submission is complete... -->
        <template v-else>
            <!-- ...and they are an "influencer": Let them know we sent Live URL. -->
            <span v-if="'influencer' === contactRecordType">
                <strong>We found your Live URL!</strong> We just sent you an email with your link, it should arrive
                shortly.
            </span>

            <!-- ...and they are not an "influencer": Show configured "accepted" message, or boilerplate. -->
            <span v-else v-html="$sanitize(page.accepted_message || boilerplate_in_progress)" />
        </template>

        <span v-if="page.support_email">
            If you need help or have any questions you can email us at
            <a :href="`mailto:${page.support_email}?subject=Influencer%20Program%20Support`">
                {{ page.support_email }}
            </a>
        </span>
    </div>

    <!-- If we did not find the contact. -->
    <div v-else-if="submissionWaiting" class="notification">
        <span v-html="$sanitize(page.accepted_message || boilerplate_in_progress)" />

        <span v-if="page.support_email">
            If you need help or have any questions you can email us at
            <a :href="`mailto:${page.support_email}?subject=Influencer%20Program%20Application`">
                {{ page.support_email }}
            </a>
        </span>
    </div>
</template>

<script>
    export default {
        /**
         *  The name of the component
         */
        name: 'ContactFound',

        /**
         *  The components props
         */
        props: {
            contactFound: {
                type: Boolean
            },
            submissionWaiting: {
                type: Boolean
            },
            contactIsUser: {
                type: Boolean
            },
            contactRecordType: {
                type: String
            }
        },

        /**
         * Computed values
         *
         * @type {Object}
         */

        computed: {
            /**
             * Page
             *
             * @returns {Object}
             */
            page() {
                return this.$root.page;
            },

            boilerplate_in_progress() {
                return `<strong>Your application is in progress!</strong> You will receive an email
                with your Live URL if your application is accepted.`;
            }
        }
    };
</script>
