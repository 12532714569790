<template>
    <g-modal
        v-model="modal"
        :closable="true"
        :width="null"
        wrap-class-name="global-exit-modal"
        :mask-style="{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }"
        @cancel="handleCancel"
    >
        <div class="margin-bottom-small">
            <g-typography tag="h3" font-weight="semibold">
                <slot name="title"> Are you sure? </slot>
            </g-typography>
        </div>
        <g-typography tag="p">
            <slot />
        </g-typography>
        <template #footer>
            <div class="modal-footer">
                <g-button @click="handleCancel">{{ cancelButtonText }}</g-button>
                <g-button type="danger" theme="live" @click="handleContinue">
                    {{ confirmButtonText }}
                </g-button>
            </div>
        </template>
    </g-modal>
</template>

<script>
    export default {
        name: 'GenericConfirmationModal',
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            cancelButtonText: {
                type: String,
                default: 'Cancel'
            },
            confirmButtonText: {
                type: String,
                default: 'Yes'
            }
        },
        data() {
            return {
                modal: false
            };
        },
        methods: {
            handleContinue() {
                this.$emit('continue');
            },
            handleCancel() {
                this.$emit('cancel');
            }
        },
        watch: {
            visible(val) {
                this.modal = val;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .modal-footer {
        display: flex;
        justify-content: flex-end;
        padding: $g-spacing-7;
    }
    .margin-bottom-small {
        margin-bottom: $g-spacing-3;
    }
</style>

<style lang="scss">
    .global-exit-modal .ant-modal {
        width: $g-screen-size-xs;
        padding: $g-spacing-5;
    }
    .global-exit-modal .ant-modal-body {
        padding: $g-spacing-7;
    }
    .global-exit-modal .ant-modal-footer {
        border: none;
        padding: 0;
    }
</style>
