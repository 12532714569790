<template>
    <div class="select is-fullwidth">
        <select v-model="model">
            <option :value="null">- Please Select -</option>
            <option v-for="option in orderedOptions" :key="option.id" :value="option.label">
                {{ option.value }}
            </option>
        </select>
    </div>
</template>
<script>
    import FieldInputMixin from './FieldInputMixin';

    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'DropdownSelectInput',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            FieldInputMixin
        ]
    }
</script>

