import { Message } from 'element-ui';
import { requestErrors } from "../helpers";

/**
 * The plugin object
 *
 * @type {object}
 */
const VueNotify = {

    /**
     * Install the plugin
     *
     * @param Vue
     * @param {object} options
     */
    install(Vue, options) {

        /**
         * Show a success message
         *
         * @param {string} message
         */
        Vue.prototype.$notifySuccess = function(message) {
            Message({ message, duration: 2500, type: 'success' });
        };

        /**
         * Show an error message
         *
         * @param {string} message
         */
        Vue.prototype.$notifyError = function(message) {
            Message({ message, duration: 2500, type: 'error' });
        };

        /**
         * Show the request errors
         *
         * @param {object} response
         */
        Vue.prototype.$requestErrors = function(response) {
            const message = requestErrors(response);
            Message({ message, duration: 3200, type: 'error', dangerouslyUseHTMLString: true });
        }
    }
};

export default VueNotify;