<template>
    <div>
        <div v-if="!connectingAccount" id="app-networks" class="notification is-dark">
            <strong>Almost done!</strong>
            Just connect {{ requiredNetworkFieldsString }} and then hit "Submit Application"
        </div>

        <network-manager
            :contact-id="contactId"
            context="profile"
            :networks="networks"
            :contact-networks="contactNetworks"
            :required-networks="requiredNetworks"
            is-landing-page
            @handle-failed="
                () => {
                    $bus.$emit('join.error', 'Failed to connect network');
                }
            "
            @start-account-connection="startAccountConnection"
            @finish-account-connection="finishAccountConnection"
        />

        <div v-if="hasRequiredNetworks && !connectingAccount" class="text-center mt-20">
            <p class="mb-10">
                <data-accept ref="terms">I accept the</data-accept>
            </p>
            <button class="button is-medium" @click="completeApplication">
                <span v-if="page.campaign_id">Next: Sponsorship</span>
                <span v-else>Submit Application</span>
            </button>
        </div>
    </div>
</template>

<script>
    import DataAccept from '../../interface/elements/DataAccept.vue';
    import { capitalize } from 'lodash';
    import NetworkManager from '../../../../app-v2/components/data-views/networks/partials/NetworkManager.vue';

    export default {
        /**
         * Component Name
         *
         * @type { String }
         */
        name: 'Networks',

        /**
         * Component child components
         *
         * @type {Object}
         */
        components: {
            NetworkManager,
            DataAccept
        },

        data() {
            return {
                connectingAccount: false
            };
        },

        /**
         * Computed Properties
         *
         * @type { Object }
         */
        computed: {
            /**
             * The page data
             *
             * @returns { Object }
             */
            page() {
                return this.$root.page;
            },

            /**
             * A formatted required network fields string
             *
             * @returns {string}
             */
            requiredNetworkFieldsString() {
                return this.$root.requiredNetworkSlugs.reduce((fieldString, networkField, i, arr) => {
                    if (arr.length - 1 === i) {
                        return fieldString + capitalize(networkField);
                    } else if (arr.length - 2 === i) {
                        if (arr.length === 2) {
                            return fieldString + capitalize(networkField) + ' and ';
                        } else {
                            return fieldString + capitalize(networkField) + ', and ';
                        }
                    } else {
                        return fieldString + capitalize(networkField) + ', ';
                    }
                }, '');
            },

            /**
             * If the user has connected the required networks
             *
             * @returns {boolean}
             */
            hasRequiredNetworks() {
                return this.$root.userHasLinkedRequiredNetworks;
            },

            contactId() {
                return this.$root.contact.id;
            },
            contactNetworks() {
                return this.$root.networks;
            },
            networks() {
                return this.$root.pageNetworks;
            },
            requiredNetworks() {
                return this.$root.requiredNetworks;
            }
        },

        /**
         * Component Methods
         *
         * @type { Object }
         */
        methods: {
            /**
             * Complete the application
             */
            completeApplication() {
                if (!this.$refs.terms || !this.$refs.terms.hasAccepted()) {
                    this.$bus.$emit('join.error', 'You must agree to the terms');
                    return;
                }
                // if there is a campaign attached to this landing page
                // we need to redirect the user to the sponsorship details
                if (this.page.campaign_id) {
                    return (window.location = `/${this.$root.contact.live_url}`);
                }

                this.$bus.$emit('join.error.clear');
                this.$bus.$emit('join.contact.application.complete');
            },

            startAccountConnection() {
                this.connectingAccount = true;
            },

            finishAccountConnection() {
                this.connectingAccount = false;
            }
        }
    };
</script>
