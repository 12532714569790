<template>
    <span class="icon network-icon-container" :style="`font-size: ${size}px`">
        <span v-if="network === 'tiktok'" class="gi gi-tiktok"></span>
        <img
            v-else-if="network === 'instagram' && taskType === TYPE_INSTAGRAM_REEL"
            :src="INSTAGRAM_REELS_ICON"
            alt="Instagram Reels Icon"
            :style="igStyle"
            class="pdf-remove"
        />
        <img v-else-if="network === 'instagram'" src="/svg/instagram.svg" alt="Instagram Icon" :style="igStyle" />
        <i v-else :class="iconClass"></i>

        <div v-if="showConnectionStatus" class="status_indicator_wrapper" :style="`font-size: ${size / 2.8}px`">
            <i
                :class="`fas fa-circle status_indicator ${
                    isConnected ? 'status_indicator--enabled' : 'status_indicator--disabled'
                }`"
            />
        </div>
    </span>
</template>

<script>
    import { networkIcon } from '../../../helpers';
    import { computed } from 'vue-demi';
    import { INSTAGRAM_REELS_ICON } from '../../../../app-v2/constants/cdn-assets';
    import { TYPE_INSTAGRAM_REEL } from '../../../../app-v2/constants/networks';

    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'NetworkIcon',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {
            /**
             * The network to show the icon for
             */
            network: {
                type: String,
                required: true
            },

            /**
             * The network to show the icon for
             */
            taskType: {
                type: String,
                required: false,
            },

            /**
             * Whther or not the icon should be in color
             */
            color: {
                type: Boolean,
                required: false,
                default: true
            },

            /**
             * The size in px of the icon
             */
            size: {
                type: Number,
                required: false,
                default: 16
            },

            /**
             * Boolean whether to show connection status indicator
             */
            showConnectionStatus: {
                type: Boolean,
                required: false,
                default: false
            },

            /**
             * Whether network status indicator shows green
             */
            isConnected: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        /**
         * The component's computed properties
         *
         * @type {object}
         */
        setup(props) {
            /**
             * The network icon class
             *
             * @returns {object.boolean}
             */
            const iconClass = computed(() => {
                return {
                    [networkIcon(props.network)]: true,
                    ['icon-color']: props.color
                };
            });

            const igStyle = computed(() => {
                return `max-width: ${props.size}px`;
            });

            return {
                INSTAGRAM_REELS_ICON,
                TYPE_INSTAGRAM_REEL,
                iconClass,
                igStyle
            };
        }
    };
</script>

<style lang="scss" scoped>
    .status_indicator_wrapper {
        position: relative;
    }
    .status_indicator {
        position: absolute;
        left: -2px;
        bottom: -1px;
        border: solid;
        border-radius: 50%;
        border-color: white;
        background-color: white;
    }
    .status_indicator--enabled {
        color: $g-color-green-5;
    }
    .status_indicator--disabled {
        color: $g-color-grey-4;
    }
    .network-icon-container {
        display: inline-block !important;
    }
    .gi-tiktok {
        color: #000;
    }
</style>
