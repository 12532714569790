import {NON_RECURRING_PROPOSAL_EXISTS} from "../../../constants/locked-exceptions";
import {message} from "@grininc/grin-ui";
export default {
    methods: {
        handleLockedException({error_name, attributes}) {
            switch (error_name) {
                case NON_RECURRING_PROPOSAL_EXISTS:
                    let dashboardPath = this.$router.resolve({
                        name: 'sponsorships'
                    }).href;

                    this.createHtmlErrorMessage(`A proposal already exists for this campaign. <a href="${dashboardPath}">Go to Dashboard</a>`);
                    let currentLocation = window.location.href;
                    setTimeout(function () {
                        if (window.location.href === currentLocation) {
                            window.location.href = dashboardPath;
                        }
                    }, 10000);
                    break;
            }

        },
        createHtmlErrorMessage(html, time = 10) {
            let dummyElement = this.$createElement('span', {
                domProps: {
                    innerHTML: html
                }
            });
            message.error(dummyElement, time);
        }
    }
}