<template>
    <textarea class="input" v-model="model" style="min-height: 70px;"></textarea>
</template>
<script>
    import FieldInputMixin from './FieldInputMixin';

    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'MultiLineTextInput',

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [
            FieldInputMixin
        ]
    }
</script>

