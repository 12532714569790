import _ from 'lodash';

export default {

    /**
     * The computed properties
     *
     * @type {object}
     */
    computed: {

        /**
         * The influencer support email
         *
         * @returns {string}
         */
        support_email() {
            return this.campaign.brand.support_email;
        },
    },

    /**
     * The mixin methods
     *
     * @type {object}
     */
    methods: {

        /**
         * Get the customization text
         *
         * @param   {string} key
         * @param   {string} fallback
         * @param   {object} campaign
         * @returns {string}
         */
        customizationText(key, fallback, campaign = null) {

            campaign = campaign || this.campaign;

            if(!campaign.customization) {
                return fallback;
            }

            const value = _.get(campaign.customization, key);

            return (_.isEmpty(value) || value === 'null') ? fallback : value;
        },

    }
}
