import { getCurrentInstance } from 'vue-demi';
/**
 * Get the current instance of a component
 * This allows composables to access things like $store
 * where appropriate.
 * In Vue 3, this should be rendered obsolete.
 * This should _not_ be used directly, instead prefer to create a composable with this if
 * absolutely required and then use the purpose built composable instead.
 */
const useCurrentInstance = () => {
    const vm = getCurrentInstance();
    if (!vm || !vm.proxy) {
        throw new Error('unable to get current instance of component');
    }
    return vm.proxy;
};
export default useCurrentInstance;
