<template>
    <div class="notification is-inverted" :class="noticeClass">
        <span class="icon"><i class="far" :class="iconClass"></i></span>
        <slot></slot>
    </div>
</template>
<script>
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'Notice',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The notice type
             */
            type: {
                type: String,
                required: false,
                default: 'info'
            }
        },

        /**
         * The component's computed properties
         *
         * @type {object}
         */
        computed: {

            /**
             * The notification class
             *
             * @returns {object.boolean}
             */
            noticeClass() {
                return {
                    ['is-primary']: this.type === 'info',
                    ['is-success']: this.type === 'success',
                    ['is-warning']: this.type === 'warning',
                    ['is-danger']: this.type === 'danger',
                }
            },

            /**
             * The icon class
             *
             * @returns {object.boolean}
             */
            iconClass() {
                return {
                    ['fa-info-circle']: this.type === 'info',
                    ['fa-check-circle']: this.type === 'success',
                    ['fa-exclamation-circle']: this.type === 'warning',
                    ['fa-times-circle']: this.type === 'danger'
                }
            }
        }
    }
</script>