<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                {{ title }}
            </p>
            <a class="card-header-icon" @click.prevent="toggleContent">
                <span class="icon">
                    <i class="far" :class="arrowClass" aria-hidden="true" />
                </span>
            </a>
        </header>
        <div v-if="!collapsed" class="card-content">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'CollapsibleCard',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {
            /**
             * The card title
             */
            title: {
                type: String,
                required: true
            },

            /**
             * Whether or not the card content is initially collapsed
             */
            initiallyCollapsed: {
                type: Boolean,
                default: false
            }
        },

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {
                /**
                 * The collapsed state
                 *
                 * @type {Boolean}
                 */
                collapsed: this.initiallyCollapsed
            };
        },

        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {
            /**
             * The arrow fa class
             */
            arrowClass() {
                return {
                    ['fa-angle-up']: !this.collapsed,
                    ['fa-angle-down']: this.collapsed
                };
            }
        },

        /**
         * The component's computed properties
         *
         * @type {object}
         */
        methods: {
            /**
             * Toggle the card content
             */
            toggleContent() {
                this.collapsed = !this.collapsed;
            }
        }
    };
</script>
