<template>
    <base-box :border="true">
        <template #content>
            <div>
                <g-level>
                    <g-level-item style="overflow: visible">
                        <div class="social-network-connect-line">
                            <network-icon
                                :network="network.slug"
                                :show-connection-status="true"
                                :is-connected="false"
                            />

                            <span v-if="!showIncompleteConnection">
                                <g-typography tag="p" :value="network.name" />
                                <g-typography v-if="!!required" tag="p" value="(required)" color="error" />
                            </span>
                            <span v-else>
                                <g-typography tag="span" font-weight="bold">
                                    {{
                                        contactNetwork.network_name
                                            ? contactNetwork.network_name
                                            : contactNetwork.network_username
                                    }}
                                </g-typography>

                                <g-typography tag="span"> ({{ contactNetwork.network_username }}) </g-typography>
                            </span>
                        </div>
                    </g-level-item>

                    <g-level-item slot="right">
                        <g-button
                            theme="live"
                            shape="round"
                            class="connect-network-btn"
                            data-testid="connect"
                            @click="connect"
                        >
                            Connect
                        </g-button>
                    </g-level-item>
                </g-level>

                <g-base-box v-if="hasFailureReason" background-color="grey-2" class="g-mt-5">
                    <div>
                        <g-icon name="exclamation-circle" type="solid" color="orange-5" class="g-mr-2" />
                        <g-typography tag="span" color="secondary" font-weight="semibold" :value="failureReasonTitle" />
                    </div>

                    <g-typography tag="p" color="secondary" :value="failureReasonMessage" class="g-mt-2 g-ml-6" />
                </g-base-box>
            </div>

            <meta-connection-info-drawer
                v-if="network.id === INSTAGRAM_ID || network.id === FACEBOOK_ID"
                :visible="showMetaConnectionInfoDrawer"
                :is-landing-page="isLandingPage"
                :live-url="liveUrl"
                :network-id="network.id"
                :brief-id="briefId"
                :contact-id="contactId"
                :task-id="taskIdCheck"
                @close="hideMetaConnectionInfoDrawer"
                @continue="handleMetaDrawerContinue"
            />
        </template>
    </base-box>
</template>

<script>
    import { getMeta } from '../../../../../src/helpers';
    import NetworkConnectionMixin from '../../../../mixins/NetworkConnectionMixin';
    import EventTrackingMixin from '../../../../mixins/EventTrackingMixin';
    import NetworkIcon from '../../../../../src/components/interface/elements/NetworkIcon.vue';
    import MetaConnectionInfoDrawer from './MetaConnectionInfoDrawer.vue';
    import {
        FACEBOOK_ID,
        INSTAGRAM_ID,
        TIKTOK_ID,
        PINTEREST_ID,
        CONNECTION_FAILURES,
        CONNECTION_FAILURE_REASON_CONNECTION_PENDING,
        CONNECTION_FAILURE_REASON_INCOMPLETE
    } from '../../../../constants/networks';
    import { useCreatorStore, useFeatureFlagsStore } from '../../../../../stores';
    import useNetworks from '../../../../../src/composables/use-networks';

    export default {
        name: 'ConnectNetworkItem',
        components: {
            NetworkIcon,
            MetaConnectionInfoDrawer
        },
        props: {
            network: {
                type: Object,
                default: () => ({})
            },
            contactNetwork: {
                type: Object,
                default: null
            },
            contactId: {
                type: String,
                default: ''
            },
            context: {
                type: String,
                required: true
            },
            required: {
                type: Boolean
            },
            isLandingPage: {
                type: Boolean,
                default: false
            },
            proceedToConnect: {
                type: Object,
                default: {
                    value: false,
                    networkId: 0
                }
            }
        },
        mixins: [NetworkConnectionMixin, EventTrackingMixin],
        data() {
            const flags = useFeatureFlagsStore().data;
            const creatorStore = useCreatorStore();
            return {
                flags,
                showMetaConnectionInfoDrawer: false,
                INSTAGRAM_ID,
                FACEBOOK_ID,
                creatorStore
            };
        },
        computed: {
            host() {
                return getMeta('routing-domain');
            },
            url() {
                return `https://${this.host}/contact/${this.contactId}/auth/${this.network.slug}/redirect?start=true`;
            },
            hasFailureReason() {
                if (
                    this.network.id === INSTAGRAM_ID &&
                    [CONNECTION_FAILURE_REASON_CONNECTION_PENDING, CONNECTION_FAILURE_REASON_INCOMPLETE].includes(
                        this.contactNetwork?.failure_reason
                    )
                ) {
                    return false;
                }

                return !!this.contactNetwork?.failure_reason;
            },
            failureReasonMessage() {
                return CONNECTION_FAILURES[this.contactNetwork?.failure_reason]?.message;
            },
            failureReasonTitle() {
                return CONNECTION_FAILURES[this.contactNetwork?.failure_reason]?.title;
            },
            failurePage() {
                return CONNECTION_FAILURES[this.contactNetwork?.failure_reason]?.page;
            },
            showIncompleteConnection() {
                return !!this.contactNetwork && this.hasFailureReason;
            },
            taskIdCheck() {
                return this.$route && this.$route.query && this.$route.query.task_id ? this.$route.query.task_id : null;
            },
            liveUrl() {
                return this.$route && this.$route.query && this.$route.params.live_url
                    ? this.$route.params.live_url
                    : '';
            },
            briefId() {
                return this.$route && this.$route.query && this.$route.params.brief_id
                    ? this.$route.params.brief_id
                    : '';
            }
        },
        methods: {
            /**
             * Open the popup to connect network
             */
            connect(event, metaContinue = false) {
                this.$emit('clear-proceed-to-connect');
                this.trackEventInitialNetworkConnect(this.network.slug);

                const socialNetwork = this.creatorStore.getFirstActiveSocialNetwork(this.network.id);
                if (
                    useNetworks().showSharedNetworkPreConnect(socialNetwork, this.isLandingPage) &&
                    !this.proceedToConnect.value &&
                    !metaContinue
                ) {
                    this.$emit('show-shared-networks', this.network.id, null);
                    return;
                }

                if (this.hasFailureReason && this.context === 'profile') {
                    return this.handleFailure();
                }

                if ([INSTAGRAM_ID, FACEBOOK_ID].includes(this.network.id)) {
                    if (this.network.id === INSTAGRAM_ID) {
                        return this.handleNetworkRedirect();
                    }

                    if (this.network.id === FACEBOOK_ID) {
                        return this.handleNetworkRedirect();
                    }
                }

                if (this.network.id === PINTEREST_ID) {
                    return this.handleNetworkRedirect();
                }

                this.showConnectionWindow(this.url, this.network.slug);
            },

            /**
             * Handle the Network redirect
             */
            handleNetworkRedirect() {
                if (this.context === 'profile' && [INSTAGRAM_ID, FACEBOOK_ID, PINTEREST_ID].includes(this.network.id)) {
                    this.$emit('network-connection-info', this.network.id);
                } else if (this.context === 'brief') {
                    this.$router.push({
                        name: `v2.activation.brief.networks.network`,
                        query: {
                            networkId: this.network.id
                        }
                    });
                } else {
                    this.$router.push({
                        name: `v2.campaign.proposal.networks.network`,
                        query: {
                            networkId: this.network.id
                        }
                    });
                }
            },

            /**
             * Redirect to a failure page
             */
            handleFailure() {
                if (this.context === 'profile') {
                    this.$emit('show-failure-page', this.failurePage, this.network.id);
                }

                if (this.context === 'proposal') {
                    return this.$router.push({
                        name: 'v2.campaign.proposal.networks.failed',
                        params: { network: this.failurePage }
                    });
                }

                if (this.context === 'brief') {
                    return this.$router.push({
                        name: 'v2.activation.brief.networks.failed',
                        params: { network: this.failurePage }
                    });
                }
            },

            handleMetaDrawerContinue() {
                this.showMetaConnectionInfoDrawer = false;
                this.connect(null, true);
            },

            hideMetaConnectionInfoDrawer() {
                this.showMetaConnectionInfoDrawer = false;
                this.$emit('meta-connection-drawer-closed');
            }
        },
        watch: {
            proceedToConnect: {
                handler(proceedToConnect) {
                    if (proceedToConnect.value) {
                        if (this.network.id === proceedToConnect.networkId) {
                            this.$emit('close-shared-networks');
                            this.connect();
                        }
                    }
                },
                immediate: true
            }
        },

        mounted() {
            if (
                [INSTAGRAM_ID, FACEBOOK_ID, TIKTOK_ID, PINTEREST_ID].includes(+this.$route?.query?.connectById) &&
                [INSTAGRAM_ID, FACEBOOK_ID, TIKTOK_ID, PINTEREST_ID].includes(this.network?.id) &&
                +this.$route?.query?.connectById === this.network?.id
            ) {
                if (this.$route?.query?.taskId && this.$route?.query?.deliverableId) {
                    this.$router.replace({
                        query: {
                            taskId: this.$route?.query?.taskId,
                            deliverableId: this.$route?.query?.deliverableId,
                            briefId: this.$route?.query?.briefId
                        }
                    });
                } else {
                    this.$router.replace({ query: null });
                }

                this.connect();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .social-network-connect-line {
        vertical-align: top;
        display: inline-block;

        span,
        p {
            display: inline-block;
        }
    }

    .connect-network-btn {
        width: 90px;
    }

    .gi-tiktok {
        color: #000;
        width: 1.25rem;
        display: inline-block;
        text-align: center;
        margin-right: $g-spacing-3 !important;
    }
</style>
