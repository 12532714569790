// import setup methods
import setupVue from './vue';
import setupElementUI from './element-ui';
import setupComponents from './components';
import setupVueResource from './vue-resource';
import setupBroadcasting from './broadcasting';
import setupBugsnag from './bugsnag';

/**
 * Setup the application
 */
export default function (rootComponent = {}) {
    const Vue = setupVue(rootComponent);

    setupBugsnag(Vue);
    setupBroadcasting();
    setupElementUI(Vue);
    setupComponents(Vue);
    setupVueResource(Vue);

    return Vue;
}
