<template>
    <div class="notification mb-5" v-if="campaign.reporting">
        Please use all of the following tags in your post(s): <strong>{{ campaign.reporting }}</strong>
        <span v-if="has_stories"><br />For Stories, please make sure at least one tag is not a sticker.</span>
    </div>
</template>
<script>
    export default {

        /**
         * The component name
         *
         * @type {string}
         */
        name: 'TrackingNotification',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {

            /**
             * The campaign
             */
            campaign: {
                type:     Object,
                required: true
            },
        },

        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {

            /**
             * Whether the campaign has Story tasks
             *
             * @returns {boolean}
             */
            has_stories() {
                if(this.campaign.tasks && this.campaign.tasks.length) {
                    return this.campaign.tasks.filter(task => task.media_type === 'story').length > 0;
                }
                return false;
            }
        }
    }
</script>
