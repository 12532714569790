<template>
    <div>
        <g-form-item label="Address" class="g-mb-3" :required="required">
            <g-address-validation
                v-if="featureResponsiveDesign && googlePlacesApiKey && !googlePlacesError"
                class="address-validation"
                :api-key="googlePlacesApiKey"
                :default-value="parts.street"
                placeholder="Address"
                @change="onPlaceChange"
            />
            <div v-else class="fallback-address-input">
                <g-input
                    v-model="parts.street"
                    class="input"
                    placeholder="Address"
                    name="address-line1"
                    @change="onFieldChange"
                />
                <g-typography
                    v-if="googlePlacesError"
                    class="g-mt-3"
                    tag="p"
                    value="We couldn't verify this address, so please make sure it's correct."
                    color="error"
                />
            </div>
        </g-form-item>
        <g-form-item label="Apartment, suite, etc." class="g-mb-3">
            <g-input
                v-model="parts.street_2"
                class="input"
                placeholder="Apartment, suite, etc. (optional)"
                name="address-line2"
                @change="onFieldChange"
            />
        </g-form-item>
        <g-form-item label="City" class="g-mb-3" :required="required">
            <g-input
                v-model="parts.city"
                class="input"
                placeholder="City"
                name="address-level2"
                @change="onFieldChange"
            />
        </g-form-item>
        <g-row type="flex" :gutter="16">
            <g-column :xs="24" :md="country_column_span">
                <g-form-item label="Country" class="g-mb-3" :required="required">
                    <g-select
                        v-model="locations.country"
                        name="country"
                        class="notranslate"
                        style="width: 100%"
                        show-search
                        option-filter-prop="children"
                        @change="
                            () => {
                                changeCountry();
                                onFieldChange();
                            }
                        "
                    >
                        <g-select-option v-for="country in priorityCountries" :key="country.name" class="notranslate" :value="country.name">
                            {{ country.name }}
                        </g-select-option>
                        <g-select-option v-if="alphabeticalCountries.length > 0" key="separator" disabled>
                            ---------
                        </g-select-option>
                        <g-select-option
                            v-for="country in alphabeticalCountries"
                            :key="country.name"
                            class="notranslate"
                            :value="country.name"
                        >
                            {{ country.name }}
                        </g-select-option>
                    </g-select>
                </g-form-item>
            </g-column>
            <g-column v-if="show_provinces" :xs="24" :md="state_column_span">
                <g-form-item :label="`${selectedCountry.province_label || ''}`" class="g-mb-3" :required="required">
                    <g-select
                        v-model="locations.state"
                        name="address-level1"
                        class="notranslate"
                        autocomplete="address-level1"
                        style="width: 100%"
                        show-search
                        option-filter-prop="children"
                        @change="
                            () => {
                                changeState();
                                onFieldChange();
                            }
                        "
                    >
                        <g-select-option v-for="state in states" :key="state.name" class="notranslate" :value="state.name">
                            {{ state.name }}
                        </g-select-option>
                    </g-select>
                </g-form-item>
            </g-column>
            <g-column v-if="show_postal" :xs="24" :md="postal_column_span">
                <g-form-item label="Postal Code" class="g-mb-3" :required="required">
                    <g-input
                        v-model="parts.zip"
                        class="input"
                        placeholder="Postal code"
                        name="postal-code"
                        @change="onFieldChange"
                    />
                </g-form-item>
            </g-column>
        </g-row>
    </div>
</template>

<script>
    import find from 'lodash-es/find';
    import capitalize from 'lodash-es/capitalize';
    import { defineComponent } from 'vue-demi';
    import useFeatureFlagsStore from '../../../../stores/feature-flags';
    import LocationDropdownMixin from '../../../mixins/LocationDropdownMixin';
    import useAddress from '../../../composables/use-address';

    export default defineComponent({
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'AddressInput',

        /**
         * The component properties
         */
        props: {
            address: {
                type: [String, Object],
                default: null
            },

            /**
             * Required flag
             */
            required: {
                type: Boolean,
                default: false
            },

            onFieldChange: {
                type: Function,
                default: () => {}
            },

            supportedCountries: {
                type: Array,
                default: null
            }
        },

        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [LocationDropdownMixin],

        /**
         * The property watchers
         *
         * @type {object}
         */
        watch: {
            address: {
                immediate: true,
                handler() {
                    this.setPartsFromProp();
                }
            }
        },

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            const flags = useFeatureFlagsStore().data;

            return {
                /**
                 * The display address
                 *
                 * @type {string|null}
                 */
                display: null,

                /**
                 * The popover boolean
                 *
                 * @type {boolean}
                 */
                popover: false,

                /**
                 * The dropdown locations
                 *
                 * @type {object}
                 */
                locations: {
                    country: null,
                    state: null
                },

                /**
                 * The address parts
                 *
                 * @type {object}
                 */
                parts: {
                    id: null,
                    street: null,
                    street_2: null,
                    city: null,
                    state: null,
                    state_code: null,
                    zip: null,
                    country: null,
                    country_code: null
                },

                featureResponsiveDesign: flags['live-site-responsive-design'] || false,
                googlePlacesApiKey: window.grinEnv.GOOGLE_PLACES_API_KEY || false,
                googlePlacesError: false
            };
        },

        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {
            selectedCountry() {
                return find(this.countries, (country) => {
                    return country.name === this.locations.country;
                });
            },

            selectedState() {
                return find(this.states, (state) => {
                    return state.name === this.locations.state;
                });
            },

            /**
             * Priority countries
             */
            priorityCountries() {
                return this.countries.slice(0, 13);
            },

            /**
             * Alphabetical Countries
             */
            alphabeticalCountries() {
                return this.countries.slice(13, this.countries.length + 1);
            },

            /**
             * Whether to show the provinces
             *
             * @returns {boolean}
             */
            show_provinces() {
                return !!(this.selectedCountry && this.selectedCountry.has_provinces);
            },

            /**
             * Whether to show the postal code
             *
             * @returns {boolean}
             */
            show_postal() {
                return !!(this.selectedCountry && this.selectedCountry.has_postal_code);
            },

            /**
             * The country column classes
             *
             * @returns {number}
             */
            country_column_span() {
                if (this.show_provinces && this.show_postal) {
                    return 8;
                }

                if (!this.show_provinces && this.show_postal) {
                    return 12;
                }

                return 24;
            },

            /**
             * The state / province column classes
             *
             * @returns {object}
             */
            state_column_span() {
                if (this.show_postal) {
                    return 8;
                }

                return 12;
            },

            /**
             * The postal code column classes
             *
             * @returns {object}
             */
            postal_column_span() {
                if (this.show_provinces) {
                    return 8;
                }

                return 12;
            },

            /**
             * Validate Address
             */
            isValid() {
                if (!this.parts.street) {
                    return false;
                }

                if (!this.parts.country) {
                    return false;
                }

                if (!this.parts.city) {
                    return false;
                }

                if (this.show_provinces && !this.parts.state) {
                    return false;
                }

                return !(this.show_postal && !this.parts.zip);
            }
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {
            /**
             * Get the payload
             *
             * @returns {object}
             */
            getPayload() {
                return this.parts;
            },

            /**
             * Set the address parts from the address prop
             */
            setPartsFromProp() {
                if (this.address) {
                    const hasOwn = Object.prototype.hasOwnProperty;
                    for (const part in this.address) {
                        if (Reflect.apply(hasOwn, this.parts, [part])) {
                            if (part === 'country_code' || part === 'state_code') {
                                this.parts[part] = null;
                            } else {
                                this.parts[part] = this.address[part];
                            }
                            if (part === 'country' || part === 'state') {
                                this.setDropdownPart(part);
                            }
                        }
                    }
                    this.setDisplay(this.parts);
                }
            },

            /**
             * Set the display
             *
             * @param {object} parts
             */
            setDisplay(parts) {
                if (!this.parts.street) {
                    this.display = 'No Address';
                    return;
                }

                let d = `${this.parts.street.replace(/\.+$/, '')}.`;

                if (this.parts.street_2) {
                    d += ` ${this.parts.street_2}`;
                }

                d += ` ${this.parts.city}, ${this.parts.state}`;

                d += this.parts.zip && this.parts.zip.length ? ` ${this.parts.zip.split('-')[0]}` : '';

                d += ` ${this.parts.country}`;

                this.display = d;
            },

            /**
             * Set the dropdown part
             *
             * @param {string} type
             */
            setDropdownPart(type) {
                const name = this.parts[type];

                if (!name) {
                    return;
                }

                this.getLocationByName(type, name).then((res) => {
                    this.locations[type] = res.data.data.name;
                    this[type === 'state' ? 'states' : 'countries'].push(res.data.data);
                    this[`change${capitalize(type)}`]();
                });
            },

            /**
             * Handle the country select change
             */
            changeCountry() {
                if (this.locations.country !== this.parts.country) {
                    // Reset state and zip values.
                    // If a selected country does not have a state, we don't want to persist old state data that may have been present.
                    this.parts.state = null;
                    this.parts.state_code = null;
                    this.locations.state = null;
                    this.parts.zip = null;
                }

                if (this.locations.country) {
                    this.parts.country = this.selectedCountry.name;
                    this.parts.country_code = this.selectedCountry.code;
                    this.getStates(this.selectedCountry.id);
                }
            },

            /**
             * Handle the state select change
             */
            changeState() {
                if (this.locations.state) {
                    this.parts.state = this.selectedState.name;
                    this.parts.state_code = this.selectedState.code;
                }
            },

            /**
             * Handle the Google place change when an address
             * suggestion is selected using address validation
             */
            onPlaceChange(addressParts) {
                if (!addressParts) {
                    return;
                }

                if (useAddress().isGooglePlaceAutoCompleteSuggestionMissingStreetNumber(addressParts)) {
                    this.googlePlacesError = true;
                }

                const prevCountryCode = this.parts.country_code;

                this.parts.street = addressParts.street;
                this.parts.city = addressParts.city;
                this.parts.state = addressParts.state;
                this.parts.state_code = addressParts.state_code;
                this.parts.zip = addressParts.zip;
                this.parts.country = addressParts.country;
                this.parts.country_code = addressParts.country_code;

                this.locations.country = this.parts.country;
                this.locations.state = this.parts.state;

                if (this.selectedCountry && prevCountryCode !== addressParts.country_code) {
                    this.getStates(this.selectedCountry.id);
                }

                this.onFieldChange();
            },

            /**
             * Save the updated address
             */
            save() {
                this.$emit('changed', this.parts);
                this.popover = false;
            }
        },

        /**
         * When the component is created
         */
        created() {
            this.getCountries(this.$props.supportedCountries);
        }
    });
</script>
