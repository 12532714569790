import { getCurrentInstance } from 'vue-demi';
import { isEmpty, has } from 'lodash';
import useRouter from './use-router';

/**
 * @returns {Object} route - this.$route - can be used inside setup()
 */
const useRoute = () => {
    const internalInstance = getCurrentInstance();
    const proto = internalInstance?.proxy ? internalInstance?.proxy : internalInstance;

    const get = () => {
        return proto?.$route;
    };

    const router = useRouter().get();
    const route = get();

    const convertRouteToPageName = (routeName) => {
        if (has(routeName, 'meta.name')) {
            return routeName.meta.name;
        }

        let name = routeName?.fullPath;

        for (const param in routeName?.params) {
            if (param.indexOf('_id') >= 0) {
                name = name.replace(routeName.params[param], '').replace('//', '/');
            }
        }

        name = name?.split('?')[0];

        if (name?.charAt(0) === '/') {
            name = name.substr(1);
        }

        if (name?.charAt(name?.length - 1) === '/') {
            name = name.substr(0, name.length - 1);
        }

        return name;
    };

    const removeQueryParam = (param) => {
        if (isEmpty(param)) {
            return;
        }

        const query = Object.assign({}, route.query);
        delete query[param];
        router.replace({ query });
    };

    return {
        get,
        convertRouteToPageName,
        removeQueryParam
    };
};

export default useRoute;
