<template>
    <div class="mb-50">
        <div>
            <field label="Email" :required="true">
                <input
                    v-model="contact_model.email"
                    name="email"
                    autocomplete="email"
                    class="input"
                    placeholder="Email Address"
                    disabled
                />
            </field>
            <div class="columns">
                <div class="column is-6">
                    <field label="First Name" :required="true">
                        <input
                            v-model="contact_model.first_name"
                            name="given-name"
                            autocomplete="given-name"
                            class="input"
                            placeholder="First Name"
                        />
                    </field>
                </div>
                <div class="column is-6">
                    <field label="Last Name" :required="true">
                        <input
                            v-model="contact_model.last_name"
                            name="family-name"
                            autocomplete="family-name"
                            class="input"
                            placeholder="Last Name"
                        />
                    </field>
                </div>
            </div>
            <div v-for="field in page_fields" :key="field.id" class="mb-20">
                <field v-if="field.id === 'phone_number'" label="Phone Number" :required="field.required">
                    <phone-input ref="phone" />
                </field>

                <address-input v-else-if="field.id === 'address'" ref="address" :required="field.required" />

                <field v-else-if="field.id === 'gender'" label="Gender" :required="field.required">
                    <el-radio v-model="contact_model.gender" name="sex" autocomplete="sex" label="Male">Male</el-radio>
                    <el-radio v-model="contact_model.gender" name="sex" autocomplete="sex" label="Female">
                        Female
                    </el-radio>
                    <el-radio v-model="contact_model.gender" name="sex" autocomplete="sex" label="Other">
                        Other
                    </el-radio>
                </field>

                <template v-else-if="isCustomField(field.id)">
                    <custom-field ref="custom_fields" :field="getCustomField(field.id)" :required="field.required" />
                </template>
            </div>
            <p v-if="!$root.requiredNetworks.length" class="mt-20 mb-20">
                By submitting your application you agree to the
                <a href="https://www.grin.co/terms-of-use-influencers" target="_blank"> terms and conditions </a>
            </p>

            <div class="text-center">
                <button
                    class="button is-medium"
                    :class="{ 'is-loading': $root.loading('join.contact.submitting') }"
                    @click="submitApplication"
                >
                    <span v-if="hasProgram">Next</span>
                    <span v-else>
                        {{ page.networks_enabled ? 'Save and Continue' : 'Submit Application' }}
                    </span>
                </button>
            </div>

            <div v-if="!$root.requiredNetworks.length && !hasProgram" class="mt-25 text-italic text-12 text-light">
                The provisions agreed to above apply in the event of any conflict with the
                <a href="https://grin.co/terms-of-use-influencers/" target="_blank">Terms</a>.
            </div>
        </div>
        <div class="text-center mt-20">
            <button class="button is-light" @click="resetEmail">Use Different Email</button>
        </div>
    </div>
</template>

<script>
    import { find } from 'lodash';
    import CrmFieldInputMixin from './mixins/CrmFieldInputsMixin';
    import PhoneInput from '../../interface/form/PhoneInput.vue';
    import AddressInput from '../../interface/form/AddressInput.vue';
    import CustomField from './CustomField.vue';

    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'ContactApplicationForm',

        /**
         * The child components
         *
         * @type {Object}
         */
        components: {
            AddressInput,
            PhoneInput,
            CustomField
        },
        /**
         * The component mixins
         *
         * @type {array}
         */
        mixins: [CrmFieldInputMixin],

        /**
         * The component properties
         *
         * @type {Object}
         */
        props: {
            /**
             * The email for the contact
             */
            email: {
                type: String,
                required: true
            }
        },

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {
                /**
                 * The contact form model
                 */
                contact_model: {
                    application_page_id: this.$parent.page.id,
                    campaign_id: this.$parent.page.campaign_id,
                    activation_id: this.$parent.page.activation_id,
                    email: this.email,
                    first_name: null,
                    last_name: null,
                    gender: null
                },

                /**
                 * The custom account fields
                 *
                 * @type {array}
                 */
                fields: this.$parent.fields,

                /**
                 * The fields that should show up on the page
                 *
                 * @type {array}
                 */
                page_fields: [],

                /**
                 * The built-in fields
                 *
                 * @type {array}
                 */
                standard_fields: ['phone_number', 'address', 'gender'],

                hasCampaign: null
            };
        },

        /**
         * Computed Properties
         *
         * @type { Object }
         */
        computed: {
            /**
             * The page data
             *
             * @returns { Object }
             */
            page() {
                return this.$root.page;
            },

            /**
             * Whether the page has an associated program (campaign or activation)
             *
             * @returns {boolean}
             */
            hasProgram() {
                return !!this.page.campaign_id || !!this.page.activation_id;
            }
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {
            /**
             * Submit the application to create the contact
             */
            submitApplication() {
                this.hydrateContactModel();

                if (!this.validateForm()) {
                    return;
                }

                // set the id based on whether or not a contact was found
                this.contact_model.id = this.$root.contact && this.$root.contact.id ? this.$root.contact.id : null;

                const complete = this.hasProgram || this.page.networks_enabled ? 0 : 1;

                this.$root.loaderStart('join.contact.submitting');

                this.$http
                    .post(`/submit?complete=${complete}`, this.contact_model)
                    .then((res) => {
                        this.clearFormErrors();
                        this.$root.contact = res.data;

                        // If campaign, skip connect networks and fill out campaign proposal.
                        if (this.page.campaign_id) {
                            return this.$root.getCampaign(this.$root.contact.id, this.page.campaign_id);
                        }

                        // If activation, skip connect networks and fill out activation brief.
                        if (this.page.activation_id) {
                            /// Need to get brief ID, to redirect to. Not Activation ID.
                            return this.$root.getActivationBrief(this.$root.contact.id, this.page.activation_id);
                        }

                        return this.handleSubmitComplete();
                    })
                    .catch(() => {
                        this.handleSubmitError();
                    })
                    .then(() => {
                        this.$root.loaderStop('join.contact.submitting');
                    });
            },

            clearFormErrors() {
                this.$bus.$emit('join.error.clear');
            },

            handleSubmitComplete() {
                return this.$bus.$emit('join.contact.form.complete');
            },

            handleSubmitError() {
                let message = 'There was a problem. Please try again or contact us';
                message += this.page.support_email ? ` at ${this.page.support_email}.` : '.';

                this.$bus.$emit('join.error', message);
            },

            resetEmail() {
                this.$bus.$emit('join.reset-email');
            },

            isCustomField(field) {
                return field.substring(0, 5) === 'field';
            },

            getCustomField(field) {
                const id = parseInt(field.split('_')[1]);
                return find(this.custom_fields, (custom_field) => {
                    if (custom_field && custom_field.id) {
                        return custom_field.id === id;
                    }
                    return false;
                });
            },

            hydrateContactModel() {
                if (this.$refs.custom_fields && this.$refs.custom_fields.length) {
                    for (const i in this.$refs.custom_fields) {
                        if (!this.$refs.custom_fields[i].$refs.field) {
                            continue;
                        }
                        const field_ref = this.$refs.custom_fields[i].$refs.field;
                        const value = field_ref.getValue();

                        this.contact_model[`field_${field_ref.field.id}`] = value;
                    }
                }

                if (this.hasField('phone_number')) {
                    Object.assign(this.contact_model, { phone: this.$refs.phone[0].getPhoneNumber() });
                    this.contact_model.phone_required = this.pageFieldIsRequired('phone_number');
                }

                if (this.hasField('address')) {
                    Object.assign(this.contact_model, { ...this.$refs.address[0].getPayload() });
                    this.contact_model.address_required = this.pageFieldIsRequired('address');
                }
            },

            validateForm() {
                return this.required_field_ids.every((fieldId) => {
                    // Special handling for address.
                    if ('address' === fieldId) {
                        if (!this.$refs.address[0].isValid) {
                            this.showError('Please provide your shipping address');
                            return false;
                        } else {
                            return true;
                        }
                    }

                    fieldId = fieldId === 'phone_number' ? 'phone' : fieldId;

                    const value = this.contact_model[fieldId];

                    if (this.isCustomFieldEmpty(value) && this.findLabel(fieldId)) {
                        this.showError('Please specify your ' + this.findLabel(fieldId));
                        return false;
                    } else {
                        return true;
                    }
                });
            },

            isCustomFieldEmpty(value) {
                return (
                    value === undefined ||
                    value === null ||
                    (typeof value === 'object' && Object.keys(value).length === 0) ||
                    (typeof value === 'string' && value.trim().length === 0) ||
                    (typeof value === 'number' && isNaN(value))
                );
            },

            showError(message) {
                this.$bus.$emit('join.error', message);
            }
        },

        /**
         * When the component is created
         */
        created() {
            this.page_fields = this.page.fields.filter((field) => {
                return this.standard_fields.includes(field.id) || this.isCustomField(field.id);
            });
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep .ant-form-item-label label {
        font-size: $g-font-size-h5;
        font-weight: $g-font-weight-bold;
        text-transform: uppercase;
    }
</style>
