<template>
    <div class="content mb-10">
        <span v-html="$sanitize(the_text)"></span>
        <a @click.prevent="more = !more" v-if="text.length > size">
            {{ more ? ' less' : 'more' }}
        </a>
    </div>
</template>
<script>
    export default {
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'ReadMore',

        /**
         * the component properties
         *
         * @type {object}
         */
        props: {
            text: {
                type: String,
                required: true
            },
            size: {
                type: Number,
                required: false,
                default: 50
            }
        },

        /**
         * The component data
         *
         * @returns {object}
         */
        data() {
            return {
                more: false
            };
        },

        /**
         * The computed properties
         *
         * @type {object}
         */
        computed: {
            the_text() {
                if (this.more || this.text.length <= this.size) {
                    return this.text;
                }

                return `${this.text.slice(0, this.size)}...`;
            }
        },

        /**
         * The component methods
         *
         * @type {object}
         */
        methods: {},

        /**
         * When the component is created
         *
         * @type {function}
         */
        created() {},

        /**
         * When the component is mounted
         *
         * @type {function}
         */
        mounted() {}
    };
</script>
