import { defineStore } from 'pinia';

const useAccountCreatorStore = defineStore('account-creator', {
    state: () => {
        return {
            data: {
                id: window.SERVER_DATA?.account_creator_id || null
            }
        };
    }
});

export default useAccountCreatorStore;
