<template>
    <money
        class="input"
        :class="classes"
        :prefix="prefix"
        v-model="input_value"
        @change="$emit('input', value)"
    ></money>
</template>

<script>
    import { currencies } from '../../../helpers/constants';

    export default {
        /**
         * The component name
         *
         * @type {String}
         */
        name: 'MoneyInput',

        /**
         * The component properties
         *
         * @type {Object}
         */
        props: {
            /**
             * Input value
             */
            value: {
                default: 0
            },

            /**
             * Additional classes
             */
            classes: {
                default: null
            },

            /**
             * Currency code
             */
            currency: {
                type:    String,
                default: 'usd'
            }
        },

        /**
         * The component data
         *
         * @returns {Object}
         */
        data() {
            return {
                /**
                 * Available currencies
                 *
                 * @type {Object}
                 */
                currencies
            };
        },

        /**
         * The computed properties
         *
         * @type {Object}
         */
        computed: {
            /**
             * The input value getter setter
             *
             * @type {Object}
             */
            input_value: {
                get() {
                    return this.value ? this.value : 0;
                },
                set(value) {
                    this.$emit('input', value ? value : 0)
                }
            },

            /**
             * The input prefix (currency symbol)
             *
             * @returns {String}
             */
            prefix() {
                return this.currencies[this.currency].symbol;
            }
        }
    }
</script>

