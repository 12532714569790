<template>
    <div class="field">
        <label v-if="label" class="label" :class="{ 'mb-0': noBottomMargin }">
            <span v-if="required" class="required">*</span>
            {{ label }}
            <info-popup v-if="pop_text_valid">{{ pop_text }}</info-popup>
        </label>
        <div class="control" :class="controlClasses">
            <slot />
            <span v-if="icon" class="icon is-left">
                <i :class="iconClasses"></i>
            </span>
        </div>
        <slot name="help" />
    </div>
</template>

<script>
    import { defineComponent } from 'vue-demi';

    export default defineComponent({
        /**
         * The component name
         *
         * @type {string}
         */
        name: 'FormField',

        /**
         * The component properties
         *
         * @type {object}
         */
        props: {
            /**
             * The field label
             */
            label: {
                type: String,
                default: null
            },

            /**
             * The input icon
             */
            icon: {
                type: String,
                default: null
            },

            /**
             * Required flag
             */
            required: {
                type: Boolean,
                default: false
            },

            /**
             * Whether to apply margin-bottom: 0
             */
            noBottomMargin: {
                type: Boolean,
                default: false
            },

            /**
             * The help text
             */
            // TODO: fix during refactors
            // eslint-disable-next-line vue/prop-name-casing
            pop_text: {
                type: String,
                default: null
            }
        },

        /**
         * The component's computed properties
         *
         * @type {object}
         */
        computed: {
            /**
             * Whether the pop text is valid
             *
             * @returns {boolean}
             */
            pop_text_valid() {
                return this.pop_text != null && this.pop_text.trim().length > 0;
            },

            /**
             * The control class list
             *
             * @returns {{'has-icons-left': boolean}}
             */
            controlClasses() {
                return { 'has-icons-left': this.icon != null };
            },

            /**
             * The icon classes
             *
             * @returns {Record<string, boolean>}
             */
            iconClasses() {
                return {
                    [this.icon]: this.icon && this.icon.startsWith('fa'),
                    [`far fa-${this.icon}`]: this.icon && !this.icon.startsWith('fa')
                };
            }
        }
    });
</script>

<style scoped>
    .required {
        color: #f5222d;
    }
</style>
